import { useCallback } from 'react';
import { financingSourcesIds } from '@src/constants/constants';
import generateGuid from '@src/utils/Guid';

export function getIdAsStringOrNull(id: string | undefined): string | null {
  return id != null ? (id as string) : null;
}

export function getFinancingSourceId(value: string | undefined): string {
  switch (value) {
    case 'FS01':
      return financingSourcesIds.FS01;
    case 'FS02':
      return financingSourcesIds.FS02;
    case 'FS03':
      return financingSourcesIds.FS03;
    case 'FS04':
      return financingSourcesIds.FS04;
  }
  return generateGuid();
}
