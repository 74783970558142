import React, { FC, lazy, useMemo } from 'react';
import useChoices from '../../../hooks/choices/use-choices';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import { sortArrayOnProperty } from '../../../utils/ArrayUtils';
import CustomPageLoader from '../../../components/LoaderContainer/CustomPageLoader';
import FinancingPlan from './FinancingPlan';
import { combineAndConvertToFinancingSourceCustomList } from '@src/utils/FinancingSourcesUtils';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface FinancingPlanThemePageProps {
  title?: string;
  ingress?: string;
}

const FinancingPlanThemePage: FC<FinancingPlanThemePageProps> = ({ title, ingress }) => {
  const { applicationId } = useUrlApplicationId();
  const { financingSources: financingSourcesFromApplication, isFetched } =
    useOperationApplication(applicationId);

  const { financingSources, financingSourcesIsFetched } = useChoices();

  const mappedFinancingSources = useMemo(() => {
    const resultArray = combineAndConvertToFinancingSourceCustomList(
      financingSourcesFromApplication,
      financingSources,
    );

    if (resultArray) {
      sortArrayOnProperty(resultArray, 'sortOrder');
      return resultArray;
    }
    return [];
  }, [financingSourcesFromApplication, financingSources]);

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection>
        {financingSourcesIsFetched && isFetched ? (
          <FinancingPlan mappedFinancingSources={mappedFinancingSources} />
        ) : (
          <CustomPageLoader />
        )}
      </ThemeSection>
    </div>
  );
};
export default FinancingPlanThemePage;
