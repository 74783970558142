import { useState } from 'react';
import { AttachmentsClient } from '../../models';
import { useQueryClient } from '@tanstack/react-query';
import { CallbackResponse, FileItem } from '@in/component-library';
import { allowedFileTypes, prefix } from '../../utils/AttachmentHelper';

const useAttachments = (invalidateQueryKey: string[], applicationId?: string | null) => {
  const queryClient = useQueryClient();
  const [attachmentsClient] = useState(new AttachmentsClient());

  const uploadFile = async (fileItem: FileItem): Promise<CallbackResponse> => {
    const fileNamePrefix: string = fileItem.fieldName != null ? fileItem.fieldName : '';
    if (fileItem.file != null && fileItem.fieldName != null) {
      if (applicationId != null) {
        //TYpe FileUploadResponseModel
        if (!allowedFileTypes.includes(fileItem.fileType)) {
          return { message: 'Feil filformat', status: false };
        } else {
          const response = await attachmentsClient.create(
            applicationId,
            prefix(fileItem.fileName, fileNamePrefix),
            fileNamePrefix,
            { data: fileItem.file, fileName: fileItem.fileName },
          );

          if (response != null && response.uploadName != null && response.uploadName !== '') {
            //Updating state attachment after upload

            queryClient.invalidateQueries(invalidateQueryKey);
            return { status: true, newId: response.uploadName };
          }
        }
      }
    }
    return { status: false, message: 'Opplasting feilet' };
  };
  const deleteFile = async (id: string): Promise<CallbackResponse> => {
    if (applicationId != null) {
      await attachmentsClient.delete(applicationId, id);
      queryClient.invalidateQueries(invalidateQueryKey);
      return { newId: id, status: true, message: '' };
    }
    return { newId: '', status: true, message: 'Opplasting feilet' };
  };
  return {
    uploadFile,
    deleteFile,
  };
};
export default useAttachments;
