'use strict';

//********************************************************************************
//*ENUMS *************************************************************************
//********************************************************************************

export enum UseDateShortForm {
  NONE = 0,
  SHORT_FORM_DAY = 1,
  SHORT_FORM_MONTH = 2,
  SHORT_FORM_YEAR = 4,
}

//********************************************************************************
//*CLASS VARIABLES AND CONSTANTS *************************************************
//********************************************************************************

const DAYS_NO: Readonly<string[]> = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'];
const DAYS_NO_SHORT: Readonly<string[]> = ['søn', 'man', 'tirs', 'ons', 'tors', 'fre', 'lør'];
const MONTHS_NO: Readonly<string[]> = [
  'januar',
  'februar',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'desember',
];
const MONTHS_NO_SHORT: Readonly<string[]> = [
  'jan',
  'feb',
  'mar',
  'apr',
  'mai',
  'jun',
  'jul',
  'aug',
  'sept',
  'okt',
  'nov',
  'des',
];

export function formatCurrencySimple(value: string | number): string {
  return formatAsCurrencyString(value, ' ', '', 0, '', ' NOK');
}
//********************************************************************************
//*PUBLIC STATIC CLASS METHODS ***************************************************
//********************************************************************************

/**
 * Updated format as currency string method.
 *
 * @param {string|number}   value               - The value to format.
 * @param {string}          [thousandSeparator] - separator char between thousand
 * @param {string}          [dotReplacement]    - Replaces the '.' in the number, if it has decimals.
 * @param {number}          [decimalCount]      - Limits the number of decimal numbers
 * @param {string}          [prefix]            - Adds a string to the beginning of the value.
 * @param {string}          [suffix]            - Adds a string to the end of the value.
 * @param {string}          [defaultValueOnNull]- Default value returned if the value property is null;
 * @returns {string}
 */

export function formatAsCurrencyString(
  value: string | number,
  thousandSeparator: string = ' ',
  dotReplacement: string = '.',
  decimalCount: number = 2,
  prefix?: string | null,
  suffix?: string | null,
  defaultValueOnNull: string = '0',
): string {
  let valueString: string, valueAsArray: string[];

  if (!value) {
    return defaultValueOnNull;
  }

  const decimals: string = value.toString().split('.')[1];

  if (typeof value === 'string') {
    value = parseFloat(value.replace(',', '.').replace(' ', ''));
  }

  if (decimals) {
    decimalCount = decimals ? Math.min(decimalCount, decimals.length) : decimalCount;
    valueAsArray = value.toFixed(decimalCount).split('.');
  } else {
    valueAsArray = value.toString().split('.');
  }

  for (let i = 0, length = valueAsArray.length; i < length; ++i) {
    valueAsArray[i] = valueAsArray[i].split(/(?=(?:\d{3})+(?:\.|$))/g).join(thousandSeparator);
  }

  valueString = valueAsArray[0];

  if (valueAsArray.length > 1) {
    valueString += dotReplacement + valueAsArray[1];
  }

  valueString = typeof prefix === 'string' ? prefix + valueString : valueString;
  valueString = typeof suffix === 'string' ? valueString + suffix : valueString;

  return valueString;
}

export function formatDateInputDate(date?: Date | string): string {
  return date ? formatDateToNOString(date, false, '{year}-{month}-{date}', true) : '';
}
/**
 * Method for formatting a date object to a string. Lets you control how the end results end up with a format string. The method replaces the following values in
 * your format string:
 * {day}    - The day name string
 * {date}   - The date number.
 * {month}  - The month name, or number
 * {year}   - The year number.
 * @param {Date | string | number} date
 * @param {boolean} [useMonthName=true]
 * @param {string} [formatString="{day} {date}. {month} {year}"]
 * @param {boolean} [zeroPadNumberValues=false]
 * @param {UseDateShortForm} [useShortFormOnValues = 0]
 * @returns {string}
 */

export function formatDateToNOString(
  date?: Date | string | number,
  useMonthName: boolean = true,
  formatString: string = '{day} {date}. {month} {year}',
  zeroPadNumberValues: boolean = false,
  useShortFormOnValues: UseDateShortForm = 0,
): string {
  if (
    date == null ||
    (typeof date !== 'string' && (typeof date !== 'number' || isNaN(date)) && !(date instanceof Date))
  ) {
    throw new SyntaxError(
      'formatDateToNOString() - The date parameter is not a valid string, number or Date.',
    );
  }

  date = typeof date === 'string' || typeof date === 'number' ? new Date(date as any) : date;

  if (!(date instanceof Date)) {
    throw new Error(
      'formatDateToNOString() - The date parameter was not possible to parse as a valid string.',
    );
  }

  const dayMap: Readonly<string[]> =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_DAY) === UseDateShortForm.SHORT_FORM_DAY
        ? DAYS_NO_SHORT
        : DAYS_NO,
    monthMap: Readonly<string[]> =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_MONTH) === UseDateShortForm.SHORT_FORM_MONTH
        ? MONTHS_NO_SHORT
        : MONTHS_NO,
    fullYear: string = date.getFullYear().toString(),
    formatDay: string = dayMap[date.getDay()],
    formatDate: string = zeroPadNumberValues
      ? _convertToStringAndZeroPadNumber(date.getDate())
      : date.getDate().toString(),
    formatMonth: string = useMonthName
      ? monthMap[date.getMonth()]
      : zeroPadNumberValues
      ? _convertToStringAndZeroPadNumber(date.getMonth() + 1)
      : (date.getMonth() + 1).toString(),
    formatYear: string =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_YEAR) === UseDateShortForm.SHORT_FORM_YEAR
        ? fullYear.substring(2)
        : fullYear;

  return formatString
    .replace('{day}', formatDay)
    .replace('{date}', formatDate)
    .replace('{month}', formatMonth)
    .replace('{year}', formatYear);
}

//********************************************************************************

/**
 * Method for creating a formatted time string from a date.
 * This method expects the time parameter to be UTC time. Set the localizeTime parameter to false, if you want to format a date not in UTC time.
 * @param {Date | string | number} date
 * @param {boolean} [localizeTime=true]
 * @param {string} [formatString="{hours}:{minutes}:{seconds}"]
 * @returns {string}
 */

export function formatDateToNOTimeString(
  date: Date | string | number,
  localizeTime: boolean = true,
  formatString: string = '{hours}:{minutes}:{seconds}',
): string {
  if (
    date == null ||
    (typeof date !== 'string' && (typeof date !== 'number' || isNaN(date)) && !(date instanceof Date))
  ) {
    throw new SyntaxError(
      'formatDateToNOString() - The date parameter is not a valid string, number or Date.',
    );
  }

  date = typeof date === 'string' || typeof date === 'number' ? new Date(date as any) : date;

  if (!(date instanceof Date)) {
    throw new Error(
      'formatDateToNOString() - The date parameter was not possible to parse as a valid string.',
    );
  }

  const formatHours: string = _convertToStringAndZeroPadNumber(
      date.getUTCHours() + (localizeTime ? -date.getTimezoneOffset() / 60 : 0),
    ),
    formatMinutes: string = _convertToStringAndZeroPadNumber(date.getUTCMinutes()),
    formatSeconds: string = _convertToStringAndZeroPadNumber(date.getUTCSeconds());

  return formatString
    .replace('{hours}', formatHours)
    .replace('{minutes}', formatMinutes)
    .replace('{seconds}', formatSeconds);
}

//********************************************************************************
//PRIVATE STATIC CLASS METHODS ***************************************************
//********************************************************************************

/**
 * Helper method for padding a number with zeros, so it's always two digits.
 * @param {number}  value
 * @returns {string}
 * @private
 */

function _convertToStringAndZeroPadNumber(value: number): string {
  return ('0' + value.toString()).slice(-2);
}
