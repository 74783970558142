import { Attachment } from '../models';
import { FileItem, FileStatus } from '@in/component-library';

export const allowedFileTypes: Array<string | undefined> = [
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export function prefix(fileName: string, fileNamePrefix: string): string {
  return fileNamePrefix
    ? (fileNamePrefix + '_' + fileName).replace(/\s+/g, '')
    : fileName.replace(/\s+/g, '');
}

export function mapAttachmentToFileItem(
  attachment: Attachment,
  fileUploadFieldName: string,
  status: FileStatus = FileStatus.Ready,
): FileItem {
  return {
    id: attachment.uploadName != null ? attachment.uploadName : '',
    fileSize: attachment.length != null ? attachment.length : 0,
    fileName:
      attachment.name != null
        ? attachment.name.replace(new RegExp(`^${fileUploadFieldName}_`), '')
        : 'mangler filnavn',
    status: status,
    fileType: attachment.contentType != null ? attachment.contentType : '',
  };
}

export function getFilesFromAttachmentsByInputFieldName(
  attachments: Attachment[],
  fileUploadFieldName: string,
): Array<FileItem> {
  const resultsThisYearAttachments =
    attachments != null
      ? attachments.filter(
          (attachment) =>
            attachment?.uploadName != null && attachment.uploadName.includes(fileUploadFieldName || ''),
        )
      : [];
  return (
    resultsThisYearAttachments.map((attachment: Attachment) =>
      mapAttachmentToFileItem(attachment, fileUploadFieldName),
    ) ?? []
  );
}

export function getAllFilesFromAttachmentsAsFileItems(attachments: Attachment[]): Array<FileItem> {
  return (
    attachments.map((attachment: Attachment) => {
      let uploadName = '';
      if (attachment.name != null) {
        uploadName = attachment.name?.substring(0, attachment.name?.lastIndexOf('_'));
      }
      return mapAttachmentToFileItem(attachment, uploadName);
    }) ?? []
  );
}

export function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return '0 B';
  }
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(1)) + ' ' + sizes[i];
}

export const saveFileOnUserDevice = function (data: Blob, fileName) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }, 100);
};
