import React, { FC, lazy, PropsWithChildren } from 'react';
import { MembersListKeyNumbers } from '../../themePageSections/MembersListKeyNumbers';
import { AboutMembershipBase } from '../../themePageSections/AboutMembershipBase';
import { ClusterOrganization } from '../../themePageSections/ClusterOrganization';
import { GenderDistributionInClusterManagement } from '../../themePageSections/GenderDistributionInClusterManagement';
import { ClusterManagement } from '../../themePageSections/ClusterManagement';
import { BoardMembers } from '../../themePageSections/BoardMembers';

import { GenderDistributionInTheBoard } from '../../themePageSections/GenderDistributionInTheBoard';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface ClusterResourceBaseProps {
  title?: string;
  ingress?: string;
}
const ClusterResourceBase: FC<ClusterResourceBaseProps> = ({
  title,
  ingress,
}: PropsWithChildren<ClusterResourceBaseProps>) => {
  return (
    <div>
      {title != null && title !== '' && (
        <ThemeSection>
          <ApplicationHeader ingress={ingress} title={title} />
        </ThemeSection>
      )}

      <ThemeSection>
        <h2>Klyngeledelsen, styret og medlemmer</h2>
        <p>
          Vi trenger en oversikt over klyngens ressursbase og en beskrivelse av hvorfor dette er de riktige
          aktørene å ha med for å nå målene deres.
        </p>
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <p>Klyngens medlemsliste blir hentet fra Flerbedriftsportalen.</p>
      </ThemeSection>

      <ClusterManagement />
      <GenderDistributionInClusterManagement />

      <BoardMembers />
      <GenderDistributionInTheBoard />
      <MembersListKeyNumbers />
      <ClusterOrganization />
      <AboutMembershipBase />
    </div>
  );
};
export default ClusterResourceBase;
