import React from 'react';
import { CustomRouteObject } from '../types';

import ClusterBaseInformation from '../../pages/ClusterBaseInfo/ClusterBaseInformation';

export type BaseInfoRouteMenu = {
  baseInfoKeyInformationPage: CustomRouteObject;
  baseInfoClusterInfoPage: CustomRouteObject;
  baseInfoConfirmGoodBusinessPracticePage: CustomRouteObject;
};

const baseBaseInfoRoute: string = '/base-info/';

const baseInfoKeyInformation = `${baseBaseInfoRoute}key-information/`;
const baseInfoClusterInfo = `${baseBaseInfoRoute}cluster-information/`;
const baseInfoConfirmGoodBusinessPractice = `${baseBaseInfoRoute}confirm-good-business-practice/`;

export interface ArenaFormRoutePaths {
  baseBaseInfoRoute: string;
  baseInfoKeyInformationRoute: string;
  baseInfoClusterInfoRoute: string;
  baseInfoConfirmGoodBusinessPracticeRoute: string;
}
export const baseInfoRoutePaths: ArenaFormRoutePaths = {
  baseBaseInfoRoute: baseBaseInfoRoute,
  baseInfoKeyInformationRoute: baseInfoKeyInformation,
  baseInfoClusterInfoRoute: baseInfoClusterInfo,
  baseInfoConfirmGoodBusinessPracticeRoute: baseInfoConfirmGoodBusinessPractice,
};

const baseInfoKeyInformationPage: CustomRouteObject = {
  name: 'Nøkkelopplysninger',
  element: <ClusterBaseInformation />,
  path: baseInfoRoutePaths.baseInfoKeyInformationRoute,
  children: [],
};
const baseInfoClusterInfoPage: CustomRouteObject = {
  name: 'Ressursbasen',
  element: <ClusterBaseInformation />,
  path: baseInfoRoutePaths.baseInfoClusterInfoRoute,
  children: [],
};
const baseInfoConfirmGoodBusinessPracticePage: CustomRouteObject = {
  name: 'God forretningsskikk',
  element: <ClusterBaseInformation />,
  path: baseInfoRoutePaths.baseInfoConfirmGoodBusinessPracticeRoute,
  children: [],
};

export const baseInfoRouteConfigList: Array<CustomRouteObject> = [
  baseInfoKeyInformationPage,
  baseInfoClusterInfoPage,
  baseInfoConfirmGoodBusinessPracticePage,
];
export const baseInfoRouteConfigObject: BaseInfoRouteMenu = {
  baseInfoKeyInformationPage,
  baseInfoClusterInfoPage,
  baseInfoConfirmGoodBusinessPracticePage,
};
