import React, { FC, lazy, PropsWithChildren, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { StatusIconType } from '../../StatusIcon/StatusIcon';
import { SideNav, SidenavItem, SidenavItemSection } from '@in/component-library';
import {
  operationApplicationRouteObjects,
  operationApplicationRoutePaths,
} from '../../../routeConfigs/application/OperationApplicationRouteConfig';
import { CustomRouteObject } from '../../../routeConfigs/types';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import { CompleteApplicationDto, OperationWithMetadataDto } from '../../../models';
import {
  activitiesGoalsAndFocusAreasNextYear,
  activitiesResultThisYearPath,
  planedActivitiesNextYear,
  validateFinancingPage,
  validateKeyNumbers,
  validateResourceBase,
  validateSocietalEffectsPage,
} from '../../../store/storeValidationFunctions/operationApplicationStoreValidation';
import useMainApplication from '../../../hooks/mainApplication/use-main-application';
import cn from 'classnames';
import css from './ApplicationMenu.module.scss';
const StatusIcon = lazy(() => import('src/components/StatusIcon/StatusIcon'));

export interface SideNavProps {
  hidden?: boolean;
  applicationId: string | null;
}

const ApplicationMenu: FC<SideNavProps> = ({
  hidden = false,
  applicationId,
}: PropsWithChildren<SideNavProps>) => {
  const location = useLocation();
  const { operationApplication } = useOperationApplication(applicationId);
  const { createApplicationSetup, annualFunding } = useMainApplication();
  const getIconType = useCallback((numberOfMissingFields: number) => {
    if (numberOfMissingFields === 0) {
      return StatusIconType.COMPLETE;
    } else if (numberOfMissingFields > 0) {
      return StatusIconType.IN_PROGRESS;
    } else {
      return StatusIconType.PENDING;
    }
  }, []);

  const getStatusIcon = useCallback(
    (path: string) => {
      const missingFieldsFromStore = validateOperationPage(
        path,
        operationApplication,
        createApplicationSetup,
      );

      return <StatusIcon type={getIconType(missingFieldsFromStore)} />;
    },
    [getIconType, operationApplication, createApplicationSetup],
  );
  const menuItemsMemo = React.useMemo(() => {
    const linkItems: Array<SidenavItemSection> = operationApplicationRouteObjects[0].children.map(
      (routeObject) => {
        const childItems: (SidenavItem | undefined)[] | undefined = routeObject.children
          ?.map<SidenavItem | undefined>((item: CustomRouteObject) => {
            return {
              icon: getStatusIcon(item.path),
              text: item.name,
              href: item.path + location.search,
              active: location.pathname === item.path,
              disabled: !annualFunding?.goodBusinessPracticeConsent,
            };
          })
          .filter((item) => item != null);

        return {
          id: `${routeObject.path}`,
          href: childItems?.length === 0 ? routeObject.path + location.search : undefined,
          header: routeObject.name,
          active: location.pathname === routeObject.path,
          disabled: !annualFunding?.goodBusinessPracticeConsent,
          children: childItems != null ? (childItems as SidenavItem[]) : [],
        };
      },
    );

    return linkItems;
  }, [location.pathname, location.search, getStatusIcon, annualFunding?.goodBusinessPracticeConsent]);

  return hidden ? (
    <></>
  ) : (
    <SideNav
      sections={menuItemsMemo}
      renderLink={(sidenavItem: SidenavItem) => {
        if (sidenavItem.disabled) {
          return (
            <span>
              {sidenavItem.icon}
              {sidenavItem.text}
            </span>
          );
        }
        return (
          <Link
            to={sidenavItem.href || '#'}
            className={cn({ [css['application-menu--active']]: sidenavItem.active })}
          >
            {sidenavItem?.listLevel == null && sidenavItem.icon}
            {sidenavItem?.listLevel != null && sidenavItem.listLevel} {sidenavItem.text}
          </Link>
        );
      }}
    />
  );
};
export default ApplicationMenu;

export function validateOperationPage(
  path: string,
  operationApplication: OperationWithMetadataDto | undefined,
  createApplicationSetup: CompleteApplicationDto | undefined,
): number {
  switch (path) {
    case operationApplicationRoutePaths.clusterResourcesPath: {
      return validateResourceBase(createApplicationSetup);
    }
    case operationApplicationRoutePaths.clusterClusterPath: {
      return validateKeyNumbers(createApplicationSetup, operationApplication);
    }
    case operationApplicationRoutePaths.activitiesResultThisYearPath: {
      return activitiesResultThisYearPath(operationApplication);
    }
    case operationApplicationRoutePaths.activitiesGoalsAndFocusAreasNextYear: {
      return activitiesGoalsAndFocusAreasNextYear(operationApplication);
    }
    case operationApplicationRoutePaths.planedActivitiesNextYear: {
      return planedActivitiesNextYear(operationApplication);
    }
    case operationApplicationRoutePaths.societalEffectsPath: {
      return validateSocietalEffectsPage(operationApplication);
    }
    case operationApplicationRoutePaths.estimatedFinancingNeeds:
    case operationApplicationRoutePaths.financingPlan: {
      return validateFinancingPage(operationApplication);
    }
    default:
      return -1;
  }
}
