import React, { lazy } from 'react';
import { CustomRouteObject } from '../types';
const GeneralGuidance = lazy(() => import('../../pages/generalGuidance/GeneralGuidance'));
const CreateProject = lazy(() => import('../../pages/CreateProject/CreateProject'));
const ClusterChooser = lazy(() => import('src/components/ClusterChooser/ClusterChooser'));

export type BaseSiteRouteMenu = {
  generalGuidancePage: CustomRouteObject;
  clusterChooserPage: CustomRouteObject;
  createProjectPage: CustomRouteObject;
};

const baseSiteRoute: string = '/';
const applicationsOverviewPath: string = '/soknader';

const generalGuidance = `${baseSiteRoute}general-guidance/`;
const clusterChooser = `${baseSiteRoute}cluster-chooser/`;
const createProject = `${baseSiteRoute}create-project/`;

export interface ArenaFormRoutePaths {
  baseSiteRoute: string;
  generalGuidance: string;
  clusterChooser: string;
  createProject: string;
  applicationsOverviewPath: string;
}
export const baseSiteRoutePaths: ArenaFormRoutePaths = {
  baseSiteRoute,
  generalGuidance,
  clusterChooser,
  createProject,
  applicationsOverviewPath,
};

const generalGuidancePage: CustomRouteObject = {
  name: 'Velkommen til ny søknadsløsning',
  element: <GeneralGuidance />,
  path: baseSiteRoutePaths.generalGuidance,
  children: [],
};
const clusterChooserPage: CustomRouteObject = {
  name: 'Velg klynge',
  element: <ClusterChooser />,
  path: baseSiteRoutePaths.clusterChooser,
  children: [],
};
const createProjectPage: CustomRouteObject = {
  name: 'Opprett prosjekt',
  element: <CreateProject />,
  path: baseSiteRoutePaths.createProject,
  children: [],
};
const routes: CustomRouteObject[] = [generalGuidancePage, createProjectPage];
export const siteRouteConfigList: CustomRouteObject[] = routes;
export const siteRouteConfigObject: BaseSiteRouteMenu = {
  generalGuidancePage,
  clusterChooserPage,
  createProjectPage,
};
