import React, { useMemo } from 'react';
import cn from 'classnames';
import css from '@src/pages/Application/ApplicationLandingPage.module.scss';
import { ClusterStatusEnum, OperationApplicationOverviewDto } from '@src/models';
import { MessageType, Tag } from '@in/component-library';
import { baseInfoRoutePaths } from '@src/routeConfigs/site/BaseInfoRouteConfig';
import { operationApplicationRoutePaths } from '@src/routeConfigs/application/OperationApplicationRouteConfig';
import { formatDateToNOString } from '@src/modules/utils/method-modules/FormatValue';
import ApplicationCard from '@src/components/ApplicationCard/ApplicationCard';
import ClusterBaseInfoCard from '@src/ApplicationShared/ClusterBaseInfo/ClusterBaseInfoCard';
import useMainApplication from '@hooks/mainApplication/use-main-application';
import { useNavigate } from 'react-router-dom';
import { saveFileOnUserDevice } from '@src/utils/AttachmentHelper';
import { toast } from 'react-toastify';
import usePdf from '@hooks/pdf/use-pdf';

type OperationContentProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  operationsApplication?: OperationApplicationOverviewDto | undefined;
};

const OperationContent = React.forwardRef<HTMLDivElement, OperationContentProps>(
  ({ operationsApplication, ...prop }: OperationContentProps, ref) => {
    const navigate = useNavigate();
    const {
      annualFunding,
      clusterStatus,
      submitInformation,
      setOperationApplicationReadyToSubmitToFalseMutation,
    } = useMainApplication();
    const { getOperationApplicationAsPdf } = usePdf();

    const basisInfoIsReadyToSubmit = useMemo(() => {
      return (
        (annualFunding?.resourceBaseComments !== '') &&
        (annualFunding?.clusterOrganizationComments !== '') &&
        (annualFunding?.goodBusinessPracticeConsent ?? false)
      );
    }, [annualFunding]);

    return (
      <div {...prop} ref={ref}>
        <div
          className={cn(
            css['application-landing-page__list-group'],
            css['application-landing-page__list-group--extra-margin-bottom'],
          )}
        >
          <div
            className={cn(
              css['application-landing-page__list-group'],
              css['application-landing-page__list-group--extra-margin-bottom'],
            )}
          >
            {operationsApplication != null && (
              <>
                <h2 className={css['application-landing-page__list-header']}>Klyngedrift</h2>

                <ApplicationCard
                  tag={
                    operationsApplication.readyToSubmit ? (
                      <Tag type={MessageType.Success}>Klar</Tag>
                    ) : (
                      <Tag type={MessageType.Info}>Påbegynt</Tag>
                    )
                  }
                  disableSelect={true}
                  selected={operationsApplication.readyToSubmit}
                  showCheckBox={operationsApplication.readyToSubmit}
                  id={operationsApplication.id}
                  key={operationsApplication.id}
                  appliedForFundsValue={operationsApplication.appliedForFunds}
                  name={`${operationsApplication?.title} ${
                    operationsApplication?.metadata?.createdOn
                      ? formatDateToNOString(
                          operationsApplication?.metadata?.createdOn,
                          false,
                          '{year}',
                          true,
                        )
                      : ''
                  }`}
                  title="Klyngedrift"
                  onEdit={() => {
                    if (operationsApplication.id != null) {
                      if (operationsApplication.readyToSubmit) {
                        setOperationApplicationReadyToSubmitToFalseMutation
                          .mutateAsync(operationsApplication.id)
                          .then(() => {
                            navigate(
                              operationApplicationRoutePaths.clusterClusterPath +
                                `?id=${operationsApplication.id}`,
                            );
                          });
                      } else {
                        navigate(
                          operationApplicationRoutePaths.clusterClusterPath +
                            `?id=${operationsApplication.id}`,
                        );
                      }
                    }
                  }}
                  onDownload={async (id) => {
                    try {
                      const response = await getOperationApplicationAsPdf.mutateAsync(id);

                      if (response?.data != null) {
                        saveFileOnUserDevice(response.data, response.fileName);
                      } else {
                        toast.error('Klarte ikke laste ned dokument.');
                      }
                    } catch (error) {
                      toast.error(
                        'Fikk ikke kontakt med Websøknad, prøv igjen litt senere, dersom problemet vedvarer, kontakt din klyngerådgiver',
                      );
                    }
                  }}
                />
              </>
            )}

            {annualFunding != null &&
              submitInformation?.isAnnualFundingApplication &&
              (clusterStatus === ClusterStatusEnum.GCE ||
                clusterStatus === ClusterStatusEnum.ModneKlynger) && (
                <>
                  <h2 className={css['application-landing-page__list-header']}>Basisinformasjon</h2>
                  <ClusterBaseInfoCard
                    tag={
                      basisInfoIsReadyToSubmit ? (
                        <Tag type={MessageType.Success}>Klar</Tag>
                      ) : (
                        <Tag type={MessageType.Info}>Påbegynt</Tag>
                      )
                    }
                    onEdit={() => {
                      navigate(baseInfoRoutePaths.baseInfoKeyInformationRoute);
                    }}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    );
  },
);
OperationContent.displayName = 'OperationContent';
export default OperationContent;
