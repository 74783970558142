import React, { lazy, useCallback } from 'react';

import css from './BudgetTable.module.scss';
import { Cost } from '../../models';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import {
  Button,
  ButtonVariant,
  FaIcons,
  IconButton,
  InfoIcon,
  InputField,
  NumberInput,
} from '@in/component-library';
import { grantsFromInnovationNorwayCode, otherFinancingSourceCode } from '../../constants/constants';
import { BudgetFormModel } from '../../Application/developmentApplication/Financing/DevelopmentBudget';
import generateGuid from '../../utils/Guid';
import cn from 'classnames';
import { formatAsCurrencyString, formatCurrencySimple } from '../../modules/utils/method-modules/FormatValue';
import { RHFRequiredAndNotEmpty } from '../../utils/FormValidations';
import { useDevelopmentBudget } from '../../hooks/use-development-budget';
import { useUrlApplicationId } from '../../hooks/use-url-application-id';
import useOperationApplication from '../../hooks/OperationApplication/use-operation-application';
import { useTotalCostsWithoutDuplicates } from '../../hooks/CustomHooks';
const DescriptionList = lazy(() => import('../DescriptionList/DescriptionList'));
const DescriptionListItem = lazy(() => import('../DescriptionList/DescriptionListItem'));
type OperationFinanceFOrmProps = {
  control: Control<BudgetFormModel, Cost[]>;
  onChange: (index: number) => void;
};

const OperationFinanceForm: React.FC<OperationFinanceFOrmProps> = ({ control, onChange }) => {
  const { applicationId } = useUrlApplicationId();

  const { patchOperationApplicationMutation, deleteOperationFinancingSourceMutation, plannedActivities } =
    useOperationApplication(applicationId);

  const {
    fields: financingSourcesFields,
    append,
    remove,
  } = useFieldArray<BudgetFormModel, 'financingSources', 'financingSourcesInternalId'>({
    control,
    name: 'financingSources',
    keyName: 'financingSourcesInternalId',
  });

  const { financingSourcesTotal, otherPublicFoundingAmount } = useDevelopmentBudget(control);

  const { totalCostsAmount, participantsAmount, applicantAmount } =
    useTotalCostsWithoutDuplicates(plannedActivities);

  const validateInputField = useCallback(
    (value, type) => {
      if (type === grantsFromInnovationNorwayCode) {
        if (value === 0) {
          return 'Må være større enn 0';
        }
        return otherPublicFoundingAmount != null &&
          value != null &&
          value + otherPublicFoundingAmount <= totalCostsAmount * 0.5
          ? true
          : 'Tilskuddet fra Innovasjon Norge og offentlig finansiering, kan ikke overstige 50% av kostnadene i prosjektet';
      }
    },
    [totalCostsAmount, otherPublicFoundingAmount],
  );

  return (
    <div>
      <div className={css['financing__sum-total']}>
        <DescriptionList
          borderBottomOnLastItem={false}
          valueLabelAlign="left"
          nameLabel="Kostnader"
          valueLabel="Beløp"
        >
          <DescriptionListItem
            equalWidthTextAndValue={true}
            htmlInputLookOnValue={true}
            htmlInputLookOnValueDisabled={true}
            nameInBold={true}
            name="Sum kostnader søkerbedrift"
            value={formatAsCurrencyString(applicantAmount, ' ', '', 0, '', ' NOK')}
          />
          <DescriptionListItem
            equalWidthTextAndValue={true}
            htmlInputLookOnValue={true}
            htmlInputLookOnValueDisabled={true}
            nameInBold={true}
            name="Sum kostnader deltagerbedrifter"
            value={formatAsCurrencyString(participantsAmount, ' ', '', 0, '', ' NOK')}
          />
          <DescriptionListItem
            equalWidthTextAndValue={true}
            htmlInputLookOnValue={true}
            htmlInputLookOnValueDisabled={true}
            nameInBold={true}
            name="Sum kostnader"
            value={formatAsCurrencyString(totalCostsAmount, ' ', '', 0, '', ' NOK')}
          />
        </DescriptionList>
      </div>
      <div className={css['development-finance']} role="table">
        <div role="rowgroup">
          <div className={css['development-finance-table__row']} role="row">
            <div role="columnheader">Finnansieringskilde</div>
            <div role="columnheader">Beløp</div>
            <div role="columnheader" aria-label="slett" lang="nb-no"></div>
          </div>
        </div>
        <div className={css['development-finance-table']} role="rowgroup">
          {financingSourcesFields.map((financingSource, index) => {
            return (
              <div
                key={financingSource.id}
                role={'row'}
                className={cn(css['development-finance-table__row'], {
                  [css['development-finance-table__row--bold']]:
                    financingSource.type === grantsFromInnovationNorwayCode,
                })}
              >
                {financingSource.type !== otherFinancingSourceCode && (
                  <div className={css['development-finance-table__row-name']} role="cell">
                    <div>
                      {financingSource.name}
                      {financingSource.type === grantsFromInnovationNorwayCode && (
                        <InfoIcon ariaLabel={''}>
                          I feltet &quot;Tilskudd fra Innovasjon Norge&quot; fører dere inn summen dere søker
                          om for å kunne gjennomføre dette prosjektet
                        </InfoIcon>
                      )}
                    </div>
                  </div>
                )}
                {(financingSource.type == null || financingSource.type === otherFinancingSourceCode) && (
                  <div role="cell">
                    <Controller
                      rules={{
                        ...RHFRequiredAndNotEmpty,
                      }}
                      render={({ field, fieldState }) => (
                        <InputField
                          {...field}
                          onBlur={() => {
                            field.onBlur();

                            onChange(index);
                          }}
                          onChange={(event) => {
                            field.onChange(event);
                          }}
                          errorMsg={fieldState?.error?.message?.toString()}
                          autoComplete="off"
                          label={financingSource.name}
                          hideLabel={true}
                        />
                      )}
                      name={`financingSources.${index}.name` as const}
                      control={control}
                    />
                  </div>
                )}
                <div role="cell">
                  <Controller
                    rules={{
                      validate: (value) => validateInputField(value, financingSource.type),
                    }}
                    render={({ field, fieldState }) => (
                      <NumberInput
                        {...field}
                        onBlur={() => {
                          field.onBlur();
                          onChange(index);
                        }}
                        onChange={(event) => {
                          field.onChange(+event.currentTarget.value);
                        }}
                        errorMsg={fieldState?.error?.message?.toString()}
                        suffix={'NOK'}
                        numberLocale="nb-NO"
                        autoComplete="off"
                        label={financingSource.name}
                        hideLabel={true}
                      />
                    )}
                    name={`financingSources.${index}.amount` as const}
                    control={control}
                  />
                </div>
                {(financingSource.type == null || financingSource.type === otherFinancingSourceCode) && (
                  <div role="cell">
                    <IconButton
                      isLoading={
                        patchOperationApplicationMutation.isLoading ||
                        deleteOperationFinancingSourceMutation.isLoading
                      }
                      ariaLabel="slett rad"
                      iconName={FaIcons.TrashAltLight}
                      onClick={async () => {
                        if (financingSource?.id) {
                          remove(index);
                          await deleteOperationFinancingSourceMutation.mutateAsync(financingSource.id);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Button
          variant={ButtonVariant.Link}
          iconName={FaIcons.PlusLight}
          isLoading={
            patchOperationApplicationMutation.isLoading || deleteOperationFinancingSourceMutation.isLoading
          }
          onClick={async () => {
            const id = generateGuid();
            await patchOperationApplicationMutation.mutateAsync({
              financing: {
                sources: [
                  {
                    id: id,
                    amount: 0,
                    name: '',
                    type: otherFinancingSourceCode,
                  },
                ],
              },
            });
            append({
              id: id,
              amount: 0,
              name: '',
              type: otherFinancingSourceCode,
              sortOrder: 5,
              deletable: true,
            });
          }}
        >
          Legg til finansieringstype
        </Button>
        <DescriptionList
          borderBottomOnLastItem={false}
          classNames={css['development-finance-table__description-list-padding']}
        >
          <DescriptionListItem
            errorMessage={
              totalCostsAmount !== financingSourcesTotal
                ? 'Sum finansiering må være lik summen av prosjektets kostnader.'
                : ''
            }
            equalWidthTextAndValue={true}
            htmlInputLookOnValue={true}
            htmlInputLookOnValueDisabled={true}
            nameInBold={true}
            name="Sum finansiering"
            value={formatCurrencySimple(financingSourcesTotal)}
          />
        </DescriptionList>
      </div>
    </div>
  );
};

export default OperationFinanceForm;
