import React, { FC, PropsWithChildren } from 'react';
import css from './PageTitleAndIngress.module.scss';
import cn from 'classnames';
export interface PageTextBodyProps {
  titleCenterAlign?: boolean;
  children: React.ReactNode;
}

const PageTitleAndIngress: FC<PageTextBodyProps> = ({
  titleCenterAlign = false,
  children,
}: PropsWithChildren<PageTextBodyProps>) => {
  return (
    <div className={cn(css['page-header'], { [css['page-header--title-center']]: titleCenterAlign })}>
      {children}
    </div>
  );
};
export default PageTitleAndIngress;
