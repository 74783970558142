import React, { FC, PropsWithChildren } from 'react';
import css from './Page.module.scss';
import cn from 'classnames';
import { Body, Section } from '@in/component-library';
export interface PageProps {
  backgroundGray?: boolean;
  paddingTop?: boolean;
  wrapWithBody?: boolean;
  children: React.ReactNode;
}

const Page: FC<PageProps> = ({
  backgroundGray = false,
  children,
  wrapWithBody = false,
  paddingTop = false,
}: PropsWithChildren<PageProps>) => {
  return (
    <Body
      className={cn(css['page'], {
        [css['page__main']]: true,
        [css['page--background-gray']]: backgroundGray,
        [css['page--padding-top']]: paddingTop,
      })}
    >
      {wrapWithBody && <Section>{children}</Section>}
      {!wrapWithBody && children}
    </Body>
  );
};
export default Page;
