import React, { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import { Card, FaIcons, IconButton, ShadowHeight } from '@in/component-library';
import css from './ClusterBaseInfoCard.module.scss';

export interface ClusterBaseInfoCardProps {
  onEdit?: () => void;
  tag?: ReactNode;
}

const ClusterBaseInfoCard: FC<ClusterBaseInfoCardProps> = ({
  tag,
  onEdit,
}: PropsWithChildren<ClusterBaseInfoCardProps>) => {
  const CardTextContainer = () => {
    return (
      <div>
        <h4 className={css['cluster-base-info-card__name']}>Basisinformasjon for klynger</h4>
      </div>
    );
  };
  const CardTest = useCallback(() => {
    return (
      <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
        <div className={css['cluster-base-info-card']}>
          <div className={css['cluster-base-info-card__content-container']}>
            <div className={css['cluster-base-info-card__header']}>
              {tag && tag}
              <h3 className={css['cluster-base-info-card__title']}>Basisinformasjon</h3>
            </div>
            <CardTextContainer />
          </div>

          <div className={css['cluster-base-info-card__action-container']}>
            <IconButton
              iconName={FaIcons.EditLight}
              ariaLabel={`Gå til basisinformasjon`}
              title={`Gå til basisinformasjon`}
              onClick={() => {
                onEdit?.();
              }}
            />
          </div>
        </div>
      </Card>
    );
  }, [onEdit, tag]);
  return <CardTest />;
};
export default ClusterBaseInfoCard;
