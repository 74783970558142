import React, { FC, lazy } from 'react';
import { Button, ButtonVariant, Theme } from '@in/component-library';
import { SubmitSuccess } from '../Confirm/SubmitSuccess';
import { useNavigate } from 'react-router-dom';
import { ClusterStatusEnum } from '../../models';
import { LandingPageTabs } from './ApplicationLandingPage';
import { useDocTitle } from '../../hooks/CustomHooks';
import { baseSiteRoutePaths } from '../../routeConfigs/site/SiteRouteConfig';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const NewDevelopmentApplicationModal = lazy(
  () => import('src/components/NewDevelopmentApplicationModal/NewDevelopmentApplicationModal'),
);

const ReadyToSubmitReceiptPage: FC = () => {
  const navigate = useNavigate();
  const { clusterStatus } = useMainApplication();

  useDocTitle('Søknad er klar til innsending');
  return (
    <SubmitSuccess confetti={false} ingress="Denne søknaden er nå klar til innsending.">
      <p>
        Søknaden kan fortsatt redigeres, dere finner den under fanen “Klar til innsending”.
        {clusterStatus !== ClusterStatusEnum.Arena
          ? ' Dere kan nå velge å opprette en ny søknad, eller gå tilbake til hovedoversikten.'
          : ''}
      </p>

      <div className={'button-container button-container--space-between section--margin-bottom'}>
        {clusterStatus !== ClusterStatusEnum.Arena && <NewDevelopmentApplicationModal />}

        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Outlined}
          onClick={() => {
            navigate(baseSiteRoutePaths.applicationsOverviewPath, {
              state: {
                activeLandingPageTab: LandingPageTabs.APPLICATIONS_READY_FOR_SUBMIT,
              },
            });
          }}
        >
          Til søknadsoversikt
        </Button>
      </div>
      <p>{`(For å redigere en søknad må den flyttes tilbake til utkast)`}</p>
    </SubmitSuccess>
  );
};

export default ReadyToSubmitReceiptPage;
