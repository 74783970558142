import React, { FC, lazy, useEffect, useState } from 'react';
import { Goal, FocusArea, GoalNextYear } from '../../models';
import { Button, ModalActionButtonContainer, Theme } from '@in/component-library/';
import FocusAreaAccordion from '../../components/FocusAreaAccordion/FocusAreaAccordion';
import { useFieldArray, useForm } from 'react-hook-form';
import GoalAccordion from '../../components/GoalAccordion/GoalAccordion';
import SelectAndAdd from '../../components/SelectAndAdd/SelectAndAdd';
import RemoveFocusAreasAndGoalsNotification from '../../components/RemoveFocusAreasAndGoalsNotification/RemoveFocusAreasAndGoalsNotification';
import useCluster from '../../hooks/cluster/use-cluster';
import { useUrlApplicationId } from '../../hooks/use-url-application-id';
import useOperationApplication from '../../hooks/OperationApplication/use-operation-application';
import DialogContainer from '@src/components/DialogContainer/DialogContainer';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface MainGoal extends Goal {
  activitiesForAchieving?: string | undefined;
  achievementComments?: string | undefined;
  description?: string;
}
export interface GoalsAndFocusAreasFormInputs {
  workAndChallengesThisYearAchievement: string;
  focusAreas: FocusArea[];
  mainGoals: MainGoal[];
}
export const ClustersGoalsAndFocusAreas: FC = () => {
  const { clusterMainGoals, clusterFocusAreas } = useCluster();

  const { applicationId } = useUrlApplicationId();

  const {
    goalsNextYear,
    focusAreasNextYear,
    plannedActivities,
    patchOperationApplicationMutation,
    putOperationGoalsNextYearMutation,
    putOperationFocusAreasMutation,
    deleteOperationGoalNextYearMutation,
    deleteOperationFocusAreaMutation,
  } = useOperationApplication(applicationId);

  const [removeFocusAreaErrorModalMessage, setRemoveFocusAreaErrorModalMessage] = useState<string>('');
  const [removeGoalErrorModalMessage, setRemoveGoalErrorModalMessage] = useState<string>('');

  const { control, reset, getValues } = useForm<GoalsAndFocusAreasFormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      focusAreas: [],
      mainGoals: [],
    },
  });
  const { fields: focusAreasFields, remove } = useFieldArray<
    GoalsAndFocusAreasFormInputs,
    'focusAreas',
    'focusAreaInternalId'
  >({
    control,
    name: 'focusAreas',
    keyName: 'focusAreaInternalId',
  });

  const { fields: mainGoalsFields, remove: removeMainGoal } = useFieldArray<
    GoalsAndFocusAreasFormInputs,
    'mainGoals',
    'mainGoalsInternalId'
  >({
    control,
    name: 'mainGoals',
    keyName: 'mainGoalsInternalId',
  });

  useEffect(() => {
    if (focusAreasNextYear.length === 0) {
      return;
    }

    reset({
      ...getValues(),
      focusAreas: focusAreasNextYear.map((focusArea) => {
        return {
          id: focusArea.id,
          name: focusArea?.name != null ? focusArea.name : '',
          description: focusArea?.description != null ? focusArea.description : '',
        };
      }),
    });
  }, [focusAreasNextYear, reset, getValues]);

  useEffect(() => {
    if (goalsNextYear.length === 0) {
      return;
    }
    reset({
      ...getValues(),
      mainGoals: goalsNextYear.map((goal) => {
        return {
          id: goal.id,
          name: goal.name,
          description: goal?.description != null ? goal.description : '',
        };
      }),
    });
  }, [goalsNextYear, reset, getValues]);

  return (
    <>
      <ThemeSection marginBottom={false}>
        <p>Mål klyngen skal jobbe mot neste år:</p>
        <SelectAndAdd
          buttonText="Legg til mål"
          title={'Mål klyngen skal jobbe mot neste år'}
          options={clusterMainGoals.map((mainGoal: Goal) => {
            return {
              disabled:
                plannedActivities.findIndex(
                  (operationPlannedActivity) => operationPlannedActivity.mainGoalId === mainGoal.id,
                ) !== -1,
              selected: false,
              text: mainGoal.name != null ? mainGoal.name : '',
              value: mainGoal.id != null ? mainGoal.id : '',
            };
          })}
          value={mainGoalsFields.map((goal: Goal) => {
            return {
              selected: true,
              text: goal.name != null ? goal.name : '',
              value: goal.id != null ? goal.id : '',
            };
          })}
          onSave={(selectedIds) => {
            const selectedGoals: GoalNextYear[] = [
              ...clusterMainGoals
                .filter((goal) => selectedIds.some((id) => goal.id === id))
                .map((goal) => {
                  const existingGoal = getValues('mainGoals').find(
                    (mainGoal: MainGoal) => mainGoal.id === goal.id,
                  );
                  return {
                    description: existingGoal != null ? existingGoal.description : '',
                    id: goal?.id != null ? goal.id : '',
                    name: goal?.name != null ? goal.name : '',
                  };
                }),
            ];

            putOperationGoalsNextYearMutation.mutateAsync(selectedGoals);
          }}
        >
          <RemoveFocusAreasAndGoalsNotification
            searchForGoals={true}
            description="Du kan ikke slette disse målene fordi de er i bruk på én eller flere aktiviteter"
            items={mainGoalsFields.map((goal) => {
              return { id: goal.id, name: goal.name };
            })}
            plannedActivities={plannedActivities}
          />
        </SelectAndAdd>
      </ThemeSection>

      <ThemeSection marginBottom={true} noPadding={true}>
        {mainGoalsFields.map((fields, index) => {
          return (
            <GoalAccordion
              label="Beskriv hvordan dere skal jobbe med dette målet neste år"
              showSaveButton={false}
              key={fields.id}
              {...{ control, index, fields }}
              onlyDescription={true}
              saveProcessActive={
                patchOperationApplicationMutation.isLoading || deleteOperationGoalNextYearMutation.isLoading
              }
              onRemove={(removeIndex, mainGoal: MainGoal) => {
                const matches = plannedActivities.filter((goalId) => goalId.mainGoalId === mainGoal.id);
                if (matches.length != 0) {
                  setRemoveGoalErrorModalMessage(
                    `Dette hovedmålet brukes i aktiviteten "${matches[0].name}" og kan derfor ikke slettes`,
                  );
                } else {
                  if (mainGoal.id != null) {
                    removeMainGoal(removeIndex);
                    deleteOperationGoalNextYearMutation.mutateAsync(mainGoal.id);
                  }
                }
              }}
              onSave={(index: number) => {
                if (getValues(`mainGoals`)[index] != null) {
                  const mainGoals: GoalNextYear[] = getValues(`mainGoals`).map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                      description: item.description,
                    };
                  });
                  patchOperationApplicationMutation.mutateAsync({
                    activity: {
                      goalsNextYear: [mainGoals[index]],
                    },
                  });
                }
              }}
            />
          );
        })}
      </ThemeSection>
      <ThemeSection marginBottom={false}>
        <p>Fokusområder klyngen skal jobbe mot neste år:</p>
        <SelectAndAdd
          buttonText="Legg til fokusområde"
          title={'Fokusområder klyngen skal jobbe mot neste år'}
          options={clusterFocusAreas.map((focusArea: FocusArea) => {
            return {
              disabled:
                plannedActivities.findIndex((activity) => {
                  const match = activity?.focusAreaIds?.find((id) => {
                    return id === focusArea.id;
                  });
                  return match != null && match;
                }) !== -1,
              selected: false,
              text: focusArea.name != null ? focusArea.name : '',
              value: focusArea.id != null ? focusArea.id : '',
            };
          })}
          value={focusAreasFields.map((goal: Goal) => {
            return {
              selected: true,
              text: goal.name != null ? goal.name : '',
              value: goal.id != null ? goal.id : '',
            };
          })}
          onSave={(selectedIds) => {
            const selectedFocusAreas: FocusArea[] = [
              ...clusterFocusAreas
                .filter((focusArea) => selectedIds.some((id) => focusArea.id === id))
                .map((focusAreaDTO) => {
                  const existingFocusArea = getValues('focusAreas').find(
                    (item: FocusArea) => item.id === focusAreaDTO.id,
                  );
                  return {
                    description: existingFocusArea != null ? existingFocusArea.description : '',
                    id: focusAreaDTO?.id != null ? focusAreaDTO.id : '',
                    name: focusAreaDTO?.name != null ? focusAreaDTO.name : '',
                  };
                }),
            ];

            putOperationFocusAreasMutation.mutateAsync(selectedFocusAreas);
          }}
        >
          <RemoveFocusAreasAndGoalsNotification
            description="Du kan ikke slette disse fokusområdene fordi de er i bruk på én eller flere aktiviteter"
            items={focusAreasFields.map((item) => {
              return { id: item.id, name: item.name };
            })}
            plannedActivities={plannedActivities}
          />
        </SelectAndAdd>
      </ThemeSection>
      <ThemeSection noPadding={true}>
        {focusAreasFields.map((fields, index) => {
          return (
            <FocusAreaAccordion
              label="Beskriv hvordan dere skal jobbe med dette fokusområdet neste år"
              showSaveButton={false}
              key={fields.id}
              {...{ control, index, fields }}
              saveProcessActive={
                patchOperationApplicationMutation.isLoading || deleteOperationFocusAreaMutation.isLoading
              }
              onRemove={(removeIndex, focusArea) => {
                const index = plannedActivities.findIndex((activity) => {
                  const match = activity?.focusAreaIds?.find((id) => {
                    return id === focusArea.id;
                  });
                  return match != null && match;
                });
                if (index !== -1) {
                  //TODO: Ordbok tekst
                  setRemoveFocusAreaErrorModalMessage(
                    `Dette fokusområdet brukes på aktiviteten: "${plannedActivities[index].name}" og kan derfor ikke slettes`,
                  );
                } else {
                  if (focusArea.id != null) {
                    remove(removeIndex);
                    deleteOperationFocusAreaMutation.mutateAsync(focusArea.id as string);
                  }
                }
              }}
              onSave={(index: number) => {
                if (getValues(`focusAreas`)[index] != null) {
                  patchOperationApplicationMutation.mutateAsync({
                    activity: { focusAreasNextYear: [getValues(`focusAreas`)[index]] },
                  });
                }
              }}
            />
          );
        })}
      </ThemeSection>
      <DialogContainer
        title={'Kan ikke slette fokusområde'}
        onClose={() => setRemoveFocusAreaErrorModalMessage('')}
        show={removeFocusAreaErrorModalMessage !== ''}
      >
        <p>{removeFocusAreaErrorModalMessage}</p>
        <ModalActionButtonContainer>
          <Button
            theme={Theme.Neutral}
            type={'button'}
            onClick={() => setRemoveFocusAreaErrorModalMessage('')}
          >
            Ok
          </Button>
        </ModalActionButtonContainer>
      </DialogContainer>

      <DialogContainer
        title={'Kunne ikke slette hovedmål'}
        onClose={() => setRemoveGoalErrorModalMessage('')}
        show={removeGoalErrorModalMessage !== ''}
      >
        <p>{removeGoalErrorModalMessage}</p>
        <ModalActionButtonContainer>
          <Button theme={Theme.Neutral} type={'button'} onClick={() => setRemoveGoalErrorModalMessage('')}>
            Ok
          </Button>
        </ModalActionButtonContainer>
      </DialogContainer>
    </>
  );
};
