import React, { FC, lazy, useState } from 'react';
import { ClusterManagementContact } from '../../models';
import { Button, ButtonSize, FaIcons, Theme } from '@in/component-library/';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
import { getBoardMemberName } from '@src/utils/BordMemberNameMapper';
import { ResourceBaseContactNotification } from '@src/Application/themePageSections/ResourceBaseContactNotification';
const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export const ClusterManagement: FC = () => {
  const defaultViewCont: number = 3;
  const [showCount, setShowCount] = useState<number>(defaultViewCont);
  const { managementContacts } = useMainApplication();

  return (
    <>
      <ThemeSection marginBottom={true}>
        <h3>Klyngeledelsen</h3>
        <ResourceBaseContactNotification />
        <DescriptionList nameLabel="Navn" valueLabel="Rolle">
          {managementContacts != null &&
            managementContacts
              .map((item: ClusterManagementContact) => {
                return (
                  <DescriptionListItem
                    name={item.name}
                    subName={item.clusterMember}
                    value={getBoardMemberName(item.role)}
                    key={item.id}
                  />
                );
              })
              .filter((item: any, index: number) => {
                return index <= showCount && item;
              })}
        </DescriptionList>
        {managementContacts != null && managementContacts.length > defaultViewCont && (
          <Button
            size={ButtonSize.Small}
            theme={Theme.None}
            iconName={showCount === 3 ? FaIcons.ChevronDown : FaIcons.ChevronUp}
            onClick={() => {
              setShowCount(showCount === defaultViewCont ? managementContacts.length : defaultViewCont);
            }}
          >
            {showCount === 3 ? 'Vis alle' : 'Vis færre'}
          </Button>
        )}
      </ThemeSection>
    </>
  );
};
