import { FinancingSource, LabelValueDto } from '@src/models';
import { otherFinancingSourceCode } from '@src/constants/constants';
import { getFinancingSourceId } from '@src/utils/developmentApplicationUtils';
import { FinancingSourceCustom } from '@src/Application/developmentApplication/Financing/DevelopmentBudget';
function getSortOrder(value: string | undefined) {
  switch (value) {
    case 'FS01':
      return 1;
    case 'FS02':
      return 4;
    case 'FS03':
      return 2;
    case 'FS04':
      return 3;
  }
  return 5;
}
export function combineAndConvertToFinancingSourceCustomList(
  applicationFinancingSources: FinancingSource[],
  financingSources: LabelValueDto[],
): FinancingSourceCustom[] {
  // Create a Map to store mapping between name in FinancingSource and FinancingSourceExternal objects
  const financingSourceCustomMap = new Map<string, FinancingSourceCustom>();

  // Combine the arrays and convert to FinancingSourceCustom type
  return [
    ...applicationFinancingSources.map((financingSource) => {
      return {
        ...financingSource,
        sortOrder: getSortOrder(financingSource.type), // Set a default value for sortOrder
        deletable: financingSource?.type == null || financingSource?.type === otherFinancingSourceCode, // Set a default value for deletable
      };
    }),
    ...financingSources.map((externalObj) => {
      return {
        id: getFinancingSourceId(externalObj.value), // You may want to generate a unique ID for the external objects
        type: externalObj.value,
        name: externalObj.label,
        amount: 0,
        sortOrder: 0, // Set a default value for sortOrder
        deletable: false, // Set a default value for deletable
      };
    }),
  ].reduce((acc, obj) => {
    if (!financingSourceCustomMap.has(obj?.type ?? '')) {
      financingSourceCustomMap.set(obj?.type ?? '', obj);
      acc.push(obj);
    }
    return acc;
  }, [] as FinancingSourceCustom[]);
}
