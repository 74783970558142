import React, { FC, lazy, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { LabelValueDto, SocialImpact } from '../../models';
import { ConfirmationBox, FileItem, FileUpload, TextArea, Theme } from '@in/component-library';
import { getFilesFromAttachmentsByInputFieldName } from '../../utils/AttachmentHelper';
import { InputMaxLength } from '../../enums/InputMaxLength';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { RHFRequiredNotEmptyMediumMaxLength } from '../../utils/FormValidations';
import { ErrorMessage } from '@in/component-library/';
import { useAppLocation } from '../../hooks/use-app-location';
import useChoices from '../../hooks/choices/use-choices';
import { useUrlApplicationId } from '../../hooks/use-url-application-id';
import useDevelopmentApplication from '../../hooks/developmentApplication/use-development-application';
import useAttachments from '../../hooks/attachment/use-attachements';
import { DEVELOPMENT_APPLICATION } from '../../constants/hook-keys';
const ToggleGroup = lazy(() => import('src/components/ToggleGroup/ToggleGroup'));
const SocialAndEnvironmentalEffectAccordion = lazy(
  () => import('src/components/SocialAndEnvironmentalEffectAccordion/SocialAndEnvironmentalEffectAccordion'),
);
const SelectAndAdd = lazy(() => import('src/components/SelectAndAdd/SelectAndAdd'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface SocietalEffectsProps {
  title?: string;
  ingress?: string;
}

export interface SocietalEffectsFormInputs {
  notApplicableComment: string;
  socialImpacts: SocialImpact[];
  notApplicable: boolean;
}
const DevelopmentSocietalEffects: FC<SocietalEffectsProps> = ({
  title,
  ingress,
}: PropsWithChildren<SocietalEffectsProps>) => {
  const developmentFileUploadFieldName = 'developmentSocietalEffectsAttachment';
  const { state } = useAppLocation();
  const { applicationId } = useUrlApplicationId();

  const ref = useRef<HTMLDialogElement>(null);

  const {
    impacts,
    developmentApplication,
    attachments,
    patchDevelopmentApplicationMutation,
    patchImpactsMutation,
    putImpactsMutation,
    deleteImpactsMutation,
  } = useDevelopmentApplication(applicationId);

  const { societalEffects } = useChoices();

  const { uploadFile, deleteFile } = useAttachments([DEVELOPMENT_APPLICATION, applicationId!], applicationId);

  const attachmentsMemo = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, developmentFileUploadFieldName);
  }, [attachments, developmentFileUploadFieldName]);

  const [notApplicableWarning, setNotApplicableWarning] = useState<{
    message: string;
    notApplicable: boolean;
  }>({ message: '', notApplicable: false });

  const { control, getValues, trigger, setValue } = useForm<SocietalEffectsFormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      socialImpacts: impacts,
      notApplicableComment: developmentApplication?.impact?.notApplicableComment ?? '',
      notApplicable: developmentApplication?.impact?.notApplicable ?? false,
    },
  });
  const { fields, remove } = useFieldArray<
    SocietalEffectsFormInputs,
    'socialImpacts',
    'socialImpactsInternalId'
  >({
    control,
    name: 'socialImpacts',
    keyName: 'socialImpactsInternalId',
  });

  const { notApplicable = false } = useWatch({ control });

  const ImpactErrorMessage = () => {
    if (state != null && state.validate) {
      if (impacts.length === 0) {
        return <ErrorMessage errorMessage={'Du må legge til minst én effekt'} />;
      }
    }
    return <></>;
  };

  useEffect(() => {
    const validatePage = async () => {
      if (notApplicable) {
        await trigger();
      }
    };

    if (state != null && state.validate) {
      validatePage();
    }
  }, [state, notApplicable, trigger]);

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection marginBottom={false}>
        <h2>Prosjektets effekt</h2>
        <p>Har prosjektet positive samfunns- eller miljøeffekter?</p>

        <ToggleGroup
          disabled={patchDevelopmentApplicationMutation.isLoading}
          toggleButtons={[
            { pressed: !notApplicable, text: 'Ja', value: 'yes' },
            { pressed: notApplicable, text: 'Nei', value: 'no' },
          ]}
          onChange={async (value) => {
            let notApplicableFromValue = true;
            if (value === 'yes') {
              notApplicableFromValue = false;
            } else if (value === 'no') {
              notApplicableFromValue = true;
            }

            if (!notApplicableFromValue && getValues('notApplicableComment')) {
              //Beskriv hvorfor dette ikke er aktuelt for dere?

              setNotApplicableWarning({
                message:
                  'Din beskrivelse av hvorfor dette ikke er aktuelt for dere vil bli slettet dersom du velger ja',
                notApplicable: notApplicableFromValue,
              });
              ref.current?.showModal();
            } else if (notApplicableFromValue && impacts.length > 0) {
              setNotApplicableWarning({
                message:
                  'Alle samfunns- og miljøeffekter i dette prosjektet blir slettet dersom du velger ja.',
                notApplicable: notApplicableFromValue,
              });
              ref.current?.showModal();
            } else {
              await patchDevelopmentApplicationMutation.mutateAsync({
                impact: {
                  notApplicable: notApplicableFromValue,
                },
              });
              setValue('notApplicable', notApplicableFromValue);
            }
          }}
        />

        <p>
          Dersom dere mener prosjektets aktiviteter har en merkbar effekt skal dere prøve å
          kvantifisere/tallfeste effekten. Dere kan velge om dere vil bruke tekstfeltet eller om dere vil
          laste opp et et dokument.
        </p>
      </ThemeSection>
      <ThemeSection>
        {notApplicable && (
          <Controller
            control={control}
            name={`notApplicableComment`}
            rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
            render={({ field, fieldState }) => (
              <>
                <TextArea
                  {...field}
                  label="Beskriv hvorfor dette ikke er aktuelt for dere?"
                  maxCount={InputMaxLength.medium}
                  maxLength={InputMaxLength.medium}
                  rows={3}
                  errorMsg={fieldState?.error?.message?.toString()}
                  onBlur={(): void => {
                    field.onBlur();
                    trigger('notApplicableComment').then((isValid) => {
                      if (isValid && getValues('notApplicableComment')) {
                        patchDevelopmentApplicationMutation.mutateAsync({
                          impact: {
                            notApplicableComment: getValues('notApplicableComment'),
                          },
                        });
                      }
                    });
                  }}
                />
              </>
            )}
          />
        )}
      </ThemeSection>
      <ThemeSection marginBottom={false}>
        {!notApplicable && (
          <>
            <SelectAndAdd
              buttonText="Legg til effekt"
              title={'Legg til aktuelle samfunns- og miljøeffekter'}
              options={societalEffects.map((impact: LabelValueDto) => {
                return {
                  selected: false,
                  text: impact.label != null ? impact.label : '',
                  value: impact.value != null ? impact.value : '',
                };
              })}
              value={impacts.map((socialImpact: SocialImpact) => {
                return {
                  selected: true,
                  text: socialImpact.name != null ? socialImpact.name : '',
                  value: socialImpact.id != null ? socialImpact.id : '',
                };
              })}
              onSave={async (selectedIds) => {
                const applicationImpacts: SocialImpact[] = [
                  ...societalEffects
                    .filter((impactChoiceItem: LabelValueDto) =>
                      selectedIds.some((id) => impactChoiceItem.value === id),
                    )
                    .map((impactChoiceItem: LabelValueDto) => {
                      const existingImpact = fields.find(
                        (item: SocialImpact) => item.id === impactChoiceItem.value,
                      );

                      return {
                        id: impactChoiceItem.value,
                        name: impactChoiceItem.label,
                        description: existingImpact != null ? existingImpact.description : '',
                      };
                    }),
                ];

                if (developmentApplication?.impact?.notApplicable == null) {
                  await patchDevelopmentApplicationMutation.mutateAsync({
                    impact: {
                      notApplicable: false,
                    },
                  });
                }

                await putImpactsMutation.mutateAsync(applicationImpacts);

                setValue('socialImpacts', applicationImpacts);
              }}
            />
            <ImpactErrorMessage />
          </>
        )}
      </ThemeSection>

      {!notApplicable && (
        <ThemeSection noPadding={true} marginBottom={true} loading={putImpactsMutation.isLoading}>
          {fields.map((field, index) => {
            return (
              <SocialAndEnvironmentalEffectAccordion
                showSaveButton={false}
                key={field.id}
                {...{ control, index, field }}
                saveProcessActive={
                  patchImpactsMutation.isLoading ||
                  deleteImpactsMutation.isLoading ||
                  putImpactsMutation.isLoading
                }
                onRemove={async (index, socialImpact) => {
                  if (index != null && socialImpact?.id != null) {
                    remove(index);

                    await deleteImpactsMutation.mutateAsync(socialImpact.id);
                  }
                }}
                onSave={async (index: number) => {
                  if (getValues(`socialImpacts`)[index] != null) {
                    await patchImpactsMutation.mutateAsync({
                      socialImpacts: getValues(`socialImpacts`),
                      index: index,
                    });
                  }
                }}
              />
            );
          })}
        </ThemeSection>
      )}
      <ThemeSection>
        <h2>Vedlegg</h2>

        <FileUpload
          files={attachmentsMemo}
          uploadCallback={uploadFile}
          deletionCallback={deleteFile}
          label="Legg ved et vedlegg relatert til klyngearbeidets effekt"
          name={developmentFileUploadFieldName}
          fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          requirementsText="Tillatte filtyper: PDF, Excel, Word"
        />
      </ThemeSection>
      <ConfirmationBox
        title="Er du sikker?"
        confirmButtonTheme={Theme.Danger}
        onConfirm={async () => {
          if (notApplicableWarning.notApplicable) {
            await patchDevelopmentApplicationMutation.mutateAsync({
              impact: {
                notApplicable: notApplicableWarning.notApplicable,
                notApplicableComment: getValues('notApplicableComment'),
              },
            });
            await putImpactsMutation.mutateAsync([]);
            setValue('socialImpacts', []);
          } else {
            await patchDevelopmentApplicationMutation.mutateAsync({
              impact: {
                notApplicable: notApplicableWarning.notApplicable,
                notApplicableComment: '',
              },
            });

            setValue('notApplicableComment', '');
          }
          setValue('notApplicable', notApplicableWarning.notApplicable);
          setNotApplicableWarning({ message: '', notApplicable: notApplicableWarning.notApplicable });
          ref.current?.close();
        }}
        onCancel={async () => {
          setNotApplicableWarning({ message: '', notApplicable: notApplicableWarning.notApplicable });
          ref.current?.close();
        }}
        confirmText="Ja"
        cancelText="Avbryt"
        ref={ref}
        text={notApplicableWarning.message}
      />
    </div>
  );
};

export default DevelopmentSocietalEffects;
