'use strict';

//********************************************************************************
//*FILE VERSION ******************************************************************
//********************************************************************************

//********************************************************************************
//*CLASS VARIABLES AND CONSTANTS *************************************************
//********************************************************************************

const _lookUpTable: string[] = _generateLookUpTable();

//********************************************************************************
//*PUBLIC STATIC CLASS METHODS ***************************************************
//********************************************************************************

/**
 * Method for generating a pseudo guid (UUID), since Javascript does not have native support for this.
 * @returns {string} - guid
 */

export default function generateGuid(): string {
  if ('crypto' in window || 'msCrypto' in window) {
    return _getCryptoBasedGuid();
  }

  return _getRandomBasedGuild();
}

//********************************************************************************

/**
 * Returns an empty guild value.
 */

export function emptyGuid(): string {
  return '00000000-0000-0000-0000-000000000000';
}

//********************************************************************************
//*PRIVATE STATIC CLASS METHODS ***************************************************
//********************************************************************************

/**
 * Method for generation a Guid-like based on the Crypto API. Still not a full Guid, but much better than
 * the version using math.random.
 * @private
 */

function _getCryptoBasedGuid(): string {
  const cryptoObj: Crypto = window.crypto || window['msCrypto'];

  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (char: string) => {
    const num: number = parseInt(char, 10);
    return (num ^ (cryptoObj.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
  });
}

//********************************************************************************

/**
 * Method for generating a Guid-like based on math.random. Fallback for any browser not supporting the crypto API.
 * @private
 */

function _getRandomBasedGuild(): string {
  const randomVal0: number = (Math.random() * 0x100000000) >>> 0,
    randomVal1: number = (Math.random() * 0x100000000) >>> 0,
    randomVal2: number = (Math.random() * 0x100000000) >>> 0,
    randomVal3: number = (Math.random() * 0x100000000) >>> 0;

  return (
    _lookUpTable[randomVal0 & 0xff] +
    _lookUpTable[(randomVal0 >> 8) & 0xff] +
    _lookUpTable[(randomVal0 >> 16) & 0xff] +
    _lookUpTable[(randomVal0 >> 24) & 0xff] +
    '-' +
    _lookUpTable[randomVal1 & 0xff] +
    _lookUpTable[(randomVal1 >> 8) & 0xff] +
    '-' +
    _lookUpTable[((randomVal1 >> 16) & 0x0f) | 0x40] +
    _lookUpTable[(randomVal1 >> 24) & 0xff] +
    '-' +
    _lookUpTable[(randomVal2 & 0x3f) | 0x80] +
    _lookUpTable[(randomVal2 >> 8) & 0xff] +
    '-' +
    _lookUpTable[(randomVal2 >> 16) & 0xff] +
    _lookUpTable[(randomVal2 >> 24) & 0xff] +
    _lookUpTable[randomVal3 & 0xff] +
    _lookUpTable[(randomVal3 >> 8) & 0xff] +
    _lookUpTable[(randomVal3 >> 16) & 0xff] +
    _lookUpTable[(randomVal3 >> 24) & 0xff]
  );
}

//********************************************************************************

/**
 * Method for generating a lookup table of hex values from 00 to ff needed by the generate() method.
 * @private
 */

function _generateLookUpTable(): string[] {
  const table: string[] = [];

  for (let i = 0; i < 256; ++i) {
    table[i] = (i < 16 ? '0' : '') + i.toString(16);
  }

  return table;
}
