import React, { FC, lazy, PropsWithChildren, ReactNode, Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useWindowSize } from '../../hooks/CustomHooks';
import css from './ApplicationWrapper.module.scss';
import { Button, ButtonVariant, FaIcon, FaIcons, LayoutGrid, Section, Theme } from '@in/component-library';
import { ApplicationCategory } from '../../enums/ApplicationCategory';
import CustomPageLoader from '../../components/LoaderContainer/CustomPageLoader';
import { useUrlApplicationId } from '../../hooks/use-url-application-id';
const ApplicationMenu = lazy(() => import('src/components/Navigation/ApplicationMenu/ApplicationMenu'));
const FormContentWrapper = lazy(() => import('src/components/FormContentWrapper/FormContentWrapper'));
const Page = lazy(() => import('src/components/Page/Page'));
const ApplicationFooter = lazy(() => import('src/ApplicationShared/ApplicationFooter/ApplicationFooter'));
const ApplicationInfo = lazy(() => import('src/ApplicationShared/ApplicationInfo/ApplicationInfo'));
export interface ThemePageWrapperProps {
  footer?: ReactNode;
}

const OperationApplication: FC<ThemePageWrapperProps> = ({
  footer,
}: PropsWithChildren<ThemePageWrapperProps>) => {
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const { applicationId } = useUrlApplicationId();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width <= 900) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [windowSize]);

  return (
    <Page backgroundGray={true} paddingTop={true}>
      <Section>
        <LayoutGrid>
          <div className={css['application__sub-menu']}>
            <Button
              aria-expanded={showMenu}
              aria-controls="side-nav"
              aria-haspopup="true"
              className={css['application__sub-menu-button-toggle']}
              variant={ButtonVariant.Solid}
              theme={Theme.Neutral}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <span className={css['application__sub-menu-button-text']}>
                <span>Skjema meny</span>
              </span>
              <FaIcon name={showMenu ? FaIcons.ChevronUp : FaIcons.ChevronDown} />
            </Button>
            <ApplicationMenu hidden={!showMenu} applicationId={applicationId} />
          </div>
          <FormContentWrapper>
            <Suspense fallback={<CustomPageLoader />}>
              <Outlet />
            </Suspense>
          </FormContentWrapper>
          <ApplicationInfo />
        </LayoutGrid>
      </Section>
      <Section>
        <ApplicationFooter footer={footer} applicationType={ApplicationCategory.OPERATION} />
      </Section>
    </Page>
  );
};

export default OperationApplication;
