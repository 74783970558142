import React, { FC, lazy, useEffect, useMemo, useState } from 'react';
import {
  RHFRequiredNotEmptyExtraLargeMaxLength,
  RHFRequiredNotEmptyLargerMaxLength,
  RHFRequiredNotEmptyMediumMaxLength,
} from '../../../utils/FormValidations';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { InputField, Select, TextArea } from '@in/component-library';
import { Controller, useForm } from 'react-hook-form';
import { FocusArea, Goal, LabelValueDto, ProjectApplicationTypeEnum } from '../../../models';
import { emptyGuid } from '../../../utils/Guid';
import { getDateAsISOStringWithoutTimeZoneOffset } from '../../../utils/DateUtils';
import { ErrorMessage } from '@in/component-library/';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { useAppLocation } from '../../../hooks/use-app-location';
import { formatDateInputDate } from '../../../modules/utils/method-modules/FormatValue';
import useChoices from '../../../hooks/choices/use-choices';
import { DevelopmentProjectAboutFormFields } from '../../ApplicationWrapper/DevelopmentProject';
import useCluster from '../../../hooks/cluster/use-cluster';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
import { DeepPartial } from '@src/types/DeepPartial';

const DateInputWrapper = lazy(() => import('src/components/DateInputWrapper/DateInputWrapper'));
const DateInput = lazy(() => import('src/components/NativeDateInput/DateInput'));
const SimpleCardList = lazy(() => import('src/components/SimpleCardList/SimpleCardList'));
const SelectAndAdd = lazy(() => import('src/components/SelectAndAdd/SelectAndAdd'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface DevelopmentProjectAboutProps {
  title?: string;
  ingress?: string;
}

const DevelopmentProjectAbout: FC<DevelopmentProjectAboutProps> = ({
  title,
  ingress,
}: DevelopmentProjectAboutProps) => {
  const { innovationLevels } = useChoices();
  const { clusterFocusAreas, clusterMainGoals } = useCluster();
  const location = useAppLocation();

  const { applicationId } = useUrlApplicationId();

  const {
    developmentApplication,
    patchDevelopmentApplicationMutation,
    putFocusAreasMutation,
    patchDevelopmentApplicationTitleMutation,
  } = useDevelopmentApplication(applicationId);

  const { getValues, trigger, control } = useForm<DevelopmentProjectAboutFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      educationalOfferingsDescription:
        developmentApplication?.project?.applicationTypeSpecificInformation
          ?.educationalOfferingsDescription ?? '',
      focusArea: developmentApplication?.project?.focusAreas ?? [],
      futureClusterValueDescription:
        developmentApplication?.project?.applicationTypeSpecificInformation?.futureClusterValueDescription ??
        '',
      goalDescription: developmentApplication?.project?.goalDescription ?? '',
      innovationLevel:
        developmentApplication?.project?.applicationTypeSpecificInformation?.feasibilityStudyInnovationLevel,
      goalId: developmentApplication?.project?.goal?.id ?? '',
      projectJustification: developmentApplication?.project?.projectJustification ?? '',
      trainingDescription:
        developmentApplication?.project?.applicationTypeSpecificInformation?.trainingDescription ?? '',
      projectName: developmentApplication?.title ?? '',
      projectStartDate: formatDateInputDate(developmentApplication?.project?.startDate),
      projectEndDate: formatDateInputDate(developmentApplication?.project?.endDate),
    },
  });

  const [focusAreasErrorMessage, setFocusAreasErrorMessage] = useState<string>('');

  useEffect(() => {
    if (location?.state != null && location?.state.validate) {
      requestAnimationFrame(() => {
        trigger();
        setFocusAreasErrorMessage(
          developmentApplication?.project?.focusAreas != null &&
            developmentApplication?.project?.focusAreas?.length > 0
            ? ''
            : 'Dere må velge minst ett fokusområde',
        );
      });
    }
  }, [location, developmentApplication?.project?.focusAreas, trigger]);

  const educationalOfferingsDescriptionLabel = useMemo(() => {
    switch (developmentApplication?.type) {
      case ProjectApplicationTypeEnum.EducationalOperations:
        return 'Gi en kort beskrivelse av hvilket utdanningstilbud som skal utvikles';
      case ProjectApplicationTypeEnum.Announcement:
        return 'Gi en kort beskrivelse av hva som skal gjøres i prosjektet ';
    }
    return '';
  }, [developmentApplication?.type]);

  return (
    <>
      <>
        <ThemeSection>
          <ApplicationHeader ingress={ingress} title={title} />
          <span>{title}</span>
          <Controller
            control={control}
            name={'projectName'}
            rules={{
              required: { value: true, message: 'Feltet er påkrevd' },
              maxLength: { value: 200, message: 'Navnet kan maks være 200 tegn' },
            }}
            render={({ field, fieldState }) => (
              <InputField
                {...field}
                autoComplete="off"
                errorMsg={fieldState?.error?.message?.toString()}
                label="Navn"
                maxCount={200}
                maxLength={200}
                ref={field.ref}
                onBlur={async (event) => {
                  const { value } = event.target;
                  field.onBlur();
                  trigger('projectName', { shouldFocus: true }).then(async (isValid) => {
                    if (isValid && developmentApplication?.id) {
                      await patchDevelopmentApplicationTitleMutation.mutateAsync(value);
                      // await patchDevelopmentApplicationMutation.mutateAsync({
                      //   title: value,
                      // });
                      //TODO: få på plass lagring av tittel
                    }
                  });
                }}
              />
            )}
          />
          <h2>Prosjektets varighet</h2>
        </ThemeSection>

        <ThemeSection>
          <DateInputWrapper>
            <Controller
              control={control}
              name={'projectStartDate'}
              rules={{
                validate: (value) => {
                  const today = new Date(formatDateInputDate(new Date())).getTime();
                  const inputDate = new Date(value).getTime();
                  return inputDate >= today ? true : 'Dato må være frem i tid';
                },
              }}
              render={({ field, fieldState }) => (
                <DateInput
                  {...field}
                  id={'projectStartDate'}
                  label={'Prosjektet starter:'}
                  min={formatDateInputDate(new Date())}
                  showClearButton={false}
                  errorMsg={fieldState.error?.message}
                  onBlur={(event) => {
                    field.onBlur();
                    trigger('projectStartDate').then(() => {
                      if (!fieldState.error?.message) {
                        patchDevelopmentApplicationMutation.mutateAsync({
                          project: {
                            startDate: getDateAsISOStringWithoutTimeZoneOffset(new Date(event.target.value)),
                          },
                        });
                      }
                    });
                  }}
                  disabled={patchDevelopmentApplicationMutation.isLoading}
                />
              )}
            />

            <Controller
              control={control}
              name={'projectEndDate'}
              rules={{
                validate: (value) => {
                  const startDate = new Date(formatDateInputDate(getValues('projectStartDate'))).getTime();
                  const inputDate = new Date(value).getTime();
                  return inputDate >= startDate ? true : 'Dato må være etter startdato';
                },
              }}
              render={({ field, fieldState }) => (
                <DateInput
                  {...field}
                  id={'projectEndDate'}
                  label={'Prosjektet er ferdig:'}
                  showClearButton={false}
                  errorMsg={fieldState.error?.message}
                  min={getValues('projectStartDate')}
                  onBlur={(event) => {
                    const { value } = event.target;

                    trigger('projectEndDate').then(() => {
                      if (!fieldState.error?.message) {
                        patchDevelopmentApplicationMutation.mutateAsync({
                          project: {
                            endDate: getDateAsISOStringWithoutTimeZoneOffset(new Date(value)),
                          },
                        });
                      }
                    });
                  }}
                  disabled={patchDevelopmentApplicationMutation.isLoading}
                />
              )}
            />
          </DateInputWrapper>
        </ThemeSection>
        <ThemeSection>
          <h2>Klyngens hovedmål</h2>

          <Controller
            control={control}
            name={'goalId'}
            rules={{
              validate: (value) => {
                return value != null && value !== ' ' && value !== emptyGuid() ? true : 'Feltet er påkrevd';
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label="Knytt prosjektet til ett av klyngens hovedmål. "
                errorMsg={fieldState.error?.message}
                options={clusterMainGoals.map((mainGoal) => {
                  return {
                    text: mainGoal?.name ? mainGoal.name : '',
                    value: mainGoal?.id ? mainGoal.id : '',
                  };
                })}
                placeholder="Velg hovedmål"
                disabled={patchDevelopmentApplicationMutation.isLoading}
                onChange={(value) => {
                  field.onChange(value);
                  if (value && fieldState.isTouched) {
                    const selectedGoal = clusterMainGoals.find((mainGoal: Goal) => {
                      return mainGoal.id === value;
                    });
                    if (selectedGoal != null && selectedGoal?.id != null) {
                      patchDevelopmentApplicationMutation.mutateAsync({
                        project: {
                          goal: {
                            id: selectedGoal.id,
                            name: selectedGoal.name,
                          },
                        },
                      });
                    }
                  }
                }}
              />
            )}
          />
        </ThemeSection>

        <ThemeSection marginBottom={true}>
          <h2>Klyngens fokusområder</h2>

          <p>Knytt prosjektet til ett eller flere av klyngens fokusområder</p>
          <SimpleCardList
            onDelete={(id: string) => {
              if (developmentApplication?.project?.focusAreas) {
                putFocusAreasMutation.mutateAsync(
                  developmentApplication?.project?.focusAreas?.filter((focusArea) => focusArea.id !== id),
                );
              }
            }}
            cards={
              developmentApplication?.project?.focusAreas
                ?.filter((item) => item?.id != null)
                .map((item: FocusArea) => {
                  return {
                    id: item.id,
                    name: item?.name,
                    iconName: solidIcons.faCrosshairs,
                  };
                }) ?? []
            }
          />
          <SelectAndAdd
            buttonText="Legg til fokusområde"
            title={'Fokusområder prosjektet skal knyttes til'}
            options={clusterFocusAreas.map((focusArea: FocusArea) => {
              return {
                selected: false,
                text: focusArea.name != null ? focusArea.name : '',
                value: focusArea.id != null ? focusArea.id : '',
              };
            })}
            value={
              developmentApplication?.project?.focusAreas?.map((focusArea: FocusArea) => {
                return {
                  selected: true,
                  text: focusArea.name != null ? focusArea.name : '',
                  value: focusArea?.id != null ? focusArea.id.toString() : '',
                };
              }) ?? []
            }
            onSave={(selectedIds) => {
              const selectedFocusAreas: DeepPartial<FocusArea>[] = [
                ...clusterFocusAreas
                  .filter((focusArea) => selectedIds.some((id) => focusArea.id === id))
                  .map((focusAreaDTO: FocusArea) => {
                    return {
                      id: focusAreaDTO?.id != null ? focusAreaDTO.id : '',
                      name: focusAreaDTO.name,
                    };
                  }),
              ];

              putFocusAreasMutation.mutateAsync(selectedFocusAreas);
            }}
          />
          {focusAreasErrorMessage !== '' && <ErrorMessage errorMessage={focusAreasErrorMessage} />}
        </ThemeSection>

        {developmentApplication?.type === ProjectApplicationTypeEnum.FeasibilityStudy && (
          <ThemeSection>
            <h2>Innovasjonsnivå</h2>
            <Controller
              control={control}
              name={'innovationLevel'}
              rules={{
                validate: (value) =>
                  value != null && value !== ' ' && value !== emptyGuid() ? true : 'Feltet er påkrevd',
              }}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  errorMsg={fieldState.error?.message}
                  label="Velg et innovasjonsnivå"
                  options={innovationLevels.map((choiceItem: LabelValueDto) => {
                    return {
                      text: choiceItem?.label ? choiceItem.label : '',
                      value: choiceItem?.value ? choiceItem.value : '',
                    };
                  })}
                  placeholder="Velg innovasjonsnivå"
                  disabled={patchDevelopmentApplicationMutation.isLoading}
                  onChange={(value: string) => {
                    field.onChange(value);
                    if (
                      value != null &&
                      developmentApplication?.project?.applicationTypeSpecificInformation
                        ?.feasibilityStudyInnovationLevel !== value
                    ) {
                      patchDevelopmentApplicationMutation.mutateAsync({
                        project: {
                          applicationTypeSpecificInformation: {
                            feasibilityStudyInnovationLevel: value,
                          },
                        },
                      });
                    }
                  }}
                />
              )}
            />
          </ThemeSection>
        )}

        <ThemeSection>
          <h2>Bakgrunn</h2>
          {developmentApplication?.type === ProjectApplicationTypeEnum.ConferenceParticipation && (
            <p>
              Det gis kun støtte til konkrete messer eller utstillinger. Formålet med deltagelsen må være å nå
              ut til en stor gruppe av ulike potensielle kunder.
            </p>
          )}

          <Controller
            control={control}
            name={`projectJustification`}
            rules={{ ...RHFRequiredNotEmptyLargerMaxLength }}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                label="Gi en kort begrunnelse for prosjektet og hvorfor det skal gjennomføres. Hvilket problem skal løses?"
                errorMsg={fieldState?.error?.message?.toString()}
                onBlur={() => {
                  field.onBlur();
                  trigger('projectJustification').then((isValid) => {
                    if (isValid) {
                      patchDevelopmentApplicationMutation.mutateAsync({
                        project: { projectJustification: getValues('projectJustification') },
                      });
                    }
                  });
                }}
                maxCount={InputMaxLength.larger}
                maxLength={InputMaxLength.larger}
                rows={3}
                disableResize={true}
              />
            )}
          />

          {(developmentApplication?.type === ProjectApplicationTypeEnum.Training ||
            developmentApplication?.type === ProjectApplicationTypeEnum.InternationalMarketsKnowledge) && (
            <>
              <h2>Opplæringsprosjektets innhold</h2>
              <Controller
                control={control}
                name={`trainingDescription`}
                rules={{ ...RHFRequiredNotEmptyLargerMaxLength }}
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    label="Gi en kort beskrivelse av hva det skal gis opplæring i, og hvordan."
                    errorMsg={fieldState?.error?.message?.toString()}
                    onBlur={() => {
                      field.onBlur();
                      trigger('trainingDescription').then((isValid) => {
                        if (isValid) {
                          patchDevelopmentApplicationMutation.mutateAsync({
                            project: {
                              applicationTypeSpecificInformation: {
                                trainingDescription: getValues('trainingDescription'),
                              },
                            },
                          });
                        }
                      });
                    }}
                    maxCount={InputMaxLength.larger}
                    maxLength={InputMaxLength.larger}
                    rows={3}
                    disableResize={true}
                  />
                )}
              />
            </>
          )}

          {(developmentApplication?.type === ProjectApplicationTypeEnum.EducationalOperations ||
            developmentApplication?.type === ProjectApplicationTypeEnum.Announcement) && (
            <>
              <h2>Prosjektets innhold</h2>
              <Controller
                control={control}
                name={`educationalOfferingsDescription`}
                rules={{ ...RHFRequiredNotEmptyLargerMaxLength }}
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    label={educationalOfferingsDescriptionLabel}
                    errorMsg={fieldState?.error?.message?.toString()}
                    onBlur={() => {
                      field.onBlur();
                      trigger('educationalOfferingsDescription').then((isValid) => {
                        if (isValid) {
                          patchDevelopmentApplicationMutation.mutateAsync({
                            project: {
                              applicationTypeSpecificInformation: {
                                educationalOfferingsDescription: getValues('educationalOfferingsDescription'),
                              },
                            },
                          });
                        }
                      });
                    }}
                    maxCount={InputMaxLength.larger}
                    maxLength={InputMaxLength.larger}
                    rows={3}
                    disableResize={true}
                  />
                )}
              />
            </>
          )}
          <h2>Forventede resultater og effektmål</h2>
          <Controller
            control={control}
            name={`goalDescription`}
            rules={{ ...RHFRequiredNotEmptyExtraLargeMaxLength }}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                label={
                  developmentApplication?.type === ProjectApplicationTypeEnum.FeasibilityStudy
                    ? 'Gi en kort beskrivelse av hva prosjektet skal lede til, og hva klyngen forventer å oppnå ved å gjennomføre prosjektet. Gi en kort beskrivelse av ett eller flere mulige hovedprosjekter'
                    : 'Gi en kort beskrivelse av hva prosjektet skal lede til, og hva klyngen forventer å oppnå ved å gjennomføre prosjektet'
                }
                errorMsg={fieldState?.error?.message?.toString()}
                onBlur={() => {
                  field.onBlur();
                  trigger('goalDescription').then((isValid) => {
                    if (isValid) {
                      patchDevelopmentApplicationMutation.mutateAsync({
                        project: { goalDescription: getValues('goalDescription') },
                      });
                    }
                  });
                }}
                maxCount={InputMaxLength.extraLarge}
                maxLength={InputMaxLength.extraLarge}
                rows={3}
                disableResize={true}
              />
            )}
          />

          {(developmentApplication?.type === ProjectApplicationTypeEnum.FeasibilityStudy ||
            developmentApplication?.type === ProjectApplicationTypeEnum.InnovationCooperation ||
            developmentApplication?.type === ProjectApplicationTypeEnum.Investments ||
            developmentApplication?.type === ProjectApplicationTypeEnum.ConferenceParticipation ||
            developmentApplication?.type ===
              ProjectApplicationTypeEnum.InternationalMarketPositionsDevelopment) && (
            <Controller
              control={control}
              name={`futureClusterValueDescription`}
              rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  label="Angi et mål om verdiskapning og økonomisk gevinst som følge av prosjektet og beskriv hvordan prosjektresultatet skal benyttes for fremtidig verdiskaping i klyngen."
                  errorMsg={fieldState?.error?.message?.toString()}
                  onBlur={() => {
                    field.onBlur();
                    trigger('futureClusterValueDescription').then((isValid) => {
                      if (isValid) {
                        patchDevelopmentApplicationMutation.mutateAsync({
                          project: {
                            applicationTypeSpecificInformation: {
                              futureClusterValueDescription: getValues('futureClusterValueDescription'),
                            },
                          },
                        });
                      }
                    });
                  }}
                  maxCount={InputMaxLength.medium}
                  maxLength={InputMaxLength.medium}
                  rows={3}
                  disableResize={true}
                />
              )}
            />
          )}
        </ThemeSection>
      </>
    </>
  );
};
export default DevelopmentProjectAbout;
