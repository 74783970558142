import { useState } from 'react';
import { ApplicationsClient } from '../../models';
import { useQuery } from '@tanstack/react-query';
import { APPLICATION_CREATION_INFORMATION } from '../../constants/hook-keys';
import useUser from '../user/use-user';

export default function useCreationInformation() {
  const [applicationsClient] = useState(new ApplicationsClient());
  const { user, chosenClusterId } = useUser();

  const {
    data: applicationCreationInformationResponse,
    isInitialLoading: isInitialLoadingApplicationCreationInformation,
  } = useQuery(
    [APPLICATION_CREATION_INFORMATION],
    () => applicationsClient.getApplicationCreationInformation(),
    {
      retry: false,
      enabled: !!user && !!chosenClusterId,
    },
  );

  return {
    applicationCreationInformation: applicationCreationInformationResponse,
    isInitialLoadingApplicationCreationInformation,
  };
}
