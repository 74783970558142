import React, { FC } from 'react';
import { Link, Notification } from '@in/component-library';
import useConfiguration from '@hooks/configuration/use-configuration';

export const ResourceBaseContactNotification: FC = () => {
  const { clusterPortalUrl } = useConfiguration();
  return (
    <Notification type="info" fullWidth>
      Ønsker du å endre jobbtittel på disse kontaktene gjøres dette i Flerbedriftsportalen under Ressursbasen
      og deretter Kontakter.{' '}
      <Link
        href={clusterPortalUrl + 'resource-base/contacts'}
        target="_blank"
        className={'link-font-size-1rem'}
      >
        Gå til kontakter
      </Link>
    </Notification>
  );
};
