import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ClusterStatusEnum, DevelopmentApplicationOverviewDto } from '@src/models';
import css from '@src/pages/Application/ApplicationLandingPage.module.scss';
import { ConfirmationBox, MessageType, Tag } from '@in/component-library';
import { developmentApplicationRoutePaths } from '@src/routeConfigs/application/DevelopmentApplicationRouteConfig';
import CardContainer from '@src/ApplicationShared/CardContainer/CardContainer';
import ApplicationCard from '@src/components/ApplicationCard/ApplicationCard';
import useMainApplication from '@hooks/mainApplication/use-main-application';
import { searchObjectArray } from '@src/utils/ArrayUtils';
import { addApplication, removeApplication } from '@src/store/confirm/confirmSlice';
import CustomPageLoader from '@src/components/LoaderContainer/CustomPageLoader';
import { useAppDispatch, useAppSelector } from '@src/store/store';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import NewDevelopmentApplicationModal from '@src/components/NewDevelopmentApplicationModal/NewDevelopmentApplicationModal';
import { saveFileOnUserDevice } from '@src/utils/AttachmentHelper';
import { toast } from 'react-toastify';
import usePdf from '@hooks/pdf/use-pdf';

type DevelopmentProjectsContentProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  developmentApplications?: DevelopmentApplicationOverviewDto[] | undefined;
};
const DevelopmentProjectsContent = React.forwardRef<HTMLDivElement, DevelopmentProjectsContentProps>(
  ({ developmentApplications, ...prop }: DevelopmentProjectsContentProps, ref) => {
    const navigate = useNavigate();
    const applicationDispatch = useAppDispatch();
    const [projectIdForDelete, setProjectIdForDelete] = useState<string | null>(null);
    const { deleteDevelopmentApplicationMutation } = useMainApplication();
    const { isLoading, clusterStatus, setDevelopmentApplicationReadyToSubmitToFalseMutation } =
      useMainApplication();
    const { getDevelopmentApplicationAsPdf } = usePdf();

    const confirmBoxRef = useRef<HTMLDialogElement>(null);

    const projectToDeleteTitle = useMemo(() => {
      return developmentApplications?.find((item) => item.id === projectIdForDelete)?.title ?? '';
    }, [projectIdForDelete, developmentApplications]);
    const selectedApplicationsForSubmit: DevelopmentApplicationOverviewDto[] = useAppSelector(
      (state) => state.confirm.selectedApplicationsForSubmit,
    );

    const onEditHandler = useCallback(
      (developmentApplicationOverview: DevelopmentApplicationOverviewDto) => {
        if (developmentApplicationOverview.id != null) {
          if (developmentApplicationOverview.readyToSubmit) {
            setDevelopmentApplicationReadyToSubmitToFalseMutation
              .mutateAsync(developmentApplicationOverview.id)
              .then(() => {
                navigate(
                  developmentApplicationRoutePaths.projectAbout + `?id=${developmentApplicationOverview.id}`,
                );
              });
          } else {
            navigate(
              developmentApplicationRoutePaths.projectAbout + `?id=${developmentApplicationOverview.id}`,
            );
          }
        }
      },
      [setDevelopmentApplicationReadyToSubmitToFalseMutation, navigate],
    );

    const onSelectChangeHandler = useCallback(
      (developmentApplicationOverview: DevelopmentApplicationOverviewDto, checked: boolean) => {
        if (checked) {
          if (developmentApplicationOverview?.id != null) {
            applicationDispatch(addApplication(developmentApplicationOverview));
          }
        } else {
          if (developmentApplicationOverview?.id != null) {
            applicationDispatch(removeApplication(developmentApplicationOverview.id));
          }
        }
      },
      [applicationDispatch],
    );

    return (
      <div {...prop} ref={ref}>
        <div className={css['application-landing-page__list-group']}>
          {clusterStatus !== ClusterStatusEnum.Arena && (
            <div className={css['application-landing-page__list-group-header']}>
              <h2 className={css['application-landing-page__list-header']}>Prosjekter til søknad</h2>
            </div>
          )}

          {!isLoading && clusterStatus !== ClusterStatusEnum.Arena && (
            <div className={cn(css['application-landing-page__button-container'])}>
              <NewDevelopmentApplicationModal />
            </div>
          )}
          <CardContainer>
            {developmentApplications != null &&
              developmentApplications
                .sort((a, b) => {
                  return a.readyToSubmit === b.readyToSubmit ? 0 : a.readyToSubmit ? -1 : 1;
                })
                .map((developmentApplicationOverview: DevelopmentApplicationOverviewDto) => {
                  return (
                    <ApplicationCard
                      tag={
                        developmentApplicationOverview.readyToSubmit ? (
                          <Tag type={MessageType.Success}>Klar</Tag>
                        ) : (
                          <Tag type={MessageType.Info}>Påbegynt</Tag>
                        )
                      }
                      id={developmentApplicationOverview.id}
                      key={developmentApplicationOverview.id}
                      title={
                        developmentApplicationOverview?.objective != null
                          ? developmentApplicationOverview?.objective
                          : ''
                      }
                      name={developmentApplicationOverview.title}
                      type={developmentApplicationOverview.type}
                      appliedForFundsValue={developmentApplicationOverview.appliedForFunds}
                      onDelete={(id) => {
                        setProjectIdForDelete(id);
                        confirmBoxRef.current?.showModal();
                      }}
                      onEdit={() => onEditHandler(developmentApplicationOverview)}
                      onDownload={async (id) => {
                        try {
                          const response = await getDevelopmentApplicationAsPdf.mutateAsync(id);

                          if (response?.data != null) {
                            saveFileOnUserDevice(response.data, response.fileName);
                          } else {
                            toast.error('Klarte ikke laste ned dokument.');
                          }
                        } catch (error) {
                          toast.error(
                            'Fikk ikke kontakt med Websøknad, prøv igjen litt senere, dersom problemet vedvarer, kontakt din klyngerådgiver',
                          );
                        }
                      }}
                      selected={
                        developmentApplicationOverview.readyToSubmit
                          ? searchObjectArray(
                              'id',
                              developmentApplicationOverview.id,
                              selectedApplicationsForSubmit,
                            ) !== -1
                          : undefined
                      }
                      onSelectChange={
                        developmentApplicationOverview.readyToSubmit
                          ? (checked: boolean) =>
                              onSelectChangeHandler(developmentApplicationOverview, checked)
                          : undefined
                      }
                    />
                  );
                })}
          </CardContainer>
          <ConfirmationBox
            title={'Advarsel'}
            onConfirm={() => {
              if (projectIdForDelete != null) {
                deleteDevelopmentApplicationMutation.mutateAsync(projectIdForDelete).then(() => {
                  confirmBoxRef.current?.close();
                  setProjectIdForDelete(null);
                });
              }
            }}
            onCancel={() => {
              confirmBoxRef.current?.close();
            }}
            confirmText={`Slett prosjekt`}
            cancelText="Avbryt"
            text={`Er du sikker på at du vil slette prosjektet "${projectToDeleteTitle}"`}
            ref={confirmBoxRef}
          />

          {isLoading && <CustomPageLoader />}
        </div>
      </div>
    );
  },
);
DevelopmentProjectsContent.displayName = 'DevelopmentProjectsContent';
export default DevelopmentProjectsContent;
