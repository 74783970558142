import React, { FC, PropsWithChildren } from 'react';
import css from './ThemeSection.module.scss';
import cn from 'classnames';
import { Spinner } from '@in/component-library';
export interface ThemeSectionProps {
  marginBottom?: boolean;
  notImplemented?: string;
  noPadding?: boolean;
  loading?: boolean;
  loadingText?: string;
  children: React.ReactNode;
}

const ThemeSection: FC<ThemeSectionProps> = ({
  children,
  marginBottom = false,
  notImplemented,
  noPadding = false,
  loading = false,
  loadingText = '',
}: PropsWithChildren<ThemeSectionProps>) => {
  return (
    <div
      className={cn(css['theme-section'], {
        [css['theme-section--margin-bottom']]: marginBottom,
        [css['theme-section--not-implemented']]: notImplemented,
        [css['theme-section--no-padding']]: noPadding,
      })}
    >
      {loading && (
        <div className={css['theme-section__loader']}>
          <Spinner text={loadingText} />
        </div>
      )}
      {notImplemented && <span className={css['theme-section--not-implemented-text']}>{notImplemented}</span>}
      {children}
    </div>
  );
};
export default ThemeSection;
