import React, { FC, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMessage, FileItem, FileUpload, TextArea } from '@in/component-library';
import { Goal, GoalThisYear } from '../../../models';
import { getFilesFromAttachmentsByInputFieldName } from '../../../utils/AttachmentHelper';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { RHFRequiredNotEmptyMediumMaxLength } from '../../../utils/FormValidations';
import GoalAccordion from '../../../components/GoalAccordion/GoalAccordion';
import { GoalsAndFocusAreasFormInputs, MainGoal } from '../../themePageSections/ClustersGoalsAndFocusAreas';
import SelectAndAdd from '../../../components/SelectAndAdd/SelectAndAdd';
import { useAppLocation } from '../../../hooks/use-app-location';
import useCluster from '../../../hooks/cluster/use-cluster';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import useAttachments from '../../../hooks/attachment/use-attachements';
import { OPERATION_APPLICATION } from '../../../constants/hook-keys';
import { DeepPartial } from '@src/types/DeepPartial';

const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface ResultsThisYearProps {
  title?: string;
  ingress?: string;
}
const ResultsThisYear: FC<ResultsThisYearProps> = ({ title, ingress }) => {
  const { state } = useAppLocation();
  const { clusterMainGoals } = useCluster();
  const { applicationId } = useUrlApplicationId();
  const fileUploadFieldName = 'resultsThisYearDocuments';
  const {
    goalsThisYear,
    resultsDescription,
    attachments,
    patchOperationApplicationMutation,
    putOperationGoalsThisYearMutation,
    deleteOperationGoalThisYearMutation,
  } = useOperationApplication(applicationId);

  const attachmentLocal = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, fileUploadFieldName);
  }, [attachments, fileUploadFieldName]);

  const { getValues, control, reset, trigger } = useForm<GoalsAndFocusAreasFormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      workAndChallengesThisYearAchievement: '',
      mainGoals: [],
    },
  });

  const { fields: mainGoalsFields } = useFieldArray<
    GoalsAndFocusAreasFormInputs,
    'mainGoals',
    'mainGoalsInternalId'
  >({
    control,
    name: 'mainGoals',
    keyName: 'mainGoalsInternalId',
  });

  const [mainGoalsErrorMessage, setMainGoalsErrorMessage] = useState<string>('');

  const { uploadFile, deleteFile } = useAttachments([OPERATION_APPLICATION, applicationId!], applicationId);

  useEffect(() => {
    if (!goalsThisYear?.length && resultsDescription == null) {
      return;
    }
    reset(
      {
        mainGoals: goalsThisYear ?? [],
        workAndChallengesThisYearAchievement: resultsDescription,
      },
      { keepDefaultValues: true },
    );
  }, [goalsThisYear, reset, getValues, resultsDescription]);

  useEffect(() => {
    if (state != null && state.validate) {
      requestAnimationFrame(async () => {
        await trigger();
        setMainGoalsErrorMessage(getValues('mainGoals').length > 0 ? '' : 'Dere må velge minst ett mål');
      });
    }
  }, [state, getValues, trigger]);

  const workAndChallengesThisYearAchievementBlurHandler = useCallback(() => {
    if (
      getValues('workAndChallengesThisYearAchievement') != null &&
      getValues('workAndChallengesThisYearAchievement') !== resultsDescription
    ) {
      patchOperationApplicationMutation.mutateAsync({
        activity: {
          resultsDescription: getValues('workAndChallengesThisYearAchievement'),
        },
      });
    }
  }, [resultsDescription, getValues, patchOperationApplicationMutation]);
  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <h2>Kommenter måloppnåelse i inneværende år</h2>

        <Controller
          control={control}
          name={'workAndChallengesThisYearAchievement'}
          rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Gi en kort introduksjon til hvordan klyngen har jobbet for å nå målene sine i inneværende år og beskriv eventuelle utfordringer. "
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={() => {
                field.onBlur();
                trigger('workAndChallengesThisYearAchievement').then((isValid) => {
                  if (isValid) {
                    workAndChallengesThisYearAchievementBlurHandler();
                  }
                });
              }}
              maxCount={InputMaxLength.medium}
              maxLength={InputMaxLength.medium}
              rows={3}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <p>
          Legg til alle målene som klyngen definerte i inneværende år, gi en overodnet beskrivelse av hvert
          mål og reflekter over resultatene.
        </p>
        <SelectAndAdd
          buttonText="Legg til mål"
          title={'Legg til mål for inneværende år'}
          options={clusterMainGoals.map((mainGoal: Goal) => {
            return {
              selected: false,
              text: mainGoal.name != null ? mainGoal.name : '',
              value: mainGoal.id != null ? mainGoal.id : '',
            };
          })}
          value={mainGoalsFields.map((goal: Goal) => {
            return {
              selected: true,
              text: goal.name != null ? goal.name : '',
              value: goal.id != null ? goal.id : '',
            };
          })}
          onSave={(selectedIds) => {
            const selectedGoals: DeepPartial<GoalThisYear>[] = [
              ...clusterMainGoals
                .filter((goal) => selectedIds.some((id) => goal.id === id))
                .map((mainGoalsDTO) => {
                  const existingGoal = getValues('mainGoals').find(
                    (item: DeepPartial<GoalThisYear>) => item.id === mainGoalsDTO.id,
                  );
                  return {
                    achievementComments: existingGoal != null ? existingGoal.achievementComments : '',
                    activitiesForAchieving: existingGoal != null ? existingGoal.activitiesForAchieving : '',
                    id: mainGoalsDTO.id,
                    name: mainGoalsDTO.name,
                  };
                }),
            ];
            putOperationGoalsThisYearMutation.mutateAsync(selectedGoals);
          }}
        />
        {mainGoalsErrorMessage !== '' && <ErrorMessage errorMessage={mainGoalsErrorMessage} />}
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        {mainGoalsFields.map((fields, index) => {
          return (
            <GoalAccordion
              showSaveButton={false}
              key={fields.id}
              {...{ control, index, fields }}
              saveProcessActive={
                patchOperationApplicationMutation.isLoading || deleteOperationGoalThisYearMutation.isLoading
              }
              onRemove={(removeIndex, mainGoal: MainGoal) => {
                const goalsThisYearCopy = [...goalsThisYear];
                const index = goalsThisYearCopy.findIndex((goalCopy) => {
                  return goalCopy.id === mainGoal?.id?.toString();
                });

                if (index !== -1 && goalsThisYearCopy?.[index]?.id != null) {
                  deleteOperationGoalThisYearMutation.mutateAsync(goalsThisYearCopy[index].id as string);
                }
              }}
              onSave={(index: number, isDirty) => {
                if (getValues(`mainGoals`)[index] != null && isDirty) {
                  patchOperationApplicationMutation.mutateAsync({
                    activity: {
                      goalsThisYear: [getValues(`mainGoals`)[index]],
                    },
                  });
                }
              }}
            />
          );
        })}
      </ThemeSection>

      <ThemeSection>
        <h2>Vedlegg </h2>
        <FileUpload
          files={attachmentLocal}
          uploadCallback={uploadFile}
          deletionCallback={deleteFile}
          name={fileUploadFieldName}
          label="Her kan dere laste opp dokumenter med utdypende beskrivelser av resultatene"
          fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          requirementsText="Tillatte filtyper: PDF, Excel, Word"
        />
      </ThemeSection>
    </div>
  );
};

export default ResultsThisYear;
