import React, { FC, lazy, useState } from 'react';

import { ClusterBoardMember } from '../../models';
import { Button, ButtonSize, FaIcons, Theme } from '@in/component-library';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
import { getBoardMemberName } from '@src/utils/BordMemberNameMapper';
import { ResourceBaseContactNotification } from '@src/Application/themePageSections/ResourceBaseContactNotification';

const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export const BoardMembers: FC = () => {
  const defaultViewCont: number = 3;
  const { boardMembers } = useMainApplication();

  const [showCount, setShowCount] = useState<number>(defaultViewCont);
  return (
    <div>
      <ThemeSection>
        <h3>Styrets sammensetning</h3>
        <ResourceBaseContactNotification />

        <DescriptionList nameLabel="Navn" valueLabel="Rolle">
          {boardMembers != null &&
            boardMembers
              .map((item: ClusterBoardMember) => {
                return (
                  <DescriptionListItem
                    name={item.name}
                    subName={item.clusterMember}
                    value={getBoardMemberName(item.role)}
                    key={item.id}
                  />
                );
              })
              .filter((item: any, index: number) => {
                return index <= showCount && item;
              })}
        </DescriptionList>
        {boardMembers != null && boardMembers.length > defaultViewCont && (
          <Button
            size={ButtonSize.Small}
            theme={Theme.None}
            iconName={showCount === defaultViewCont ? FaIcons.ChevronDown : FaIcons.ChevronUp}
            onClick={() => {
              setShowCount(showCount === defaultViewCont ? boardMembers.length : defaultViewCont);
            }}
          >
            {showCount === defaultViewCont ? 'Vis alle' : 'Vis færre'}
          </Button>
        )}
      </ThemeSection>
    </div>
  );
};
