import React from 'react';
import css from './CardContainer.module.scss';

type CardContainerProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

const CardContainer = React.forwardRef<HTMLDivElement, CardContainerProps>(({ children, ...rest }, ref) => {
  return (
    <div className={css['card-container']} {...rest} ref={ref}>
      {children}
    </div>
  );
});
CardContainer.displayName = 'CardContainer';
export default CardContainer;
