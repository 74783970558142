import { ApiException } from '../models';
import { QueryClient } from '@tanstack/react-query';
import { USER_SESSION_HAS_EXPIRED, USER_STATUS_LOGGED_IN } from '../constants/hook-keys';

export const retryStrategy =
  (maxFailureCount: number) =>
  (failureCount: number, error: unknown): boolean => {
    if (ApiException.isApiException(error)) {
      // Noen ganger så er status med stor bokstav, da denne ekstra sjekken
      if (
        error.status === 401 ||
        (error as any).Status === 401 ||
        error.status === 403 ||
        (error as any).Status === 403
      ) {
        return false;
      }
    }

    // Sender inn max feil, da vi ikke vil ha masse feil på mutations
    return failureCount < maxFailureCount;
  };

export const handleOnError = (error: unknown): void => {
  if (ApiException.isApiException(error)) {
    if (error.status === 401 || (error as any).Status === 401) {
      const userStatusLoggedIn = queryClient.getQueriesData([USER_STATUS_LOGGED_IN]);
      if (userStatusLoggedIn) {
        queryClient.setQueryData([USER_SESSION_HAS_EXPIRED], true);
      }

      queryClient.setQueryData([USER_STATUS_LOGGED_IN], false);
    }
    //TODO: skal det være en global error toast dersom noe feiler?
    //toast.error('Det har oppstått en feil', { toastId: error?.status });
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: retryStrategy(3),

      onError: handleOnError,
    },
    mutations: {
      retry: retryStrategy(1),

      onError: handleOnError,
    },
  },
});
