import React from 'react';
import { Button, ButtonVariant, TextArea, Theme } from '@in/component-library';
import { FocusArea } from '../../models';
import { Control, Controller } from 'react-hook-form';
import { InputMaxLength } from '../../enums/InputMaxLength';
import { RHFRequiredNotEmptyMediumMaxLength } from '../../utils/FormValidations';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';

import { GoalsAndFocusAreasFormInputs } from '../../Application/themePageSections/ClustersGoalsAndFocusAreas';
import { DeepPartial } from '@src/types/DeepPartial';

export interface GoalInputFieldsProps {
  showSaveButton?: boolean;
  onlyDescription?: boolean;
  saveProcessActive: boolean;
  onRemove: (index: number, focusArea: FocusArea) => void;
  onSave: (index: number, isDirty: boolean) => void;
  index: number;
  label?: string;
  control: Control<GoalsAndFocusAreasFormInputs, any>;
  fields: DeepPartial<FieldArrayWithId<GoalsAndFocusAreasFormInputs, 'mainGoals', 'mainGoalsInternalId'>>;
}

const GoalInputFields = React.forwardRef<HTMLTextAreaElement, GoalInputFieldsProps>(
  (
    {
      control,
      index,
      onRemove,
      onSave,
      onlyDescription,
      showSaveButton = true,
      label = 'Overordnet beskrivelse av mål',
      ...props
    }: GoalInputFieldsProps,
    ref,
  ) => {
    return (
      <>
        {onlyDescription && (
          <Controller
            control={control}
            name={`mainGoals.${index}.description` as const}
            rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                errorMsg={fieldState.error?.message}
                onBlur={() => {
                  field.onBlur();
                  onSave?.(index, fieldState.isDirty);
                }}
                ref={ref}
                label={label}
                maxCount={InputMaxLength.medium}
                maxLength={InputMaxLength.medium}
                rows={3}
                disableResize={true}
              />
            )}
          />
        )}

        {!onlyDescription && (
          <>
            <Controller
              control={control}
              name={`mainGoals.${index}.achievementComments` as const}
              rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  errorMsg={fieldState.error?.message}
                  onBlur={() => {
                    field.onBlur();

                    onSave?.(index, fieldState.isDirty);
                  }}
                  ref={ref}
                  label="Overordnet beskrivelse av klyngens aktiviteter for å nå målet i inneværende år"
                  maxCount={InputMaxLength.medium}
                  maxLength={InputMaxLength.medium}
                  rows={3}
                  disableResize={true}
                />
              )}
            />
            <Controller
              control={control}
              name={`mainGoals.${index}.activitiesForAchieving` as const}
              rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  errorMsg={fieldState.error?.message}
                  onBlur={() => {
                    field.onBlur();
                    onSave?.(index, fieldState.isDirty);
                  }}
                  ref={ref}
                  label="Beskriv grad av måloppnåelse og hvordan aktivitetene har bidratt til måloppnåelse? "
                  maxCount={InputMaxLength.medium}
                  maxLength={InputMaxLength.medium}
                  rows={3}
                  disableResize={true}
                />
              )}
            />
          </>
        )}

        <div className="button-container button-container--space-between">
          <Button
            disabled={props.saveProcessActive}
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            onClick={() => {
              onRemove?.(index, props.fields as FocusArea);
            }}
            type={'button'}
          >
            Fjern
          </Button>
          {showSaveButton && (
            <Button
              disabled={props.saveProcessActive}
              theme={Theme.Neutral}
              variant={ButtonVariant.Outlined}
              type={'button'}
              onClick={() => {
                onSave?.(index, true);
              }}
            >
              Lagre
            </Button>
          )}
        </div>
      </>
    );
  },
);
GoalInputFields.displayName = 'GoalInputFields';
export default GoalInputFields;
