import { useState } from 'react';
import { ENVIRONMENT_INFORMATION_KEY } from 'src/constants/hook-keys';
import useUser from '../user/use-user';
import { applicationInsights } from '../../ApplicationInsights/ApplicationInsights';
import { useQuery } from '@tanstack/react-query';
import { ConfigurationClient } from '../../models';

export default function useConfiguration() {
  const { user } = useUser();
  const [configurationClient] = useState(new ConfigurationClient());

  const { data: environmentInformation, ...rest } = useQuery(
    [ENVIRONMENT_INFORMATION_KEY],
    () => configurationClient.getEnvironmentInformation(),
    {
      enabled: !!user,
      retry: false,
      onSuccess(data) {
        if (data?.appInsightsInstrumentationKey && applicationInsights?.appInsights?.core == null) {
          applicationInsights.config.instrumentationKey = data.appInsightsInstrumentationKey;
          applicationInsights.loadAppInsights();
        }
      },
    },
  );
  return {
    environmentInformation: environmentInformation,
    clusterPortalUrl: environmentInformation?.clusterPortalUrl ?? 'https://flerbedriftsportal.no/',
    ...rest,
  };
}
