import React, { FC, lazy, useEffect } from 'react';
import { TextArea } from '@in/component-library';
import { Controller, useForm } from 'react-hook-form';
import { InputMaxLength } from '../../enums/InputMaxLength';
import { RHFRequiredNotEmptyLargeMaxLength } from '../../utils/FormValidations';
import { useAppLocation } from '../../hooks/use-app-location';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

interface FormInputs {
  clusterOrganizationDescription: string;
}

export const ClusterOrganization: FC = () => {
  const { state } = useAppLocation();
  const { patchMainApplicationAnnualFunding, annualFunding } = useMainApplication();

  const { control, getValues, trigger, reset } = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      clusterOrganizationDescription: '',
    },
  });

  useEffect(() => {
    if (
      annualFunding?.clusterOrganizationComments == null ||
      annualFunding?.clusterOrganizationComments === ''
    ) {
      return;
    }
    reset({ clusterOrganizationDescription: annualFunding?.clusterOrganizationComments ?? '' });
  }, [reset, getValues, annualFunding?.clusterOrganizationComments]);

  useEffect(() => {
    if (state != null && state.validate) {
      requestAnimationFrame(() => {
        trigger();
      });
    }
  }, [state, trigger]);

  function clusterOrganizationBlurHandler(): void {
    if (getValues('clusterOrganizationDescription') !== annualFunding?.clusterOrganizationComments) {
      patchMainApplicationAnnualFunding.mutateAsync({
        clusterOrganizationComments: getValues('clusterOrganizationDescription'),
      });
    }
  }
  return (
    <>
      <ThemeSection>
        <h2>Organisering av klyngen</h2>
        <Controller
          control={control}
          name={'clusterOrganizationDescription'}
          rules={{
            ...RHFRequiredNotEmptyLargeMaxLength,
          }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Beskriv hvordan klyngen er administrert og hvilke ressurser den har tilgjengelig i styret og klyngeledelsen.
          Forklar hvordan klyngen er organisert med eventuelle arbeidsgrupper."
              maxCount={InputMaxLength.large}
              maxLength={InputMaxLength.large}
              rows={3}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={() => {
                field.onBlur();
                trigger('clusterOrganizationDescription').then((isValid) => {
                  if (isValid) {
                    clusterOrganizationBlurHandler();
                  }
                });
              }}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>
    </>
  );
};
