import React, { FC, lazy } from 'react';
import ClusterBaseInfoContent from './ClusterBaseInfoContent';
import { useDocTitle } from '../../hooks/CustomHooks';
const Page = lazy(() => import('src/components/Page/Page'));

const ClusterBaseInformation: FC = () => {
  const docTitlePrefix = 'Godkjenn basisinformasjon';
  useDocTitle(docTitlePrefix);

  return (
    <Page paddingTop={true}>
      <ClusterBaseInfoContent docTitlePrefix={docTitlePrefix} />
    </Page>
  );
};

export default ClusterBaseInformation;
