import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import '@in/component-library/dist/component-library.css';
import './styles/style.scss';
import './styles/toastify.scss';
import { App } from './App';
import store from './store/store';
import HotJarScript from './components/HotJarScript/HotJarScript';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './reactQuery/reactQueryCallbackFunctions';
import ScrollToTop from './components/ScrollToTop';
import { createRoot } from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faScrubber, faCircle, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
document.documentElement.lang = 'nb';
library.add(faCircleCheck, faScrubber, faCircle, faExclamationCircle);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // Bruker strict mode for testing. kommentert ut nå fordi det per i dag ligger nå
  // fetch av data i useeffect og strict mode kjører mount og unmount to ganger som gjør at
  // kall mot server skjer to ganger. kan ha strict mode på når alt er konvertert til hooks
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <QueryClientProvider client={queryClient}>
          <HotJarScript />
          <App />
          <ToastContainer closeOnClick={false} position="top-right" theme="colored" />
          <ReactQueryDevtools />
        </QueryClientProvider>
        <ScrollToTop />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
