import { useState } from 'react';
import {
  AnnualFundingApplicationFields,
  ApplicationsClient,
  CreateDevelopmentApplicationRequest,
} from '../../models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CLUSTER_FINANCIAL_FRAME,
  CLUSTER_FOCUS_AREAS,
  CLUSTER_MAIN_GOALS,
  COMPLETE_APPLICATION_ID,
  CREATE_APPLICATION_SETUP,
  OPERATION_APPLICATION_IS_CREATED,
} from '../../constants/hook-keys';
import useUser from '../user/use-user';
import { toast } from 'react-toastify';
import { DeepPartial } from '@src/types/DeepPartial';

export default function useMainApplication() {
  const [applicationsClient] = useState(new ApplicationsClient());
  const { user, chosenClusterId } = useUser();
  const queryClient = useQueryClient();

  const { data: operationApplicationIsCreated } = useQuery([OPERATION_APPLICATION_IS_CREATED], () => false, {
    enabled: false,
    initialData: false,
  });
  const { data: completeApplicationId } = useQuery([COMPLETE_APPLICATION_ID], () => null, {
    enabled: false,
    initialData: null,
  });

  const { data: createApplicationSetupResponse, ...rest } = useQuery(
    [CREATE_APPLICATION_SETUP],
    () => applicationsClient.createApplicationSetup(),
    {
      enabled: !!user && !!chosenClusterId,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 min staletime, refetches on mount if stale
      refetchOnMount: true,
      onSuccess(response) {
        if (response) {
          queryClient.setQueryData([OPERATION_APPLICATION_IS_CREATED], true);
        }
        queryClient.setQueryData([COMPLETE_APPLICATION_ID], response.id);
      },
      onError() {
        toast.error('Klarte ikke hente søknadsdata', { toastId: CREATE_APPLICATION_SETUP });
      },
    },
  );

  const patchMainApplicationAnnualFunding = useMutation(
    (request: DeepPartial<AnnualFundingApplicationFields>) => {
      return applicationsClient.partiallyUpdateMainApplicationAnnualFunding(
        completeApplicationId,
        request as AnnualFundingApplicationFields,
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );

  const createDevelopmentApplicationMutation = useMutation(
    (request: CreateDevelopmentApplicationRequest) => {
      return applicationsClient.createDevelopmentApplication(request);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
      onError() {
        toast.error('Klarte ikke opprette prosjekt', { toastId: 'createDevelopmentApplication' });
      },
    },
  );

  const getUpdatedClusterInformationMutation = useMutation(
    () => {
      return applicationsClient.getUpdatedClusterInformation();
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
        queryClient.invalidateQueries([CLUSTER_MAIN_GOALS]);
        queryClient.invalidateQueries([CLUSTER_FOCUS_AREAS]);
        queryClient.invalidateQueries([CLUSTER_FINANCIAL_FRAME]);
      },
    },
  );
  const setOperationApplicationReadyToSubmitToFalseMutation = useMutation(
    (id: string) => {
      return applicationsClient.setOperationApplicationReadyToSubmitToFalse(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );
  const setDevelopmentApplicationReadyToSubmitToFalseMutation = useMutation(
    (id: string) => {
      return applicationsClient.setDevelopmentApplicationReadyToSubmitToFalse(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );

  const deleteDevelopmentApplicationsMutation = useMutation(
    (applicationIds: string[]) => {
      return applicationsClient.deleteDevelopmentApplications({ applicationIds: applicationIds });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
      onError() {
        toast.error('Klarte ikke slette prosjekt', { toastId: 'deleteDevelopmentApplications' });
      },
    },
  );

  const deleteDevelopmentApplicationMutation = useMutation(
    (applicationId: string) => {
      return applicationsClient.deleteDevelopmentApplication(applicationId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
      onError() {
        toast.error('Klarte ikke slette prosjekt', { toastId: 'deleteDevelopmentApplication' });
      },
    },
  );
  const submitMainApplicationMutation = useMutation(
    (applicationIds: string[]) => {
      return applicationsClient.submit({ applicationIds: applicationIds });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );

  const createApplicationSetup = createApplicationSetupResponse;
  return {
    createApplicationSetup: createApplicationSetup,
    mainApplicationId: createApplicationSetup?.id,
    clusterStatus: createApplicationSetup?.clusterInformation?.keyInformation?.clusterStatus,
    operationsApplication: createApplicationSetup?.operationsApplication,
    submitInformation: createApplicationSetup?.submitInformation,
    developmentApplications: createApplicationSetup?.developmentApplications,
    attachments: createApplicationSetup?.attachments ?? [],
    operationApplicationIsCreated,
    patchMainApplicationAnnualFunding,
    getUpdatedClusterInformationMutation,
    boardMembers: createApplicationSetup?.clusterInformation?.board?.members ?? [],
    managementContacts: createApplicationSetup?.clusterInformation?.management?.contacts ?? [],
    clusterManagement: createApplicationSetup?.clusterInformation?.management,
    clusterBoard: createApplicationSetup?.clusterInformation?.board,
    clusterKeyInformation: createApplicationSetup?.clusterInformation?.keyInformation,
    annualFunding: createApplicationSetup?.annualFunding,
    setOperationApplicationReadyToSubmitToFalseMutation,
    setDevelopmentApplicationReadyToSubmitToFalseMutation,
    deleteDevelopmentApplicationsMutation,
    deleteDevelopmentApplicationMutation,
    createDevelopmentApplicationMutation,
    submitMainApplicationMutation,
    ...rest,
  };
}
