import React from 'react';
import { CustomRouteObject } from '../types';

import { baseSiteRoutePaths } from '../site/SiteRouteConfig';
import OperationApplication from '../../Application/ApplicationWrapper/OperationApplication';
import Cluster from '../../Application/operationApplication/Cluster/ClusterInfo';
import Activities from '../../Application/operationApplication/ResultsAndActivities/ResultsAndActivities';
import Financing from '../../Application/operationApplication/Financing/Financing';
import CompleteForm from '../../Application/operationApplication/CompleteForm/CompleteForm';
import ResultsThisYear from '../../Application/operationApplication/ResultsAndActivities/ResultsThisYear';
import ActivitiesNextYear from '../../Application/operationApplication/ResultsAndActivities/PlanedActivitiesNextYear';
import ClusterThemePage from '../../Application/operationApplication/Cluster/ClusterThemePage';
import MembersThemePage from '../../Application/operationApplication/Cluster/ClusterResourceBase';
import FinancingPlanThemePage from '../../Application/operationApplication/Financing/FinancingPlanThemePage';
import FinancingNeedsThemePage from '../../Application/operationApplication/Financing/FinancingNeedsThemePage';
import GoalsAndFocusAreasNextYear from '../../Application/operationApplication/ResultsAndActivities/GoalsAndFocusAreasNextYear';
import SocietalEffects from '../../ApplicationShared/SocialAndEnvironmentalEffect/SocietalEffects';
import Impact from '../../ApplicationShared/SocialAndEnvironmentalEffect/Impact';

const baseOperationApplicationRoute: string = `${baseSiteRoutePaths.applicationsOverviewPath}/operations-application/`;
const clusterBasePath = `${baseOperationApplicationRoute}cluster/`;
const clusterClusterPath = `${clusterBasePath}info`;
const clusterResourcesPath = `${clusterBasePath}resource-base`;
const clusterOrganizationPath = `${clusterBasePath}organization`;
const activitiesParentPath = `${baseOperationApplicationRoute}results-and-activities/`;
const activitiesResultThisYearPath = `${activitiesParentPath}results-this-year`;
const activitiesGoalsAndFocusAreasNextYear = `${activitiesParentPath}goals-and-focus-areas-next-year`;
const planedActivitiesNextYear = `${activitiesParentPath}planed-activities-next-year`;
const impactParentPath = `${baseOperationApplicationRoute}impact/`;
const societalEffectsPath = `${impactParentPath}societal-effects`;
const financingParentPath = `${baseOperationApplicationRoute}financing/`;
const financingPlan = `${financingParentPath}financing-plan`;
const estimatedFinancingNeeds = `${financingParentPath}estimated-financing-needs`;
const completeFormPath = `${baseOperationApplicationRoute}complete-form/`;
const completeFormOverviewPath = `${completeFormPath}overview`;
export interface ArenaFormRoutePaths {
  baseOperationApplicationRoute: string;
  clusterBasePath: string;
  clusterClusterPath: string;
  clusterResourcesPath: string;
  clusterOrganizationPath: string;
  activitiesParentPath: string;
  activitiesResultThisYearPath: string;
  activitiesGoalsAndFocusAreasNextYear: string;
  planedActivitiesNextYear: string;
  impactParentPath: string;
  societalEffectsPath: string;
  financingParentPath: string;
  financingPlan: string;
  estimatedFinancingNeeds: string;
  completeFormPath: string;
  completeFormOverviewPath: string;
}
export const operationApplicationRoutePaths: ArenaFormRoutePaths = {
  baseOperationApplicationRoute,
  clusterBasePath,
  clusterClusterPath,
  clusterResourcesPath,
  clusterOrganizationPath,
  activitiesParentPath,
  activitiesResultThisYearPath,
  activitiesGoalsAndFocusAreasNextYear,
  planedActivitiesNextYear,
  impactParentPath,
  societalEffectsPath,
  financingParentPath,
  financingPlan,
  estimatedFinancingNeeds,
  completeFormPath,
  completeFormOverviewPath,
};

const cluster: CustomRouteObject = {
  name: 'Klyngen',
  element: <Cluster />,
  path: operationApplicationRoutePaths.clusterBasePath,
  children: [
    {
      name: 'Nøkkelopplysninger',
      element: (
        <ClusterThemePage
          title="Klyngens nøkkelopplysninger"
          ingress="Informasjonen blir hentet fra Flerbedriftsportalen. Kontroller at informasjonen under stemmer. Trenger dere å gjøre endringer kan dette gjøres i Flerbedriftsportalen."
        />
      ),
      path: operationApplicationRoutePaths.clusterClusterPath,
      children: [],
    },
    {
      name: 'Klyngens ressursbase',
      element: (
        <MembersThemePage
          title="Klyngens ressursbase"
          ingress="Klyngen eksisterer for å dekke partnerskapets behov, og det er de som skal gjøre mye av jobben."
        />
      ),
      path: operationApplicationRoutePaths.clusterResourcesPath,
      children: [],
    },
  ],
};

const activities: CustomRouteObject = {
  name: 'Resultater og aktiviteter',
  element: <Activities />,
  path: operationApplicationRoutePaths.activitiesParentPath,
  children: [
    {
      name: 'Resultater i år',
      element: <ResultsThisYear title="Foreløpige resultater i år" />,
      attachmentField: 'resultsThisYearDocuments',
      path: activitiesResultThisYearPath,
      children: [],
    },
    {
      name: 'Mål og fokusområder neste år',
      element: (
        <GoalsAndFocusAreasNextYear
          title="Mål og fokusområder neste år"
          ingress="Hva er klyngens mål og fokusområder det kommende året?"
        />
      ),
      path: activitiesGoalsAndFocusAreasNextYear,
      children: [],
    },
    {
      name: 'Planlagte aktiviteter neste år',
      element: (
        <ActivitiesNextYear
          title="Planlagte aktiviteter neste år"
          ingress="Innovasjon Norge må vite hva dere planlegger å gjøre til neste år. For å kunne se sammenhengen i søknaden, ber vi dere om å knytte de planlagte aktivitetene opp mot hovedmål og fokusområder."
        />
      ),
      path: planedActivitiesNextYear,
      children: [],
    },
  ],
};

const impacts: CustomRouteObject = {
  name: 'Samfunns- og miljøeffekt',
  element: <Impact />,
  path: impactParentPath,
  children: [
    {
      name: 'Samfunns- og miljøeffekt',
      element: <SocietalEffects title="Samfunns- og miljøeffekt" />,
      path: societalEffectsPath,
      attachmentField: 'societalEffectsAttachment',
      children: [],
    },
  ],
};

const financing: CustomRouteObject = {
  name: 'Finansiering',
  element: <Financing />,
  path: financingParentPath,
  children: [
    {
      name: 'Finansieringsplan',
      element: (
        <FinancingPlanThemePage title="Finansieringsplan" ingress="Hvordan skal klyngedriften finansieres?" />
      ),
      path: financingPlan,
      children: [],
    },
    {
      name: 'Budsjett for klyngedrift',
      element: <FinancingNeedsThemePage title="Budsjett for klyngedrift" />,
      attachmentField: 'activityBudgetUpcomingYearAttachment',
      path: estimatedFinancingNeeds,
      children: [],
    },
  ],
};

const completeForm: CustomRouteObject = {
  name: 'Oppsummering',
  element: <CompleteForm title="Oppsummering" />,
  path: completeFormPath,
  children: [],
};

const operationApplication: CustomRouteObject = {
  name: 'Søknad',
  element: <OperationApplication />,
  path: operationApplicationRoutePaths.baseOperationApplicationRoute,
  children: [cluster, activities, impacts, financing, completeForm],
};
export const operationApplicationRouteObjects: CustomRouteObject[] = [operationApplication];
