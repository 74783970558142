import useChoices from './choices/use-choices';
import { Cost, ProjectApplicationTypeEnum } from '../models';
import { useMemo } from 'react';
import generateGuid, { emptyGuid } from '../utils/Guid';
import { totalRequestedAmountCostCode } from '../constants/constants';

export default function useMergeCostsWithDefaultCosts(
  type: ProjectApplicationTypeEnum | undefined,
  costs: Cost[],
) {
  const { expenseTypes } = useChoices(type);

  const mergedCosts: Cost[] = useMemo(() => {
    let costsInternal: Cost[];
    if (
      type === ProjectApplicationTypeEnum.EducationalOperations ||
      type === ProjectApplicationTypeEnum.Announcement
    ) {
      costsInternal =
        costs && costs.length > 0
          ? costs
          : expenseTypes?.map((item) => {
              return {
                id: generateGuid(),
                type: item.value,
                name: '',
                applicantAmount: 0,
                participantsAmount: 0,
              };
            });
    } else {
      costsInternal = expenseTypes.map<Cost>((expenseType) => {
        const currentCost = costs?.find((item) => {
          return item.type === expenseType.value;
        });
        return {
          id: currentCost?.id == null || currentCost?.id === emptyGuid() ? generateGuid() : currentCost.id,
          type: expenseType.value,
          name:
            expenseType.value !== totalRequestedAmountCostCode
              ? expenseType.label
              : currentCost?.name
              ? currentCost.name
              : '',
          applicantAmount: currentCost?.applicantAmount ?? 0,
          participantsAmount: currentCost?.participantsAmount ?? 0,
        };
      });
    }

    return costsInternal;
  }, [costs, type, expenseTypes]);

  return { mergedCosts };
}
