import React, { FC, lazy, PropsWithChildren } from 'react';

import css from './SummaryCheckList.module.scss';
import classNames from 'classnames';
import { ErrorMessage, FaIcon, FaIcons } from '@in/component-library/';
import { Link, useLocation } from 'react-router-dom';
import { developmentApplicationRoutePaths } from '../../../routeConfigs/application/DevelopmentApplicationRouteConfig';
import { operationApplicationRoutePaths } from '../../../routeConfigs/application/OperationApplicationRouteConfig';
import { validateOperationPage } from '../../../components/Navigation/ApplicationMenu/ApplicationMenu';
import { validatePage } from '../../../components/Navigation/ApplicationMenu/DevelopmentApplicationMenu';
import {
  CompleteApplicationDto,
  DevelopmentWithMetadataDto,
  OperationWithMetadataDto,
} from '../../../models';
const ValidCheckMark = lazy(() => import('src/components/ValidCheckMark/ValidCheckMark'));
const NotStartedStatusWithIcon = lazy(
  () => import('src/components/NotStartedStatusWithIcon/NotStartedStatusWithIcon'),
);

export interface SummaryCheckListChild {
  name: string;
  documents: string[];
  id: string;
}
export interface SummaryCheckListParent {
  name: string;
  children: SummaryCheckListChild[];
  id: string;
}
export interface SummaryCheckListProps {
  data: SummaryCheckListParent[];
  excludePathFromErrorMessage?: string[];
  developmentApplication?: DevelopmentWithMetadataDto;
  operationApplication?: OperationWithMetadataDto;
  mainApplication?: CompleteApplicationDto;
}
//TODO: sjekke på om developApplication finnes avgjør hvilken validering som kjøres.
//finn på noe lurt her
export const SummaryCheckList: FC<SummaryCheckListProps> = ({
  data,
  excludePathFromErrorMessage = [],
  developmentApplication,
  operationApplication,
  mainApplication,
}: PropsWithChildren<SummaryCheckListProps>) => {
  const { search } = useLocation();
  const missingFieldErrorTemplate = `Det gjenstår å fylle ut {count} felter på siden {name}`;

  return (
    <div>
      <div className={css['summary-check-list__header']}>
        <span className={css['summary-check-list__header-page']}>Side</span>
        <span className={css['summary-check-list__header-status']}>Status</span>
      </div>
      <ul className={css['summary-check-list']}>
        {data.map((item) => {
          return (
            <li key={item.id} className={css['summary-check-list__parent']}>
              <div className={css['summary-check-list__parent-name-container']}>
                <span className={classNames(css['summary-check-list__parent-name'])}>
                  {item.children.length > 0 && item.name}
                </span>
              </div>

              {item.children.length > 0 && (
                <ul>
                  {item.children.map((child) => {
                    let missingFieldsCountFromStore = -1;
                    if (developmentApplication) {
                      missingFieldsCountFromStore = validatePage(child.id, developmentApplication);
                    } else {
                      missingFieldsCountFromStore = validateOperationPage(
                        child.id,
                        operationApplication,
                        mainApplication,
                      );
                    }

                    const excludeFromErrorMessage = excludePathFromErrorMessage.find(
                      (item) => item === child.id,
                    );
                    return (
                      <li key={child.id} className={css['summary-check-list__child']}>
                        <div className={classNames(css['summary-check-list__child-name'])}>
                          <div className={css['summary-check-list__child-name-text']}>
                            <Link
                              to={{
                                pathname: child.id,
                                search: search,
                              }}
                              state={{ validate: true }}
                              aria-label={`${missingFieldErrorTemplate
                                .replace('{count}', missingFieldsCountFromStore.toString())
                                .replace('{name}', child.name)}`}
                            >
                              {child.name}
                            </Link>
                          </div>
                          {missingFieldsCountFromStore === 0 && (
                            <span className={css['summary-check-list__child-name--check-container']}>
                              <ValidCheckMark />
                            </span>
                          )}
                          {missingFieldsCountFromStore === -1 && (
                            <span className={css['summary-check-list__child-name--check-container']}>
                              <NotStartedStatusWithIcon />
                            </span>
                          )}
                          {missingFieldsCountFromStore > 0 && !excludeFromErrorMessage && (
                            <div className={css['summary-check-list__error-message-container']}>
                              <ErrorMessage
                                errorMessage={
                                  missingFieldErrorTemplate
                                    .replace('{count}', missingFieldsCountFromStore.toString())
                                    .replace('{name}', '') +
                                  (child.id === developmentApplicationRoutePaths.financingPlan ||
                                  child.id === operationApplicationRoutePaths.financingPlan
                                    ? '. Sjekk at summen på finansiering er lik kostnadene i prosjektet'
                                    : '')
                                }
                              />
                            </div>
                          )}
                        </div>
                        {child.documents.length > 0 && (
                          <ul className={css['summary-check-list__documents']}>
                            {child.documents.map((documentName) => (
                              <li
                                key={child.id + documentName}
                                className={css['summary-check-list__document']}
                              >
                                <FaIcon name={FaIcons.File} /> {documentName}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
