import React, { FC, lazy } from 'react';
import { ClustersVision } from '../../themePageSections/ClustersVision';
import { ClustersGoalsAndFocusAreas } from '../../themePageSections/ClustersGoalsAndFocusAreas';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface GoalsAndFocusAreasNextYearProps {
  title?: string;
  ingress?: string;
}
const GoalsAndFocusAreasNextYear: FC<GoalsAndFocusAreasNextYearProps> = ({ title, ingress }) => {
  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ClustersVision />
      <ClustersGoalsAndFocusAreas />
    </div>
  );
};
export default GoalsAndFocusAreasNextYear;
