import React, { FC, lazy, Suspense, useMemo } from 'react';
import Layout from './PageLayout/Layout/Layout';
import useUser from './hooks/user/use-user';
import RouterConfig from './routeConfigs/RouterConfig';
import useConfiguration from './hooks/configuration/use-configuration';
import CustomPageLoader from './components/LoaderContainer/CustomPageLoader';
import { Section } from '@in/component-library';
import Page from 'src/components/Page/Page';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import useEnvironment from './hooks/environment/use-environment';
import useMyCluster from '@hooks/cluster/use-my-cluster';
const AutoSaveIndicator = lazy(() => import('src/components/autoSaveIndicator/AutoSaveIndicator'));

export const App: FC = () => {
  const user = useUser();
  const cluster = useMyCluster();
  const configuration = useConfiguration();
  const { isDevOrTest } = useEnvironment();

  const isLoading = useMemo(() => {
    return user.isInitialLoading || cluster.isInitialLoading || configuration.isInitialLoading;
  }, [user.isInitialLoading, configuration.isInitialLoading, cluster.isInitialLoading]);

  if (isLoading) {
    return <CustomPageLoader viewportHeight={true} />;
  }
  return (
    <ErrorBoundary
      message="Det har oppstått en feil, kontakt oss og referer til id som vises på siden"
      isDevOrTest={isDevOrTest}
    >
      <Layout user={user}>
        <Suspense
          fallback={
            <Page>
              <Section>
                <CustomPageLoader viewportHeight={true} />
              </Section>
            </Page>
          }
        >
          <RouterConfig user={user} />
          <AutoSaveIndicator />
        </Suspense>
      </Layout>
    </ErrorBoundary>
  );
};
