import React, { FC, lazy } from 'react';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));

export const GenderDistributionInTheBoard: FC = () => {
  const { clusterBoard } = useMainApplication();
  return (
    <>
      <ThemeSection>
        <h3>Kjønnsfordeling i styret</h3>
        <DescriptionList>
          <DescriptionListItem
            name="Kvinner"
            value={clusterBoard?.femaleCount != null ? clusterBoard.femaleCount : '0'}
          />
          <DescriptionListItem
            name="Menn"
            value={clusterBoard?.maleCount != null ? clusterBoard.maleCount : '0'}
          />
        </DescriptionList>
      </ThemeSection>
    </>
  );
};
