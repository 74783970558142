import React, { FC, lazy, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { SummaryCheckList } from '../../operationApplication/CompleteForm/SummaryCheckList';
import {
  Button,
  ButtonVariant,
  Card,
  FileItem,
  FileUpload,
  ShadowHeight,
  TextArea,
  Theme,
} from '@in/component-library';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDocTitle, useSummaryList } from '../../../hooks/CustomHooks';
import { getFilesFromAttachmentsByInputFieldName } from '../../../utils/AttachmentHelper';
import generateGuid from '../../../utils/Guid';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { Controller, useForm } from 'react-hook-form';
import { SummaryFields } from '../../operationApplication/CompleteForm/CompleteForm';
import { readyToSubmitReceiptRouteConfigObject } from '../../../routeConfigs/site/ReadyToSubmitReceiptRouteConfig';
import { baseSiteRoutePaths } from '../../../routeConfigs/site/SiteRouteConfig';
import { developmentApplicationRoutePaths } from '../../../routeConfigs/application/DevelopmentApplicationRouteConfig';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
import { validatePage } from '../../../components/Navigation/ApplicationMenu/DevelopmentApplicationMenu';
import useAttachments from '../../../hooks/attachment/use-attachements';
import { DEVELOPMENT_APPLICATION } from '../../../constants/hook-keys';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface DevelopmentApplicationSummaryProps {
  title?: string;
  ingress?: string;
}
const DevelopmentApplicationSummary: FC<DevelopmentApplicationSummaryProps> = ({
  title,
  ingress,
}: PropsWithChildren<DevelopmentApplicationSummaryProps>) => {
  useDocTitle('Oppsummering');
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { applicationId } = useUrlApplicationId();

  const {
    developmentApplication,
    patchDevelopmentApplicationMutation,
    attachments,
    validateDevelopmentApplicationMutation,
  } = useDevelopmentApplication(applicationId);

  const fileUploadFieldName = 'developmentSummaryExtraDocuments';

  const [validationStarted, setValidationStarted] = useState<boolean>(false);
  const [errorsFromServer, setErrorsFromServer] = useState<Record<string, string[]> | null>(null);

  const { uploadFile, deleteFile } = useAttachments([DEVELOPMENT_APPLICATION, applicationId!], applicationId);

  const attachmentsMemo = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, fileUploadFieldName);
  }, [attachments, fileUploadFieldName]);

  const { trigger, control } = useForm<SummaryFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: { extraComments: developmentApplication?.extraComments ?? '' },
  });

  const { summaryList } = useSummaryList(pathname, attachments);

  const allPagesIsValid = useCallback(() => {
    return summaryList.every((route) => {
      const allChildrenIsValid = route.children.every(
        (childRoute) => validatePage(childRoute.id, developmentApplication) === 0,
      );
      return route.children != null && route.children.length > 0 ? allChildrenIsValid : false;
    });
  }, [summaryList, developmentApplication]);

  function updateChildren(children) {
    return children.map((child) => <li key={generateGuid()}>{child}</li>);
  }

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection>
        <h2>Har dere fylt ut alt?</h2>
        <p>
          Vi har sjekket alle sider og alle felter, her ser dere resultatet. Når alle felt er fylt ut, vil
          &quot;Klar til innsending&quot;-knappen aktiveres.
        </p>
      </ThemeSection>

      <SummaryCheckList
        data={summaryList}
        excludePathFromErrorMessage={[developmentApplicationRoutePaths.estimatedFinancingNeeds]}
        developmentApplication={developmentApplication}
      />
      <ThemeSection>
        <h2>Tilleggsinformasjon</h2>

        <Controller
          control={control}
          name={`extraComments`}
          rules={{ maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' } }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Eventuell tilleggsinformasjon"
              maxCount={InputMaxLength.medium}
              maxLength={InputMaxLength.medium}
              rows={3}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={async (event) => {
                const { value } = event.target;
                field.onBlur();
                trigger('extraComments').then(async (isValid) => {
                  if (isValid) {
                    await patchDevelopmentApplicationMutation.mutateAsync({
                      extraComments: value,
                    });
                  }
                });
              }}
            />
          )}
        />

        <FileUpload
          files={attachmentsMemo}
          uploadCallback={uploadFile}
          deletionCallback={deleteFile}
          name={fileUploadFieldName}
          label="Har dere noen vedlegg som dere ikke fant det naturlig å laste opp andre steder, kan disse legges ved her."
          fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          requirementsText="Tillatte filtyper: PDF, Excel, Word"
        />
      </ThemeSection>
      <ThemeSection>
        <h2>Klar til innsending</h2>
        <p>Er søknaden ferdig?</p>
        <div className="button-container">
          <Button
            isLoading={validationStarted}
            theme={Theme.Neutral}
            variant={ButtonVariant.Solid}
            type="button"
            disabled={!allPagesIsValid() || validationStarted}
            onClick={async () => {
              setValidationStarted(true);

              validateDevelopmentApplicationMutation
                .mutateAsync()
                .then(() => {
                  setValidationStarted(false);
                  navigate(
                    readyToSubmitReceiptRouteConfigObject.developmentApplicationReadyToSubmitPage.path +
                      search,
                  );
                })
                .catch((error) => {
                  setValidationStarted(false);
                  setErrorsFromServer(error as Record<string, string[]>);
                });
            }}
          >
            Klar til innsending
          </Button>
          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={() => navigate(baseSiteRoutePaths.applicationsOverviewPath)}
          >
            Lagre som utkast
          </Button>
        </div>
      </ThemeSection>
      <ThemeSection>
        {errorsFromServer && (
          <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
            <h2>Feil fra server</h2>
            <ul>
              {Object.keys(errorsFromServer).map((key) => (
                <li key={generateGuid()}>
                  {key}
                  <ul>{updateChildren(errorsFromServer[key])}</ul>
                </li>
              ))}
            </ul>
          </Card>
        )}
      </ThemeSection>
    </div>
  );
};

export default DevelopmentApplicationSummary;
