import React from 'react';
import { ModuleTypes } from '@hooks/authorization/use-auth';

export type MenuItem = {
  href?: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export type NavItem = {
  href?: string;
  text: string | React.ReactNode;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export type NavItemModule = {
  module?: ModuleTypes;
  text: string;
  path: string;
};

export const NavItemModules: Record<string, NavItemModule> = {
  ResourceBase: {
    module: 'ResourceBase',
    text: 'Ressursbasen',
    path: 'resource-base',
  },
  Operation: {
    module: 'Operation',
    text: 'Klyngedrift',
    path: 'cluster-operation',
  },
  Projects: {
    module: 'PortalProject',
    text: 'Prosjekter',
    path: 'projects',
  },
  Assignments: {
    // module: 'Assignments',
    text: 'Oppgaver',
    path: 'assignments',
  },
  Statistics: {
    module: 'Statistics',
    text: 'Statistikk',
    path: 'statistics',
  },
  Financing: {
    // module: 'Financing',
    text: 'Finansiering',
    path: 'financing',
  },
  Opportunities: {
    // module: 'Opportunities',
    text: 'Henvendelser',
    path: 'opportunities',
  },
};

const ClusterNavItems = [
  NavItemModules.ResourceBase,
  NavItemModules.Operation,
  NavItemModules.Projects,
  NavItemModules.Assignments,
  NavItemModules.Statistics,
  NavItemModules.Financing,
];

// For klynger (gce)
const GCEClusterNavItems = [
  NavItemModules.ResourceBase,
  NavItemModules.Projects,
  NavItemModules.Assignments,
  NavItemModules.Statistics,
  NavItemModules.Financing,
];

// Bedriftsnettverk
const BusinessNetworkNavItems = [
  NavItemModules.ResourceBase,
  NavItemModules.Projects,
  NavItemModules.Assignments,
  NavItemModules.Statistics,
  NavItemModules.Financing,
];

// Nettverk
const NetworkNavItems = [
  NavItemModules.ResourceBase,
  NavItemModules.Projects,
  NavItemModules.Assignments,
  NavItemModules.Statistics,
  NavItemModules.Financing,
];

// Næringshager
const BusinessGardenNavItems = [
  NavItemModules.Opportunities,
  NavItemModules.ResourceBase,
  NavItemModules.Projects,
  NavItemModules.Statistics,
];

// Samlet alle (fungere som en switch)
export const AppNavItems = {
  // Klynger
  ClusterInitiative: ClusterNavItems,
  Arena: ClusterNavItems,
  ArenaPro: ClusterNavItems,
  Nce: ClusterNavItems,
  NceOutsideNic: ClusterNavItems,
  Gce: GCEClusterNavItems,
  MatureClusters: ClusterNavItems,
  // Bedriftsnettverk
  BusinessNetwork: BusinessNetworkNavItems,
  // Nettverk
  Network: NetworkNavItems,
  // Næringshager
  BusinessGarden: BusinessGardenNavItems,
  // Ikke satt
  NotSet: [], // Hva skal vi ha her?
};
