import css from '../../../components/SumTable/SumTable.module.scss';
import classNames from 'classnames';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';
import {
  Button,
  ButtonVariant,
  FaIcons,
  IconButton,
  NumberInput,
  Select,
  Theme,
} from '@in/component-library';
import { formatAsCurrencyString } from '../../../modules/utils/method-modules/FormatValue';
import React, { FC, useCallback } from 'react';
import { sumAllPropertiesInCollection } from '../../../hooks/use-development-budget';
import { Cost, ProjectApplicationTypeEnum } from '../../../models';
import { NewActivityFormInputs } from './NewActivity';
import useChoices from '../../../hooks/choices/use-choices';
import generateGuid from '../../../utils/Guid';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { DeepPartial } from '@src/types/DeepPartial';

type ActivityCostTableProps = {
  control: Control<NewActivityFormInputs>;
  getValues: UseFormGetValues<NewActivityFormInputs>;
};
export const ActivityCostTable: FC<ActivityCostTableProps> = ({ control, getValues }) => {
  const {
    fields: costs,
    remove,
    append: appendCost,
  } = useFieldArray<NewActivityFormInputs, 'costs', 'costInternalId'>({
    control,
    name: 'costs',
    keyName: 'costInternalId',
  });

  const { expenseTypes } = useChoices(ProjectApplicationTypeEnum.OperationApplication);
  const results = useWatch({ control });

  const applicantAmountAndParticipantsAmountTotal = sumAllPropertiesInCollection<DeepPartial<Cost>>(
    results?.costs ? results?.costs : [],
    ['applicantAmount', 'participantsAmount'],
  );
  const getExpenseTypeName = useCallback(
    (code: string | undefined) => {
      return expenseTypes.find((item) => item.value === code)?.label;
    },
    [expenseTypes],
  );
  function addCostRowHandler(): void {
    appendCost({ id: generateGuid(), participantsAmount: 0, applicantAmount: 0 } as Cost);
  }
  return (
    <>
      <div className={css['sum-table']}>
        <div className={classNames(css['sum-table__row'], css['sum-table__row--four-columns'])}>
          <div className={classNames(css['sum-table__cell'], css['sum-table__cell-header'])}>
            <div className={css['sum-table__column-label']}>Kostnadstype</div>
          </div>

          <div
            className={classNames(
              css['sum-table__cell'],
              css['sum-table__cell-header'],
              css['sum-table__cell--currency-input'],
            )}
          >
            <div className={css['sum-table__column-label']}>Kostnader i søkerbedrift</div>
          </div>

          <div className={classNames(css['sum-table__cell'], css['sum-table__cell-header'])}>
            <div className={css['sum-table__column-label']}>Kostnader i deltagerbedrifter</div>
          </div>

          <div
            className={classNames(
              css['sum-table__cell  sum-table__cell-header'],
              css['sum-table__cell--delete'],
            )}
          />
        </div>
        {costs != null &&
          costs.map((cost, index: number) => {
            return (
              <div key={cost.id}>
                <div className={classNames(css['sum-table__row'], css['sum-table__row--three-columns'])}>
                  <div className={classNames(css['sum-table__cell'], css['sum-table__cell--currency-input'])}>
                    <Controller
                      control={control}
                      name={`costs.${index}.type` as const}
                      rules={{
                        validate: (value) => (value != null && value !== ' ' ? true : 'Feltet er påkrevd'),
                      }}
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          className={css['sum-table__cell-cost-type']}
                          label=""
                          errorMsg={fieldState.error?.message}
                          options={expenseTypes.map((item) => {
                            return {
                              text: item?.label ?? '',
                              value: item?.value ? item.value.toString() : 'no-value',
                            };
                          })}
                          placeholder={'Velg'}
                        />
                      )}
                    />
                  </div>
                  <div className={classNames(css['sum-table__cell'], css['sum-table__cell--currency-input'])}>
                    <Controller
                      control={control}
                      name={`costs.${index}.applicantAmount` as const}
                      rules={{
                        validate: (value) => {
                          return (value != null && value > 0) ||
                            Number(getValues(`costs.${index}.participantsAmount`)) > 0
                            ? true
                            : 'Dere må ha et beløp i minst ett av feltene';
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          aria-label={`${getExpenseTypeName(
                            getValues(`costs.${index}.type`),
                          )} Kostnader i søkerbedrift`}
                          type="text"
                          autoComplete="off"
                          numberLocale="nb-NO"
                          suffix="NOK"
                          decimal={false}
                          onChange={(event) => {
                            field.onChange(+event.currentTarget.value);
                          }}
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          errorMsg={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={classNames(css['sum-table__cell'], css['sum-table__cell--currency-input'])}>
                    <Controller
                      control={control}
                      name={`costs.${index}.participantsAmount` as const}
                      rules={{
                        validate: (value) => {
                          return (value != null && value > 0) ||
                            Number(getValues(`costs.${index}.applicantAmount`)) > 0
                            ? true
                            : 'Dere må ha et beløp i minst ett av feltene';
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          aria-label={`${getExpenseTypeName(
                            getValues(`costs.${index}.type`),
                          )} Kostnader i deltagerbedrifter `}
                          type="text"
                          autoComplete="off"
                          numberLocale="nb-NO"
                          suffix="NOK"
                          decimal={false}
                          onChange={(event) => {
                            field.onChange(+event.currentTarget.value);
                          }}
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          errorMsg={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={classNames(css['sum-table__cell'], css['sum-table__cell--delete'])}>
                    {index !== 0 && (
                      <IconButton
                        type="button"
                        ariaLabel="slett rad"
                        iconName={FaIcons.Trash}
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        <div className={classNames(css['sum-table__row'], css['sum-table__row--four-columns'])}>
          <div className={css['sum-table__cell']}>
            <span className={css['sum-table__total-label']}>Sum kostnad</span>
          </div>

          <div
            className={classNames(
              css['sum-table__cell'],
              css['sum-table__cell--total-sum'],
              css['sum-table__row--span-col-three-to-five'],
            )}
          >
            <span className={classNames(css['sum-table__total-value'])}>
              {formatAsCurrencyString(applicantAmountAndParticipantsAmountTotal, ' ')}
            </span>
          </div>

          <div className={classNames(css['sum-table__cell'], css['sum-table__cell--delete'])} />
        </div>
      </div>
      <div className="section--margin-bottom">
        <Button
          type={'button'}
          theme={Theme.Neutral}
          variant={ButtonVariant.Link}
          iconName={FaIcons.PlusLight}
          onClick={addCostRowHandler}
        >
          Legg til kostnad
        </Button>
      </div>
    </>
  );
};
