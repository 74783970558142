/**
 * Method for sorting an array based on a property of the objects in the array.
 *
 * @param {Array}   array       - The array we want to sort.
 * @param {string}  property    - The property we want to sort on.
 * @param {string}  type        - valid values : "number", "string"
 * @param {string}  direction   - valid values : "DEC", "ACC".
 * @returns {Array}
 */
export function sortArrayOnProperty<TObject>(
  array: TObject[],
  property: keyof TObject,
  type?: 'string' | 'number' | 'date',
  direction?: 'ascending' | 'descending'
): TObject[] {
  if (type === void 0) {
    type = 'string';
  }
  if (direction === void 0) {
    direction = 'descending';
  }
  const flipSort = direction === 'descending' ? 1 : -1;
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      let aProperty: string | number = '';
      let bProperty: string | number = '';
      if (a[property] != null) {
        // @ts-ignore
        aProperty = a[property].toString().toLowerCase();
      }
      if (b[property] != null) {
        // @ts-ignore
        bProperty = b[property].toString().toLowerCase();
      }

      if (type === 'number') {
        // @ts-ignore
        aProperty = parseFloat(a[property].toString());
        // @ts-ignore
        bProperty = parseFloat(b[property].toString());
      }
      return (aProperty < bProperty ? -1 : aProperty > bProperty ? 1 : 0) * flipSort;
    });
  }
  return array;
}

/**
 * Method for finding an object in an array, based on the value of a property on the object.
 * Returns the index of the first object to match the property value.
 * @param {TKey}  searchProperty  - The property name containing the value you want to match
 * @param {TObject}  searchValue     - The value you want to match
 * @param {TObject[]}   objectArray     - Array of objects you want to search.
 * @return {int}                    - Index of the first object with matching property value.
 * @public
 */

export function searchObjectArray<TObject, TKey extends keyof TObject = keyof TObject>(
  searchProperty: TKey,
  searchValue: TObject[TKey],
  objectArray: TObject[] | undefined
): number {
  if (!objectArray) {
    return -1;
  }

  let length: number = objectArray.length;

  while (--length > -1) {
    if (objectArray[length][searchProperty] === searchValue) {
      return length;
    }
  }

  return -1;
}
