import React, { FC } from 'react';
import { Link } from '@in/component-library/';

const NotFound: FC = () => {
  return (
    <div>
      <h1>Finner ikke siden dere ser etter (404)</h1>
      <Link href="/">Gå til forsiden</Link>
    </div>
  );
};
export default NotFound;
