import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Operation, PermissionDtoV2, UserClient } from 'src/models/clusters-application.models';

import { AUTH_KEY } from '@src/constants/hook-keys';

export type ModuleTypes =
  | 'ResourceBase'
  | 'AccountSettings'
  | 'Report'
  | 'Inkind'
  | 'Operation'
  | 'OneWayIn'
  | 'OneWayInOpportunity'
  | 'DevelopmentProject'
  | 'PortalProject'
  | 'Course'
  | 'Core'
  | 'Statistics'
  | 'MainGoal'
  | 'FocusArea'
  | 'MemberEconomy'
  | 'AccessControl'
  | 'Application'
  | 'AnnualApplication'
  | 'WorkGroup';

type AccessType = {
  read: boolean;
  write: boolean;
};

function useAuth(clusterId) {
  const [service] = useState(new UserClient());

  const { data, ...rest } = useQuery<PermissionDtoV2>(
    [AUTH_KEY, { clusterId: clusterId }],
    async () => {
      return await service.getUserRolePermissions(clusterId);
    },
    {
      enabled: !!clusterId,
    },
  );

  const checkAccess = useCallback(
    (module: ModuleTypes): AccessType => {
      if (data) {
        const item = data!.permissions ? data!.permissions[module] : undefined;
        if (item !== undefined) {
          return {
            read: item !== Operation.None,
            write: item === Operation.Write,
          };
        }
      }
      return {
        read: false,
        write: false,
      };
    },
    [data],
  );

  return {
    data,
    ...rest,

    checkAccess,
  };
}

export default useAuth;
