import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ApplicationsClient,
  BrRegMember,
  ClusterMember,
  Cost,
  DevelopmentWithMetadataDto,
  FocusArea,
  PlannedActivity,
  SocialImpact,
  SocietalEnvironmentalImpact,
} from '../../models';
import {
  APP_IS_SAVING_DATA,
  CREATE_APPLICATION_SETUP,
  DEVELOPMENT_APPLICATION,
} from '../../constants/hook-keys';
import { DeepPartial } from '@src/types/DeepPartial';

export default function useDevelopmentApplication(id: string | null) {
  const [applicationsClient] = useState(new ApplicationsClient());
  const queryClient = useQueryClient();
  const {
    data: developmentApplication,
    isLoading: isLoading,
    isFetching: developmentApplicationIsFetching,
    isFetched: developmentApplicationIsFetched,
  } = useQuery([DEVELOPMENT_APPLICATION, id], () => applicationsClient.getDevelopmentApplication(id), {
    enabled: !!id,
    retry: false,
  });

  const patchDevelopmentApplicationMutation = useMutation(
    (data: DeepPartial<DevelopmentWithMetadataDto>) => {
      queryClient.setQueryData([APP_IS_SAVING_DATA], true);
      return applicationsClient.partiallyUpdateDevelopmentApplication(id, data as DevelopmentWithMetadataDto);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
        queryClient.setQueryData([APP_IS_SAVING_DATA], false);
      },
    },
  );

  const putFocusAreasMutation = useMutation(
    (focusAreas: DeepPartial<FocusArea>[]) => {
      return applicationsClient.updateDevelopmentFocusAreas(id, focusAreas as FocusArea[]);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const putDevelopmentParticipantsMutation = useMutation(
    (participants: ClusterMember[]) => {
      return applicationsClient.replaceDevelopmentParticipants(id, participants);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );
  const putDevelopmentBrRegParticipantsMutation = useMutation(
    (brRegParticipants: BrRegMember[]) => {
      return applicationsClient.replaceDevelopmentBrRegParticipants(id, brRegParticipants);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );
  const putDevelopmentActivityMutation = useMutation(
    ({ planedActivityId, planedActivity }: { planedActivityId: string; planedActivity: PlannedActivity }) => {
      return applicationsClient.updateDevelopmentActivity(id, planedActivityId, planedActivity);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const deleteDevelopmentActivityMutation = useMutation(
    (planedActivityId: string) => {
      return applicationsClient.deleteDevelopmentActivity(id, planedActivityId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const patchImpactsMutation = useMutation(
    ({ socialImpacts, index }: { socialImpacts: DeepPartial<SocialImpact>[]; index: number }) => {
      const impactsRequest: { impact: DeepPartial<SocietalEnvironmentalImpact> } = {
        impact: {
          impacts: [socialImpacts[index] as SocialImpact],
        },
      };
      return applicationsClient.partiallyUpdateDevelopmentApplication(
        id,
        impactsRequest as DevelopmentWithMetadataDto,
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const putImpactsMutation = useMutation(
    (socialImpacts: SocialImpact[]) => {
      return applicationsClient.updateDevelopmentSocialImpacts(id, socialImpacts);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const deleteImpactsMutation = useMutation(
    (impactId: string) => {
      return applicationsClient.deleteDevelopmentSocietalEnvironmentalImpact(id, impactId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const putDevelopmentCostsMutation = useMutation(
    (costs: Cost[]) => {
      return applicationsClient.updateDevelopmentCosts(id, costs);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const deleteFinancingSourceMutation = useMutation(
    (financingSourceId: string) => {
      return applicationsClient.deleteDevelopmentFinancingSource(id, financingSourceId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
      },
    },
  );

  const patchDevelopmentApplicationTitleMutation = useMutation(
    (title: string) => {
      return applicationsClient.updateDevelopmentApplicationTitle(id, {
        title: title,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([DEVELOPMENT_APPLICATION, id]);
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );
  const validateDevelopmentApplicationMutation = useMutation(
    () => {
      return applicationsClient.validateDevelopmentApplication(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );

  return {
    developmentApplication: developmentApplication,
    projectFocusAreas: developmentApplication?.project?.focusAreas
      ? developmentApplication?.project?.focusAreas
      : [],
    applicationTypeSpecificInformation: developmentApplication?.project?.applicationTypeSpecificInformation,
    participants: developmentApplication?.project?.participants?.participants ?? [],
    brRegParticipants: developmentApplication?.project?.participants?.brRegParticipants ?? [],
    activities: developmentApplication?.plannedActivities ?? [],
    impacts: developmentApplication?.impact?.impacts ?? [],
    attachments: developmentApplication?.attachments ?? [],
    applicationFinancingSources: developmentApplication?.financing?.sources ?? [],
    costs: developmentApplication?.costs ?? [],
    isLoading,
    developmentApplicationIsFetching,
    patchDevelopmentApplicationMutation,
    patchImpactsMutation,
    putFocusAreasMutation,
    putDevelopmentParticipantsMutation,
    putDevelopmentBrRegParticipantsMutation,
    putDevelopmentActivityMutation,
    putImpactsMutation,
    putDevelopmentCostsMutation,
    deleteDevelopmentActivityMutation,
    deleteImpactsMutation,
    deleteFinancingSourceMutation,
    developmentApplicationIsFetched,
    patchDevelopmentApplicationTitleMutation,
    validateDevelopmentApplicationMutation,
  };
}
