import React from 'react';
import { Accordion } from '@in/component-library';
import { Control } from 'react-hook-form';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';
import GoalInputFields from './GoalInputFields';
import {
  GoalsAndFocusAreasFormInputs,
  MainGoal,
} from '../../Application/themePageSections/ClustersGoalsAndFocusAreas';

export interface GoalAccordionProps {
  showSaveButton?: boolean;
  onRemove: (index: number, mainGoal: MainGoal) => void;
  onSave: (index: number, isDirty) => void;
  saveProcessActive: boolean;
  onlyDescription?: boolean;
  index: number;
  label?: string;
  control: Control<GoalsAndFocusAreasFormInputs, any>;
  fields: FieldArrayWithId<GoalsAndFocusAreasFormInputs, 'mainGoals', 'mainGoalsInternalId'>;
}

const GoalAccordion = React.forwardRef<HTMLTextAreaElement, GoalAccordionProps>(
  (props: GoalAccordionProps, ref) => {
    return (
      <Accordion
        title={props.fields.name}
        id={`goal_this_year_${props.fields.id}`}
        open={
          props.onlyDescription
            ? props.fields?.description === '' || props?.fields?.description == null
            : props.fields.achievementComments === '' ||
              props?.fields?.achievementComments == null ||
              props.fields.activitiesForAchieving === '' ||
              props?.fields?.activitiesForAchieving == null
        }
      >
        <GoalInputFields {...props} ref={ref} />
      </Accordion>
    );
  },
);
GoalAccordion.displayName = 'GoalAccordion';
export default GoalAccordion;
