import { useState } from 'react';
import useUser from '@hooks/user/use-user';
import {
  CLUSTER,
  CLUSTERS,
  USER_KEY,
  USER_STATUS_HAS_CLUSTERS,
  USER_STATUS_LOADING,
  USER_STATUS_LOGGED_IN,
  USER_STATUS_REGISTERED,
} from '@src/constants/hook-keys';
import { ClusterDto, ClustersClient, SessionClient } from '@src/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export default function useClusters() {
  const { user, chosenClusterId, setHasUserClusters } = useUser();
  const [clustersClient] = useState(new ClustersClient());
  const [sessionClient] = useState(new SessionClient());
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery([CLUSTERS], () => clustersClient.getMyClusters(), {
    enabled: !!user,
    onSuccess: (data) => {
      const found = data.find((x) => x.id === chosenClusterId);

      if (found) {
        setHasUserClusters(true);
        queryClient.setQueryData([CLUSTER], found);
        return;
      }

      if (data.length === 0) {
        setHasUserClusters(false);
      } else {
        setHasUserClusters(true);
      }

      if (data.length === 1) {
        sessionClient.storeChosenCluster(data[0]!.id).finally(() => {
          queryClient.setQueryData([CLUSTERS], data[0]);
        });
      }
    },
    onError: () => setHasUserClusters(false),
  });

  const storeChosenClusterMutation = useMutation(
    async (cluster: ClusterDto) => {
      return await sessionClient.storeChosenCluster(cluster.id);
    },
    {
      onSuccess(_, cluster) {
        const keepQueries = [
          USER_STATUS_LOGGED_IN,
          USER_STATUS_LOADING,
          USER_STATUS_REGISTERED,
          USER_STATUS_HAS_CLUSTERS,
          CLUSTER,
          CLUSTERS,
        ];

        queryClient.setQueryData([CLUSTER], cluster);

        const userData = queryClient.getQueriesData([USER_KEY]);
        queryClient.setQueryData([USER_KEY], { ...userData, chosenClusterId: cluster.id });

        queryClient.resetQueries({
          predicate: (query) => {
            return !keepQueries.includes(query.queryKey[0] as string);
          },
        });
      },
    },
  );

  const deleteChosenClusterMutation = useMutation(
    () => {
      return sessionClient.deleteChosenCluster();
    },
    {
      onSuccess() {
        queryClient.setQueryData([USER_STATUS_LOADING], false);
      },
    },
  );

  return {
    clusters: data || [],

    ...rest,

    storeChosenClusterMutation,
    deleteChosenClusterMutation,
  };
}
