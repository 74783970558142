import React from 'react';
import css from './CustomCheckbox.module.scss';
import { FaIcon, FaIcons } from '@in/component-library';
import cn from 'classnames';

const CustomCheckbox = ({
  disabled,
  ...prop
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'autoComplete'>) => {
  return (
    <div
      className={cn(css['checkbox'], {
        [css['checkbox__checkbox--disabled']]: disabled,
      })}
    >
      <label>
        <input {...prop} disabled={disabled} type="checkbox" className={css['checkbox__checkbox']} />
        <span className={css['checkbox__checkmark']}>
          <span className={css['checkbox__checkmark-icon']}>
            <FaIcon name={FaIcons.Check} />
          </span>
        </span>
      </label>
    </div>
  );
};
export default CustomCheckbox;
