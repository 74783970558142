import React, { FC, useCallback } from 'react';
import css from './StatusIcon.module.scss';
import { FaIcon } from '@in/component-library';
import { icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';

export enum StatusIconType {
  COMPLETE,
  IN_PROGRESS,
  PENDING,
  ERROR,
  NONE,
}
export interface StatusIconProps {
  type: StatusIconType;
}

const StatusIcon: FC<StatusIconProps> = ({ type }: StatusIconProps) => {
  const getIconCssClass = useCallback((): string => {
    switch (type) {
      case StatusIconType.COMPLETE:
        return 'status-icon--complete';
      case StatusIconType.IN_PROGRESS:
        return 'status-icon--in-progress';
      case StatusIconType.PENDING:
        return 'status-icon--pending';
      case StatusIconType.ERROR:
        return 'status-icon--error';
      default:
        return 'status-icon--pending';
    }
  }, [type]);
  const getIcon = useCallback((): IconDefinition => {
    switch (type) {
      case StatusIconType.COMPLETE:
        return icon({ prefix: 'fal', iconName: 'circle-check' });
      case StatusIconType.IN_PROGRESS:
        return icon({ prefix: 'fal', iconName: 'scrubber' });
      case StatusIconType.PENDING:
        return icon({ prefix: 'fal', iconName: 'circle' });
      case StatusIconType.ERROR:
        return icon({ prefix: 'fal', iconName: 'exclamation-circle' });
      default:
        return icon({ prefix: 'fal', iconName: 'circle' });
    }
  }, [type]);

  const getIconTitle = useCallback(() => {
    switch (type) {
      case StatusIconType.COMPLETE:
        return 'Alt er ok';
      case StatusIconType.IN_PROGRESS:
        return 'Påbegynt';
      case StatusIconType.PENDING:
        return 'Ikke påbegynt';
      case StatusIconType.ERROR:
        return 'Det er feil på siden';
      default:
        return '';
    }
  }, [type]);
  return (
    <span className={cn(css['status-icon'], css[getIconCssClass()])}>
      <FaIcon title={getIconTitle()} icon={getIcon()} />
    </span>
  );
};
export default StatusIcon;
