import React, { useEffect, useRef } from 'react';
import { Dialog, Alignment } from '@in/component-library';

type DialogContainerProps = {
  children: React.ReactNode;
  show: boolean;
  /**
   * This gets fired when the user clicks on the close button ("X") or on the overlay
   */
  onClose: () => void;
  /**
   * This is the title of the modal, you need to set the title even if you're not going to show the header or the title, because we use it for the aria-label
   */
  title: string;
  /**
   * You can add you're own css class to the modal if you need to
   */
  customCssClass?: string;
  /**
   * You can add you´re own css class to the content wrapper to override the content style
   */
  modalContentClassName?: string;
  /**
   * Set this to true if you don't want the modal to close when clicking on the overlay, primary usage is if there is form elements inside the modal
   */
  preventClickOutside?: boolean;
  /**
   * Set this to false if you dont want the "X" close button in the corner
   */
  showCloseButton?: boolean;
  /**
   * Override aria-label on close button
   */
  closeButtonLabel?: string;
  /**
   * Set this to false if you want to hide the title
   */
  showTitle?: boolean;
  /**
   * Set this to false if you want to hide the header, this includes the title and the close button
   */
  showHeader?: boolean;
  /**
   * Set this to false if you don't want padding on the container, let's say you have a component that has the desired padding it self
   */
  modalContainerPadding?: boolean;
  /**
   * Set max width if default width doesn't fit you're needs
   */
  maxWidth?: number;
  className?: string;
  id?: string;
  /**
   * This is the id of the element you want to append the modal to.
   * If not set, default is id root
   * If it does not find a div with the id a div with your id (or "root" if not set)
   * is created and appended to body as container for all modals
   */
  parentId?: string;
  /**
   * Set vertical alignment of modal
   * @default Alignment.Center
   * */
  verticalAlignment?: Alignment;
};

const DialogContainer: React.FC<DialogContainerProps> = ({
  show,
  title,
  children,
  onClose,
  showCloseButton = true,
  closeButtonLabel = 'Lukk dialog',
  className,
  id,
  verticalAlignment = Alignment.Center,
}) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (show) {
      ref?.current?.showModal();
    } else {
      ref?.current?.close();
    }
  }, [show]);

  return (
    <Dialog
      ref={ref}
      title={title}
      className={className}
      closeButton={
        showCloseButton
          ? {
              'aria-label': closeButtonLabel,
              onClick: () => {
                onClose();
              },
            }
          : undefined
      }
      onClose={onClose}
      verticalAlignment={verticalAlignment}
      id={id}
    >
      {children}
    </Dialog>
  );
};
export default DialogContainer;
