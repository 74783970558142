export function getBoardMemberName(role: string | undefined) {
  switch (role) {
    case 'ClusterManager':
      return 'Klyngeleder';
    case 'BoardChairman':
      return 'Styreleder';
    case 'BoardMember':
      return 'Styremedlem';
    case 'BoardVaraMember':
      return 'Varamedlem';
    case 'ProjectManager':
      return 'Prosjektleder';
    default:
      return '-';
  }
}
