import { useState } from 'react';
import { CLUSTER } from '@src/constants/hook-keys';
import { useQuery } from '@tanstack/react-query';
import { ClustersClient } from '@src/models/clusters-application.models';
import useUser from '@hooks/user/use-user';

function useMyCluster() {
  const [clustersClient] = useState(new ClustersClient());
  const { chosenClusterId } = useUser();

  const { data: cluster, ...rest } = useQuery([CLUSTER], () => clustersClient.getMyClusterV2(), {
    enabled: !!chosenClusterId,
  });

  return { cluster: cluster, ...rest };
}

export default useMyCluster;
