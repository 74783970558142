import React, { FC, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonVariant,
  FileItem,
  FileUpload,
  ShadowHeight,
  TextArea,
  Theme,
} from '@in/component-library';
import { useDocTitle, useSummaryList } from '../../../hooks/CustomHooks';
import { getFilesFromAttachmentsByInputFieldName } from '../../../utils/AttachmentHelper';
import { SummaryCheckList } from './SummaryCheckList';
import { Card } from '@in/component-library/';
import generateGuid from '../../../utils/Guid';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { Controller, useForm } from 'react-hook-form';
import { readyToSubmitReceiptRouteConfigObject } from '../../../routeConfigs/site/ReadyToSubmitReceiptRouteConfig';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
import { validateOperationPage } from '../../../components/Navigation/ApplicationMenu/ApplicationMenu';
import { baseSiteRoutePaths } from '../../../routeConfigs/site/SiteRouteConfig';
import { operationApplicationRoutePaths } from '../../../routeConfigs/application/OperationApplicationRouteConfig';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import useAttachments from '../../../hooks/attachment/use-attachements';
import useMainApplication from '../../../hooks/mainApplication/use-main-application';
import { OPERATION_APPLICATION } from '../../../constants/hook-keys';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export interface SummaryFields {
  extraComments: string;
}
export interface CompleteFormProps {
  title?: string;
  ingress?: string;
}
const CompleteForm: FC<CompleteFormProps> = ({ title, ingress }) => {
  useDocTitle('Oppsummering');
  const fileUploadFieldName = 'summaryExtraDocuments';
  const { applicationId } = useUrlApplicationId();
  const { search, pathname } = useLocation();
  const {
    attachments,
    extraComments,
    patchOperationApplicationMutation,
    operationApplication,
    validateOperationApplicationMutation,
  } = useOperationApplication(applicationId);
  const { createApplicationSetup } = useMainApplication();
  const navigate = useNavigate();

  const { trigger, control, getValues, reset } = useForm<SummaryFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: { extraComments: extraComments ?? '' },
  });

  const { summaryList } = useSummaryList(pathname, attachments);

  const summaryAttachments = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, fileUploadFieldName);
  }, [attachments, fileUploadFieldName]);

  const { uploadFile, deleteFile } = useAttachments([OPERATION_APPLICATION, applicationId!], applicationId);

  const [errorsFromServer, setErrorsFromServer] = useState<Record<string, string[]> | null>(null);
  useEffect(() => {
    if (extraComments == null) {
      return;
    }
    reset({ ...getValues(), extraComments: extraComments });
  }, [reset, getValues, extraComments]);

  const allPagesIsValid = useCallback(() => {
    return summaryList.every((route) => {
      const allChildrenIsValid = route.children.every(
        (childRoute) =>
          validateOperationPage(childRoute.id, operationApplication, createApplicationSetup) === 0,
      );
      return route.children != null && route.children.length > 0 ? allChildrenIsValid : false;
    });
  }, [summaryList, operationApplication, createApplicationSetup]);

  function updateChildren(children) {
    return children.map((child) => <li key={generateGuid()}>{child}</li>);
  }

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection>
        <h2>Har dere fylt ut alt?</h2>
        <p>
          Vi har sjekket alle sider og alle felter, her ser dere resultatet. Når alle felt er fylt ut, vil
          &quot;Klar til innsending&quot;-knappen aktiveres.
        </p>
      </ThemeSection>

      <SummaryCheckList
        data={summaryList}
        excludePathFromErrorMessage={[operationApplicationRoutePaths.estimatedFinancingNeeds]}
        operationApplication={operationApplication}
        mainApplication={createApplicationSetup}
      />
      <ThemeSection>
        <h2>Tilleggsinformasjon</h2>
        <p>
          Er det noe dere vil nevne for oss som dere ikke har fått sagt andre steder kan dere gjøre det her
        </p>
        <Controller
          control={control}
          name={`extraComments`}
          rules={{ maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' } }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Eventuell tilleggsinformasjon"
              maxCount={InputMaxLength.medium}
              maxLength={InputMaxLength.medium}
              rows={3}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={() => {
                field.onBlur();
                trigger('extraComments').then((isValid) => {
                  if (isValid) {
                    if (getValues('extraComments') !== extraComments) {
                      patchOperationApplicationMutation.mutateAsync({
                        extraComments: getValues('extraComments'),
                      });
                    }
                  }
                });
              }}
            />
          )}
        />

        <FileUpload
          files={summaryAttachments}
          uploadCallback={uploadFile}
          deletionCallback={deleteFile}
          name={fileUploadFieldName}
          label="Har dere noen vedlegg som dere ikke fant det naturlig å laste opp andre steder, kan disse legges ved her."
          fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          requirementsText="Tillatte filtyper: PDF, Excel, Word"
        />
      </ThemeSection>
      <ThemeSection>
        {errorsFromServer && (
          <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
            <h2>Feil fra server</h2>
            <ul>
              {Object.keys(errorsFromServer).map((key) => (
                <li key={generateGuid()}>
                  {key}
                  <ul>{updateChildren(errorsFromServer[key])}</ul>
                </li>
              ))}
            </ul>
          </Card>
        )}
      </ThemeSection>
      <ThemeSection>
        <h2>Klar til innsending?</h2>
        <p>Er søknaden ferdig?</p>
        <div className="button-container">
          <Button
            isLoading={validateOperationApplicationMutation.isLoading}
            theme={Theme.Neutral}
            variant={ButtonVariant.Solid}
            disabled={!allPagesIsValid() || validateOperationApplicationMutation.isLoading}
            onClick={async () => {
              validateOperationApplicationMutation
                .mutateAsync()
                .then(() => {
                  navigate(
                    readyToSubmitReceiptRouteConfigObject.operationApplicationReadyToSubmitPage.path + search,
                  );
                })
                .catch((error) => {
                  setErrorsFromServer(error as Record<string, string[]>);
                });
            }}
          >
            Klar til innsending
          </Button>
          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={() => navigate(baseSiteRoutePaths.applicationsOverviewPath)}
          >
            Lagre som utkast
          </Button>
        </div>
      </ThemeSection>
    </div>
  );
};

export default CompleteForm;
