import {
  ApplicationsClient,
  FocusArea,
  GoalNextYear,
  GoalThisYear,
  OperationPlannedActivity,
  OperationWithMetadataDto,
  SocialImpact,
} from '../../models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CREATE_APPLICATION_SETUP, OPERATION_APPLICATION } from '../../constants/hook-keys';
import { toast } from 'react-toastify';
import { DeepPartial } from '@src/types/DeepPartial';

export default function useOperationApplication(id: string | null) {
  const applicationsClient = new ApplicationsClient();
  const queryClient = useQueryClient();

  const { data: operationApplicationResults, ...rest } = useQuery(
    [OPERATION_APPLICATION, id],
    () => applicationsClient.getOperationApplication(id),
    {
      enabled: !!id,
      retry: 0,
      staleTime: 1000 * 60 * 5, // 5 min staletime, refetches on mount if stale
      refetchOnMount: true,
      onError() {
        toast.error('Klarte ikke hente klyngedrift', { toastId: OPERATION_APPLICATION });
      },
    },
  );

  const activity = operationApplicationResults?.activity;
  const financing = operationApplicationResults?.financing;
  const impact = operationApplicationResults?.impact;

  const putOperationActivityMutation = useMutation(
    (activity: OperationPlannedActivity) => {
      return applicationsClient.updateOperationActivity(id, activity?.id ?? '', activity);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const putOperationGoalsThisYearMutation = useMutation(
    (goalThisYears: DeepPartial<GoalThisYear>[]) => {
      return applicationsClient.updateOperationGoalsThisYear(id, goalThisYears as GoalThisYear[]);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const putOperationGoalsNextYearMutation = useMutation(
    (goalsNextYear: GoalNextYear[]) => {
      return applicationsClient.updateOperationGoalsNextYear(id, goalsNextYear);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const putOperationFocusAreasMutation = useMutation(
    (focusAreasNextYear: FocusArea[]) => {
      return applicationsClient.updateOperationFocusAreas(id, focusAreasNextYear);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const putOperationSocialImpactsMutation = useMutation(
    (operationApplicationImpacts: SocialImpact[]) => {
      return applicationsClient.updateOperationSocialImpacts(id, operationApplicationImpacts);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
      onError() {
        toast.error('Klarte ikke lagre effekt');
      },
    },
  );

  const patchOperationApplicationMutation = useMutation(
    (data: DeepPartial<OperationWithMetadataDto>) => {
      return applicationsClient.partiallyUpdateOperation(id, data as OperationWithMetadataDto);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
      onError() {
        toast.error('Klarte ikke lagre ');
      },
    },
  );

  const deleteOperationActivityMutation = useMutation(
    (planedActivityId: string) => {
      return applicationsClient.deleteOperationActivity(id, planedActivityId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const deleteOperationGoalThisYearMutation = useMutation(
    (operationGoalId: string) => {
      return applicationsClient.deleteOperationGoalThisYear(id, operationGoalId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const deleteOperationGoalNextYearMutation = useMutation(
    (goalId: string) => {
      return applicationsClient.deleteOperationGoalNextYear(id, goalId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const deleteOperationFocusAreaMutation = useMutation(
    (focusAreaId: string) => {
      return applicationsClient.deleteOperationFocusArea(id, focusAreaId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const deleteOperationSocietalEnvironmentalImpact = useMutation(
    (impactId: string) => {
      return applicationsClient.deleteOperationSocietalEnvironmentalImpact(id, impactId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const deleteOperationFinancingSourceMutation = useMutation(
    (financingSourceId: string) => {
      return applicationsClient.deleteOperationFinancingSource(id, financingSourceId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([OPERATION_APPLICATION, id]);
      },
    },
  );

  const validateOperationApplicationMutation = useMutation(
    () => {
      return applicationsClient.validateOperationApplication(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([CREATE_APPLICATION_SETUP]);
      },
    },
  );

  const operationApplication = operationApplicationResults;
  return {
    operationApplication: operationApplication,
    goalsThisYear: activity?.goalsThisYear ?? [],
    goalsNextYear: activity?.goalsNextYear ?? [],
    focusAreasNextYear: activity?.focusAreasNextYear ?? [],
    plannedActivities: activity?.plannedActivities ?? [],
    financingSources: financing?.sources ?? [],
    impacts: impact?.impacts ?? [],
    notApplicable: impact?.notApplicable,
    notApplicableComment: impact?.notApplicableComment,
    financingComments: financing?.comments,
    plannedActivitiesOverallDescription: activity?.plannedActivitiesOverallDescription,
    resultsDescription: activity?.resultsDescription,
    attachments: operationApplication?.attachments ?? [],
    ...operationApplicationResults,
    patchOperationApplicationMutation,
    putOperationActivityMutation,
    putOperationGoalsThisYearMutation,
    putOperationGoalsNextYearMutation,
    putOperationFocusAreasMutation,
    putOperationSocialImpactsMutation,
    deleteOperationActivityMutation,
    deleteOperationGoalThisYearMutation,
    deleteOperationGoalNextYearMutation,
    deleteOperationFocusAreaMutation,
    deleteOperationSocietalEnvironmentalImpact,
    deleteOperationFinancingSourceMutation,
    validateOperationApplicationMutation,
    ...rest,
  };
}
