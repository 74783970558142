import { FC, useEffect } from 'react';
import useConfiguration from '../../hooks/configuration/use-configuration';

const HotJarScript: FC = () => {
  const { environmentInformation } = useConfiguration();
  useEffect(() => {
    if (environmentInformation?.name === 'prod') {
      const hotJarScript = document.createElement('script');
      hotJarScript.type = 'text/javascript';
      hotJarScript.async = true;
      hotJarScript.innerHTML = `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2645278,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

      document.head.appendChild(hotJarScript);
    }
  }, [environmentInformation]);
  return null;
};

export default HotJarScript;
