import React, { lazy, useMemo } from 'react';
import { Cost, FinancingSource } from '../../../models';
import { sortArrayOnProperty } from '../../../utils/ArrayUtils';
import useChoices from '../../../hooks/choices/use-choices';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
import useMergeCostsWithDefaultCosts from '../../../hooks/use-merge-costs-with-default-costs';
import { combineAndConvertToFinancingSourceCustomList } from '@src/utils/FinancingSourcesUtils';
const CustomPageLoader = lazy(() => import('src/components/LoaderContainer/CustomPageLoader'));
const DevelopmentBudgetForm = lazy(() => import('./DevelopmentBudgetForm'));
export interface FinancingSourceCustom extends FinancingSource {
  sortOrder: number;
  deletable: boolean;
}
export type BudgetFormModel = {
  costs: Cost[];
  financingSources: FinancingSourceCustom[];
  developmentFinancingComments: string;
};
const DevelopmentBudget: React.FC = () => {
  const { applicationId } = useUrlApplicationId();

  const { developmentApplication, applicationFinancingSources, costs } =
    useDevelopmentApplication(applicationId);

  const { financingSources, financingSourcesIsFetched, expenseTypesIsFetched } = useChoices(
    developmentApplication?.type,
  );

  const { mergedCosts } = useMergeCostsWithDefaultCosts(developmentApplication?.type, costs);

  const mappedFinancingSources = useMemo(() => {
    const resultArray = combineAndConvertToFinancingSourceCustomList(
      applicationFinancingSources,
      financingSources,
    );

    if (resultArray) {
      sortArrayOnProperty(resultArray, 'sortOrder');
      return resultArray;
    }
    return [];
  }, [applicationFinancingSources, financingSources]);

  return (
    <div>
      {financingSourcesIsFetched && expenseTypesIsFetched ? (
        <DevelopmentBudgetForm mappedFinancingSources={mappedFinancingSources} costs={mergedCosts} />
      ) : (
        <CustomPageLoader />
      )}
    </div>
  );
};

export default DevelopmentBudget;
