import { ChoicesClient } from '@src/models';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  APPLICATION_TYPES,
  EXPENSE_TYPES,
  FINANCING_SOURCES,
  INNOVATION_LEVELS,
  SOCIETAL_EFFECTS,
} from '@src/constants/hook-keys';
import useUser from '../user/use-user';

export default function useChoices(applicationType?: string) {
  const [choicesService] = useState(new ChoicesClient());
  const { user } = useUser();
  const { data: clusterManagementContactRoles } = useQuery(
    ['clusterManagementContactRoles'], //
    () => choicesService.getClusterManagementContactRoles(),
    {
      enabled: false,
    },
  );
  const { data: societalEffectsResponse } = useQuery(
    [SOCIETAL_EFFECTS],
    () => choicesService.getSocietalEffects(),
    {
      enabled: !!user,
    },
  );

  const { data: applicationTypesResponse } = useQuery(
    [APPLICATION_TYPES],
    () => choicesService.getApplicationTypes(),
    {
      enabled: !!user,
    },
  );
  const { data: innovationLevelsResponse } = useQuery(
    [INNOVATION_LEVELS],
    () => choicesService.getInnovationLevels(),
    {
      enabled: !!user,
    },
  );
  const { data: financingSourcesResponse, isFetched: financingSourcesIsFetched } = useQuery(
    [FINANCING_SOURCES],
    () => choicesService.getFinancingSources(),
    {
      enabled: !!user,
    },
  );
  const {
    data: expenseTypesResponse,
    isFetching: isFetchingExpenseTypes,
    isFetched: expenseTypesIsFetched,
  } = useQuery(
    [EXPENSE_TYPES, applicationType],
    () => choicesService.getClusterExpenseTypesByApplicationType(applicationType),
    { enabled: !!applicationType },
  );

  return {
    clusterManagementContactRoles,
    societalEffects: societalEffectsResponse ?? [],
    applicationTypes: applicationTypesResponse ?? [],
    innovationLevels: innovationLevelsResponse ?? [],
    financingSources: financingSourcesResponse ?? [],
    expenseTypes: expenseTypesResponse ?? [],
    isFetchingExpenseTypes,
    expenseTypesIsFetched,
    financingSourcesIsFetched,
  };
}
