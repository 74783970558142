import React, { FC, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { Card, FaIcons, IconButton, ShadowHeight } from '@in/component-library';
import { formatAsCurrencyString } from '@src/modules/utils/method-modules/FormatValue';
import css from './ApplicationCard.module.scss';
import { LabelValueDto, ProjectApplicationTypeEnum } from '@src/models';
import cn from 'classnames';
import { ContextMenuTriggerButton } from '../ContextMenu/ContextMenuTriggerButton';
import { ContextMenuItem } from '../ContextMenu/ContextMenu';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import useChoices from '@hooks/choices/use-choices';
import generateGuid from '@src/utils/Guid';

export interface ApplicationCardProps {
  id?: string;
  title: string;
  name?: string;
  linkTo?: string;
  type?: ProjectApplicationTypeEnum;
  appliedForFundsValue?: number;
  onSelectChange?: (checked: boolean) => void;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  onDownload?: (id: string) => void;
  onClick?: () => void;
  selected?: boolean;
  disableSelect?: boolean;
  showCheckBox?: boolean;
  contextMenu?: ContextMenuItem[];
  tag?: ReactNode;
  isLoading?: boolean;
}

const ApplicationCard: FC<ApplicationCardProps> = ({
  title,
  name,
  id,
  type,
  appliedForFundsValue,
  onSelectChange,
  onEdit,
  onDelete,
  selected,
  disableSelect,
  showCheckBox,
  onDownload,
  contextMenu,
  tag,
}: PropsWithChildren<ApplicationCardProps>) => {
  const { applicationTypes } = useChoices();
  const [selectedInternal, setSelectedInternal] = useState<boolean>(false);

  const showCheckBoxInternal = useMemo(() => {
    return showCheckBox;
  }, [showCheckBox]);

  const typeName = useMemo(() => {
    if (type != null) {
      const typeLabel = applicationTypes.find((item: LabelValueDto) => {
        return item.value === type.toString();
      })?.label;

      if (typeLabel != null) {
        return typeLabel;
      }
    }
    return '';
  }, [type, applicationTypes]);

  useEffect(() => {
    if (selected != null) {
      setSelectedInternal(selected);
    }
  }, [selected]);

  const CardTextContent = () => {
    return (
      <>
        {name != null && <h4 className={css['application-card__name']}>{name}</h4>}

        <p className={css['application-card__applied-for-funds']}>{`Tilskudd fra Innovasjon Norge: ${
          appliedForFundsValue != null
            ? formatAsCurrencyString(appliedForFundsValue, ' ', '', 0, null, ' kr')
            : ''
        }`}</p>
      </>
    );
  };

  return (
    <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white" className={cn(css['application-card'])}>
      <div className={css['application-card__check-box-container']}>
        {(onSelectChange || showCheckBoxInternal) && (
          <CustomCheckbox
            aria-label={`Velg ${title} ${name != null && name != '' ? `: ${name}` : ''} ${
              typeName != null && typeName != '' ? ` - ${typeName}` : ''
            } `}
            id={id ? id : generateGuid()}
            checked={selectedInternal}
            disabled={disableSelect}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { checked } = e.currentTarget;
              setSelectedInternal(checked);
              onSelectChange?.(checked);
            }}
          />
        )}
      </div>
      <div className={css['application-card__content-container']}>
        <div className={css['application-card__header']}>
          {tag && tag}
          <h3 className={css['application-card__title']}>
            {title}
            {typeName != '' && <span className={css['application-card__type']}>{typeName}</span>}
          </h3>
        </div>

        <CardTextContent />
      </div>
      <div className={css['application-card__action-container']}>
        <IconButton
          iconName={FaIcons.EditLight}
          iconSize={'xl'}
          ariaLabel={`Gå til ${name ?? ''} `}
          title={`Gå til ${name ?? ''} `}
          onClick={() => {
            onEdit?.();
          }}
        />

        {onDownload && (
          <IconButton
            title={`Last ned ${name ?? ''} som pdf`}
            iconName={FaIcons.FilePdfLight}
            iconSize={'xl'}
            onClick={() => {
              if (id && onDownload) {
                onDownload(id);
              }
            }}
          />
        )}

        {onDelete && (
          <IconButton
            iconName={FaIcons.TrashAltLight}
            title={`Slett ${name != null && name != '' ? `: ${name}` : ''}`}
            iconSize={'xl'}
            ariaLabel={`Slett ${name}`}
            onClick={() => {
              if (id) {
                onDelete(id);
              }
            }}
          />
        )}
      </div>
      {contextMenu != null && <ContextMenuTriggerButton contextMenu={contextMenu} />}
    </Card>
  );
};
export default ApplicationCard;
