import React, { FC, lazy, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonVariant,
  FaIcon,
  FaIcons,
  FileItem,
  IconPosition,
  Section,
  Theme,
} from '@in/component-library';
import { useNavigate } from 'react-router-dom';
import { confirmRouteConfigObject } from '../../routeConfigs/site/ConfirmRouteConfig';
import cn from 'classnames';
import css from './Confirm.module.scss';
import ConfirmCostsAndAttachments from './ConfirmCostsAndAttachments';
import { ClusterStatusEnum, DevelopmentApplicationOverviewDto, FileResponse } from '../../models';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useDocTitle } from '../../hooks/CustomHooks';
import {
  clearSelectedApplicationIds,
  clearSelectedApplicationsForSubmit,
} from '../../store/confirm/confirmSlice';
import { baseSiteRoutePaths } from '../../routeConfigs/site/SiteRouteConfig';
import { getFilesFromAttachmentsByInputFieldName, saveFileOnUserDevice } from '../../utils/AttachmentHelper';
import usePdf from '../../hooks/pdf/use-pdf';
import ThemeSection from '../../components/ThemeSection/ThemeSection';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
import LegalOwnerNotification from '@src/components/LegalOwnerNotification/LegalOwnerNotification';
const Page = lazy(() => import('src/components/Page/Page'));

const Confirm: FC = () => {
  useDocTitle('Oppsummering Finansieringsøknad ');
  const operatingBudgetFileUploadFieldName = `operatingBudgetFileUpload`;
  const annualAccountsFileUploadFieldName = `annualAccountsFileUpload`;

  const applicationDispatch = useAppDispatch();
  const { getApplicationsAsPdf } = usePdf();
  const { operationsApplication, clusterStatus, attachments, submitMainApplicationMutation } =
    useMainApplication();
  const navigate = useNavigate();
  const selectedApplicationsForSubmit: DevelopmentApplicationOverviewDto[] | undefined = useAppSelector(
    (state) => state?.confirm.selectedApplicationsForSubmit,
  );

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [submitStarted, setSubmitStarted] = useState<boolean>(false);

  const headingText = useMemo(() => {
    return clusterStatus === ClusterStatusEnum.ModneKlynger
      ? 'Finansieringssøknad Modne klynger'
      : 'Finansieringssøknad neste år';
  }, [clusterStatus]);

  useEffect(() => {
    if (selectedApplicationsForSubmit?.length === 0 && !operationsApplication?.readyToSubmit) {
      navigate(baseSiteRoutePaths.applicationsOverviewPath);
    }
  }, [selectedApplicationsForSubmit, navigate, operationsApplication?.readyToSubmit]);

  const operatingBudgetAttachments = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, operatingBudgetFileUploadFieldName);
  }, [attachments, operatingBudgetFileUploadFieldName]);

  const annualAccountsAttachments = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, annualAccountsFileUploadFieldName);
  }, [attachments, annualAccountsFileUploadFieldName]);

  useEffect(() => {
    if (selectedApplicationsForSubmit != null && attachments != null) {
      if (
        (selectedApplicationsForSubmit?.length > 0 || operationsApplication?.readyToSubmit) &&
        operatingBudgetAttachments.length > 0 &&
        annualAccountsAttachments.length > 0
      ) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [
    attachments,
    operatingBudgetAttachments,
    annualAccountsAttachments,
    selectedApplicationsForSubmit,
    operationsApplication?.readyToSubmit,
  ]);

  return (
    <Page wrapWithBody={true} paddingTop={true}>
      <Section className={css['confirm']}>
        <h1>{headingText}</h1>

        <div className={cn(css['confirm__content'], css['confirm__content--stand-alone'])}>
          <ConfirmCostsAndAttachments />

          <ThemeSection marginBottom={true}>
            <Button
              variant={ButtonVariant.Link}
              className={'link-black'}
              iconName={FaIcons.FileDownload}
              iconPosition={IconPosition.Left}
              onClick={async (e) => {
                e.preventDefault();
                const applicationIds: string[] = [];
                if (selectedApplicationsForSubmit != null && selectedApplicationsForSubmit.length > 0) {
                  selectedApplicationsForSubmit.forEach((item) => {
                    if (item.id != null) {
                      applicationIds.push(item.id);
                    } 
                  });
                }

                if (
                  operationsApplication != null &&
                  operationsApplication?.readyToSubmit &&
                  operationsApplication.id != null
                ) {
                  applicationIds.push(operationsApplication.id);
                }
                const response: FileResponse | null = await getApplicationsAsPdf.mutateAsync({
                  applicationIds: applicationIds,
                });

                if (response != null && response?.data != null) {
                  saveFileOnUserDevice(response.data, response.fileName);
                }
              }}
            >
              Forhåndsvisning av søknad (PDF)
              {getApplicationsAsPdf.isLoading && <FaIcon name={FaIcons.Spinner} size="sm" pulse={true} />}
            </Button>
          </ThemeSection>

          <ThemeSection marginBottom={true}>
            <LegalOwnerNotification />
          </ThemeSection>

          <div className="button-container">
            <Button
              isLoading={submitStarted}
              theme={Theme.Neutral}
              variant={ButtonVariant.Solid}
              disabled={disableSubmit || submitStarted}
              onClick={() => {
                const applicationIds: string[] = [];
                if (selectedApplicationsForSubmit != null && selectedApplicationsForSubmit.length > 0) {
                  selectedApplicationsForSubmit.forEach((item) => {
                    if (item.id != null) {
                      applicationIds.push(item.id);
                    } 

                  });
                }

                if (
                  operationsApplication != null &&
                  operationsApplication?.readyToSubmit &&
                  operationsApplication.id != null
                ) {
                  applicationIds.push(operationsApplication.id);
                }

                if (applicationIds.length > 0) {
                  setSubmitStarted(true);
                  submitMainApplicationMutation.mutateAsync(applicationIds).then(() => {
                    setSubmitStarted(false);
                    navigate(confirmRouteConfigObject.success.path);
                    //TODO: finn løsning på disse dispatches
                    applicationDispatch(clearSelectedApplicationIds());
                    applicationDispatch(clearSelectedApplicationsForSubmit());
                  });
                }
              }}
            >
              {`Send inn finansieringssøknad (${
                (selectedApplicationsForSubmit?.length != null ? selectedApplicationsForSubmit?.length : 0) +
                (operationsApplication != null && operationsApplication.readyToSubmit === true ? 1 : 0)
              })`}
            </Button>
            <Button
              theme={Theme.Neutral}
              variant={ButtonVariant.Outlined}
              onClick={() => {
                navigate(baseSiteRoutePaths.applicationsOverviewPath);
              }}
            >
              Avbryt
            </Button>
          </div>
        </div>
      </Section>
    </Page>
  );
};

export default Confirm;
