import { useState } from 'react';
import { ClustersClient } from '@src/models';
import { useQuery } from '@tanstack/react-query';
import { CLUSTER_FINANCIAL_FRAME, CLUSTER_FOCUS_AREAS, CLUSTER_MAIN_GOALS } from '@src/constants/hook-keys';
import useUser from '../user/use-user';
import { toast } from 'react-toastify';

export default function useCluster() {
  const { user, chosenClusterId } = useUser();
  const [clustersClient] = useState(new ClustersClient());

  const { data: clusterFocusAreasResults, ...rest } = useQuery(
    [CLUSTER_FOCUS_AREAS],
    () => clustersClient.getFocusAreas(),
    {
      enabled: !!user && !!chosenClusterId,
      onError() {
        toast.error('Klarte ikke hente fokusområder', { toastId: CLUSTER_FOCUS_AREAS });
      },
    },
  );

  const { data: clusterMainGoalsResults } = useQuery(
    [CLUSTER_MAIN_GOALS],
    () => clustersClient.getMainGoals(),
    {
      enabled: !!user && !!chosenClusterId,
      onError() {
        toast.error('Klarte ikke hente hovedmål', { toastId: CLUSTER_MAIN_GOALS });
      },
    },
  );

  const { data: clusterFinancialFrameResults, isLoading: isLoadingClusterFinancialFrame } = useQuery(
    [CLUSTER_FINANCIAL_FRAME],
    () => clustersClient.getMyClusterFinancialFrame(),
    {
      enabled: !!user && !!chosenClusterId,
      onError() {
        toast.error('Klarte ikke hente finansieringsramme', { toastId: CLUSTER_FINANCIAL_FRAME });
      },
    },
  );

  return {
    clusterFocusAreas: clusterFocusAreasResults ?? [],
    clusterMainGoals: clusterMainGoalsResults ?? [],
    clusterFinancialFrame: clusterFinancialFrameResults,

    isLoadingClusterFinancialFrame,
    ...rest,
  };
}
