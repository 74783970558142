import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Attachment, Cost, FinancingSource, PlannedActivity, ProjectApplicationTypeEnum } from '../models';

import { FileItem } from '@in/component-library';
import {
  developmentApplicationRouteObjects,
  developmentApplicationRoutePaths,
} from '../routeConfigs/application/DevelopmentApplicationRouteConfig';
import {
  operationApplicationRouteObjects,
  operationApplicationRoutePaths,
} from '../routeConfigs/application/OperationApplicationRouteConfig';
import { SummaryCheckListParent } from '../Application/operationApplication/CompleteForm/SummaryCheckList';
import { getFilesFromAttachmentsByInputFieldName } from '../utils/AttachmentHelper';
import { CustomRouteObject } from '../routeConfigs/types';

type DocTitleProps = {
  updateDocTitle: (title: string) => void;
  docTitle: string;
};

type ClickOutsideProps<T extends HTMLElement> = {
  containerRef: MutableRefObject<T | null>;
  clickOutside: boolean;
  setClickOutside: (value: boolean) => void;
};

export type PropertiesOfType<TType, TFilterType> = {
  [Property in keyof TType as NonNullable<TType[Property]> extends TFilterType
    ? Property
    : never]: TType[Property];
};
export type PropertyKeysOfType<TType, TFilterType> = keyof PropertiesOfType<TType, TFilterType>;

export function sumPropertiesOnType<TType extends object>(
  source: TType[],
  sumProperties: PropertyKeysOfType<TType, number>[],
): number {
  return source.reduce((result: number, object: TType) => {
    sumProperties.forEach((property: PropertyKeysOfType<TType, number>) => {
      if (object[property as string] != null) {
        result += object[property as string];
      }
    });
    return result;
  }, 0);
}

function useDocTitle(initialTitle: string): DocTitleProps {
  const [docTitle, setDocTitle] = useState<string>(initialTitle);

  const updateDocTitle = useCallback((value) => setDocTitle(value), []);
  return { docTitle, updateDocTitle };
}

function useClickOutside<TContainer extends HTMLElement>(initialIsVisible): ClickOutsideProps<TContainer> {
  const [clickOutside, setClickOutside] = useState(initialIsVisible);
  const containerRef = useRef<TContainer>(null);

  const handleClickOutside = (event) => {
    if (
      containerRef != null &&
      containerRef.current != null &&
      !containerRef.current.contains(event.target)
    ) {
      setClickOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { containerRef, clickOutside, setClickOutside };
}

export function getTotalCostsWithoutDuplicates(
  activities: PlannedActivity[],
  developmentApplicationType?: ProjectApplicationTypeEnum,
) {
  const skipDuplicateFilter: boolean =
    developmentApplicationType != null &&
    (developmentApplicationType === ProjectApplicationTypeEnum.EducationalOperations ||
      developmentApplicationType === ProjectApplicationTypeEnum.Announcement);
  const allCosts: Cost[] = activities.reduce<Cost[]>((costs, current) => {
    if (current.costs != null) {
      return costs.concat(current.costs);
    }

    return costs;
  }, []);

  const allCostDeepCopy: Cost[] = JSON.parse(JSON.stringify(allCosts));
  let allCostsNoDuplicates: Cost[];

  if (!skipDuplicateFilter) {
    allCostsNoDuplicates = allCostDeepCopy.reduce<Cost[]>((costs, current) => {
      const index = costs.findIndex((item) => item.type === current.type);
      const currentParticipantsAmount = current?.participantsAmount != null ? current?.participantsAmount : 0;
      const currentApplicantAmount = current?.applicantAmount != null ? current?.applicantAmount : 0;

      if (index !== -1) {
        let applicantAmount = 0;
        let participantsAmount = 0;

        if (costs[index] != null) {
          if (current?.applicantAmount != null) {
            applicantAmount = (costs[index].applicantAmount as number) + currentApplicantAmount;
          }

          if (current?.participantsAmount != null) {
            participantsAmount = (costs[index].participantsAmount as number) + currentParticipantsAmount;
          }

          costs[index].applicantAmount = applicantAmount;
          costs[index].participantsAmount = participantsAmount;
        }
        return costs;
      } else {
        costs.push({
          id: current.id,
          type: current.type,
          name: current.name,
          applicantAmount: current.applicantAmount,
          participantsAmount: current.participantsAmount,
        });
        return costs;
      }
    }, []);
  } else {
    allCostsNoDuplicates = allCostDeepCopy;
  }

  const totalCostsAmount = sumPropertiesOnType(allCostsNoDuplicates, [
    'applicantAmount',
    'participantsAmount',
  ]);

  const applicantAmount = sumPropertiesOnType(allCostsNoDuplicates, ['applicantAmount']);

  const participantsAmount = sumPropertiesOnType(allCostsNoDuplicates, ['participantsAmount']);

  return { allCostsNoDuplicates, totalCostsAmount, applicantAmount, participantsAmount };
}
const useTotalCostsWithoutDuplicates = (
  activities: PlannedActivity[],
  developmentApplicationType?: ProjectApplicationTypeEnum,
) => {
  return getTotalCostsWithoutDuplicates(activities, developmentApplicationType);
};

const useSumFinancingSources = (financingSources: Array<FinancingSource>) => {
  const financingSourcesTotal = financingSources.reduce(
    (sum, current) => sum + (current?.amount ? current.amount : 0),
    0,
  );

  return { financingSourcesTotal };
};

const useApplicationRoutes = (pathname: string) => {
  let applicationRoutes: CustomRouteObject[] = [];

  if (pathname.startsWith(developmentApplicationRoutePaths.baseApplicationPath)) {
    applicationRoutes = developmentApplicationRouteObjects;
  } else if (pathname.startsWith(operationApplicationRoutePaths.baseOperationApplicationRoute)) {
    applicationRoutes = operationApplicationRouteObjects;
  }

  return { applicationRoutes };
};

const useSummaryList = (pathname: string, attachments: Attachment[] | undefined) => {
  const summaryList: SummaryCheckListParent[] = useMemo(() => {
    let applicationRoutes: Array<CustomRouteObject> = [];

    if (pathname.startsWith(developmentApplicationRoutePaths.baseApplicationPath)) {
      applicationRoutes = developmentApplicationRouteObjects[0].children;
      // TODO: Se om vi skal løse dette på en annen måte developmentApplicationRouteObjects[0] index 0...
    } else if (pathname.startsWith(operationApplicationRoutePaths.baseOperationApplicationRoute)) {
      // TODO: Se om vi skal løse dette på en annen måte operationApplicationRouteObjects[0] index 0...
      applicationRoutes = operationApplicationRouteObjects[0].children;
    }

    return applicationRoutes
      .filter((item: CustomRouteObject) => {
        return (
          item.path !== operationApplicationRoutePaths.completeFormPath &&
          item.path !== developmentApplicationRoutePaths.summaryParentPath
        );
      })
      .map((item: CustomRouteObject) => {
        const children =
          item.children != null
            ? item.children.map((themePage: CustomRouteObject) => {
                let documentList: Array<FileItem> = [];

                if (themePage.attachmentField != null && attachments != null) {
                  documentList = getFilesFromAttachmentsByInputFieldName(
                    attachments,
                    themePage.attachmentField,
                  );
                }

                return {
                  name: themePage.name,
                  id: themePage.path,
                  documents: documentList.reduce<string[]>((previousValue: string[], currentValue) => {
                    if (currentValue.fileName != null) {
                      previousValue.push(currentValue.fileName);
                    }
                    return previousValue;
                  }, []),
                };
              })
            : [];

        return {
          name: item.name,
          id: item.path,
          children: children,
        };
      });
  }, [pathname, attachments]);

  return { summaryList };
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function useWhyDidYouUpdate<Ttype>(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Ttype>();
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps?.current != null && previousProps?.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export {
  // useLocalizer,
  useTotalCostsWithoutDuplicates,
  useSumFinancingSources,
  useApplicationRoutes,
  useSummaryList,
  useWhyDidYouUpdate,
  useDocTitle,
  useClickOutside,
  useWindowSize,
};
