import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory({ basename: '' });
const browserHistory = createBrowserHistory();

export const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    extensions: [reactPlugin],
    disableAjaxTracking: true,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

export const applicationInsights = ai;

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
