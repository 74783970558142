//use-auth
export const AUTH_KEY = 'auth';

//use-development-application
export const DEVELOPMENT_APPLICATION = 'developmentApplication';

//use-operation-application
export const OPERATION_APPLICATION = 'operationApplication';

//use-contacts
export const ACTIVE_CLUSTER_MEMBERS = 'activeClusterMembers';

//use-cluster
export const CLUSTER_FOCUS_AREAS = 'clusterFocusAreas';
export const CLUSTER_MAIN_GOALS = 'clusterMainGoals';
export const CLUSTER_FINANCIAL_FRAME = 'clusterFinancialFrame';
export const CLUSTERS = 'clusters';

//use-my-cluster
export const CLUSTER = 'cluster';

//use-choices
export const SOCIETAL_EFFECTS = 'societalEffects';
export const APPLICATION_TYPES = 'applicationTypes';
export const INNOVATION_LEVELS = 'innovationLevels';
export const FINANCING_SOURCES = 'financingSources';
export const EXPENSE_TYPES = 'expenseTypes';

//use-user
export const USER_KEY = 'user';
export const USER_STATUS_LOGGED_IN = 'user-status-logged-in';
export const USER_STATUS_LOADING = 'user-status-loading';
export const USER_STATUS_REGISTERED = 'user-status-registered';
export const USER_STATUS_HAS_CLUSTERS = 'user-status-has-clusters';
export const USER_STATUS_HAS_LOGIN_ERROR_4XX = 'user-status-has-login-error-4XX';
export const USER_STATUS_HAS_LOGIN_ERROR_5XX = 'user-status-has-login-error-5XX';
export const USER_SESSION_HAS_EXPIRED = 'user-session-has-exipred';

//use-configuration
export const ENVIRONMENT_INFORMATION_KEY = 'environment-information';
export const APP_INSIGHT_LOADED = 'appInsightLoaded';

//use-main-application
export const CREATE_APPLICATION_SETUP = 'createApplicationSetup';
export const APPLICATION_CREATION_INFORMATION = 'applicationCreationInformation';
export const OPERATION_APPLICATION_IS_CREATED = 'operationApplicationIsCreated';
export const COMPLETE_APPLICATION_ID = 'completeApplicationId';

//use-archived-applications
export const GET_ARCHIVED_APPLICATIONS = 'getArchivedApplications';
//use-archived-applications
export const USE_APPLICATIONS_EXPENSE_OVERVIEW = 'useApplicationsExpenseOverview';

//use-app-local-state
export const APP_IS_SAVING_DATA = 'appIsSavingData';
export const SELECTED_APPLICATION_IDS_FOR_SUBMIT = 'selectedApplicationIdsForSubmit';
