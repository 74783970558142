import React, { FC, useMemo } from 'react';
import { Button, ButtonVariant, Theme } from '@in/component-library';
import { SubmitSuccess } from '../Confirm/SubmitSuccess';
import { useNavigate } from 'react-router-dom';

import { LandingPageTabs } from './ApplicationLandingPage';
import { ClusterStatusEnum } from '../../models';
import { useDocTitle } from '../../hooks/CustomHooks';
import { baseSiteRoutePaths } from '../../routeConfigs/site/SiteRouteConfig';
import useMainApplication from '../../hooks/mainApplication/use-main-application';

const SubmitReceiptPage: FC = () => {
  useDocTitle('Søknad er sendt inn');
  const navigate = useNavigate();
  const { clusterStatus } = useMainApplication();
  const ingress = useMemo(() => {
    if (clusterStatus === ClusterStatusEnum.ModneKlynger) {
      return 'Gratulerer, finansieringssøknaden til delprogrammet Modne klynger er sendt inn.';
    }
    return 'Gratulerer, deres årlige finansieringssøknad er sendt inn!';
  }, [clusterStatus]);

  return (
    <SubmitSuccess confetti={true} ingress={ingress}>
      {clusterStatus != null && clusterStatus === ClusterStatusEnum.Arena && (
        <p>{`Søknaden blir nå behandlet av en saksbehandler. 
Aktivitetene vil bli synlige i Flerbedriftsportalen ved årsskiftet. `}</p>
      )}
      {clusterStatus != null && clusterStatus !== ClusterStatusEnum.Arena && (
        <p>{`Søknadene blir nå behandlet av en saksbehandler. 
Prosjektene vil bli synlige i Flerbedriftsportalen ved årsskiftet. Dersom dere har mer tilgjengelige midler igjen på rammen deres, kan dere når som helst opprette nye utviklingsprosjektsøknader. `}</p>
      )}

      <Button
        theme={Theme.Neutral}
        variant={ButtonVariant.Outlined}
        onClick={() => {
          navigate(baseSiteRoutePaths.applicationsOverviewPath, {
            state: { activeLandingPageTab: LandingPageTabs.ARCHIVED_APPLICATIONS },
          });
        }}
      >
        Til søknadsoversikt
      </Button>
    </SubmitSuccess>
  );
};

export default SubmitReceiptPage;
