import React, { FC, PropsWithChildren } from 'react';

import { MessageType, Notification } from '@in/component-library';
import { OperationPlannedActivity } from '../../models';
import TextCollapse from '../TextCollapse/TextCollapse';

export interface RemoveFocusAreasAndGoalsNotificationItem {
  id?: string;
  name?: string;
}
export interface RemoveFocusAreasAndGoalsNotificationProps {
  items: RemoveFocusAreasAndGoalsNotificationItem[];
  plannedActivities: OperationPlannedActivity[];
  description: string;
  searchForGoals?: boolean;
}
const RemoveFocusAreasAndGoalsNotification: FC<RemoveFocusAreasAndGoalsNotificationProps> = ({
  items,
  plannedActivities,
  description,
  searchForGoals = false,
}: PropsWithChildren<RemoveFocusAreasAndGoalsNotificationProps>) => {
  const matches = items.map((focusArea: RemoveFocusAreasAndGoalsNotificationItem) => {
    plannedActivities.filter((activity) => {
      if (searchForGoals) {
        return activity.mainGoalId === focusArea.id;
      } else {
        activity?.focusAreaIds?.find((id) => {
          return id === focusArea.id;
        });
      }
    });
  });
  return (
    <>
      {matches.length > 0 ? (
        <Notification type={MessageType.Info} fullWidth={true}>
          {description != null && <p>{description}</p>}
          {items.map((focusArea: RemoveFocusAreasAndGoalsNotificationItem) => {
            const matchingPlanedActivities = plannedActivities.filter((activity) => {
              if (searchForGoals) {
                return activity.mainGoalId === focusArea.id;
              } else {
                const match = activity?.focusAreaIds?.find((id) => {
                  return id === focusArea.id;
                });
                return match != null && match;
              }
            });
            const readMoreText =
              focusArea.name != null ? `${focusArea.name} (${matchingPlanedActivities.length})` : '';

            return (
              <div key={focusArea.id}>
                {matchingPlanedActivities.length > 0 ? (
                  <TextCollapse
                    collapseText={readMoreText}
                    ariaLabel={`Vis hvilke aktiviteter "${focusArea.name}" er brukt på`}
                  >
                    <ul>
                      {matchingPlanedActivities.map((activity) => (
                        <li key={activity.id}>{activity.name}</li>
                      ))}
                    </ul>
                  </TextCollapse>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </Notification>
      ) : (
        <></>
      )}
    </>
  );
};

export default RemoveFocusAreasAndGoalsNotification;
