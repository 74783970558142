import React, { useCallback } from 'react';
import {
  BudgetFormModel,
  FinancingSourceCustom,
} from '../../developmentApplication/Financing/DevelopmentBudget';
import OperationFinanceForm from '../../../components/BudgetTable/OperationFinanceForm';
import { Controller, useForm } from 'react-hook-form';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { TextArea } from '@in/component-library';
import ThemeSection from '../../../components/ThemeSection/ThemeSection';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';

const FinancingPlan: React.FC<{ mappedFinancingSources: FinancingSourceCustom[] }> = ({
  mappedFinancingSources,
}) => {
  const { applicationId } = useUrlApplicationId();
  const { financingComments, patchOperationApplicationMutation } = useOperationApplication(applicationId);

  const { trigger, control, getValues } = useForm<BudgetFormModel>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      developmentFinancingComments: financingComments ?? '',
      financingSources: mappedFinancingSources,
    },
  });
  function financingCommentsBlurHandler() {
    const valueChanged: boolean = financingComments !== getValues('developmentFinancingComments');
    if (getValues('developmentFinancingComments') != null && valueChanged) {
      patchOperationApplicationMutation.mutateAsync({
        financing: {
          comments: getValues('developmentFinancingComments'),
        },
      });
    }
  }
  const operationFinanceFormChangeHandler = useCallback(
    (index) => {
      const financingSourceCustom = getValues(`financingSources`)[index];
      if (financingSourceCustom) {
        patchOperationApplicationMutation
          .mutateAsync({
            financing: {
              sources: [
                {
                  type: financingSourceCustom.type,
                  name: financingSourceCustom.name,
                  amount: financingSourceCustom.amount,
                  id: financingSourceCustom.id,
                },
              ],
            },
          })
          .then(async () => {
            await trigger('financingSources');
          });
      }
    },
    [getValues, trigger, patchOperationApplicationMutation],
  );
  return (
    <div>
      <OperationFinanceForm control={control} onChange={operationFinanceFormChangeHandler} />

      <ThemeSection>
        <Controller
          control={control}
          name={`developmentFinancingComments`}
          rules={{ maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' } }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Kommentar til finansieringskilder"
              maxCount={InputMaxLength.medium}
              maxLength={InputMaxLength.medium}
              rows={3}
              onBlur={() => {
                field.onBlur();
                trigger('developmentFinancingComments').then((isValid) => {
                  if (isValid) {
                    financingCommentsBlurHandler();
                  }
                });
              }}
              errorMsg={fieldState?.error?.message?.toString()}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>
    </div>
  );
};
export default FinancingPlan;
