import React, { FC, lazy, useCallback, useEffect, useState } from 'react';
import { RHFRequiredNotEmptyMediumMaxLength } from '../../../utils/FormValidations';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { Button, ButtonVariant, FaIcons, TextArea, Theme } from '@in/component-library';
import { Controller, useForm } from 'react-hook-form';
import { BrRegMember, ClusterMember, ProjectApplicationTypeEnum } from '../../../models';
import * as solidIcons from '@fortawesome/free-regular-svg-icons';
import { useAppLocation } from '../../../hooks/use-app-location';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
const AddParticipantsModal = lazy(() => import('./AddParticipantsModal'));
const SimpleCardList = lazy(() => import('src/components/SimpleCardList/SimpleCardList'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export interface DevelopmentProjectParticipantsProps {
  title?: string;
  ingress?: string;
}
interface FormField {
  participantsDescription: string;
}

const DevelopmentProjectParticipants: FC<DevelopmentProjectParticipantsProps> = ({
  title,
  ingress,
}: DevelopmentProjectParticipantsProps) => {
  const { state } = useAppLocation();

  const { applicationId } = useUrlApplicationId();

  const {
    developmentApplication,
    patchDevelopmentApplicationMutation,
    brRegParticipants,
    participants,
    putDevelopmentParticipantsMutation,
    putDevelopmentBrRegParticipantsMutation,
  } = useDevelopmentApplication(applicationId);

  const [showAddParticipantsModal, setShowAddParticipantsModal] = useState<boolean>(false);

  const { control, trigger, getValues } = useForm<FormField>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      participantsDescription: developmentApplication?.project?.participants?.participantsDescription ?? '',
    },
  });

  const participantsLabel = useCallback(() => {
    if (developmentApplication?.type != null) {
      switch (developmentApplication.type) {
        case ProjectApplicationTypeEnum.Announcement:
        case ProjectApplicationTypeEnum.InnovationCooperation:
        case ProjectApplicationTypeEnum.InnovationCounseling:
        case ProjectApplicationTypeEnum.ConferenceParticipation:
        case ProjectApplicationTypeEnum.InternationalMarketPositionsDevelopment:
          return 'Gi en kort beskrivelse av prosjektdeltagerne og deres rolle i prosjektet.';

        case ProjectApplicationTypeEnum.EducationalOperations:
        case ProjectApplicationTypeEnum.InternationalMarketsKnowledge:
          return 'Gi en kort beskrivelse av prosjektdeltagerne og deres rolle i prosjektet. Hvem skal få opplæring, og hvem skal stå for opplæringen?';

        case ProjectApplicationTypeEnum.Investments:
          return 'Gi en kort beskrivelse av prosjektdeltagerne og deres rolle i prosjektet. Hvem er målgruppen for investeringen som skal gjennomføres? ';

        case ProjectApplicationTypeEnum.FeasibilityStudy:
          return 'Gi en kort beskrivelse av prosjektdeltagerne og deres rolle i prosjektet. Beskriv målgruppen og hvem som skal stå for utviklingen av utdanningstilbudet.';
        default:
          return 'Gi en kort beskrivelse av prosjektdeltagerne og deres rolle i prosjektet.';
      }
    }
  }, [developmentApplication?.type]);

  useEffect(() => {
    if (state != null && state.validate) {
      requestAnimationFrame(() => {
        trigger();
      });
    }
  }, [getValues, trigger, state]);

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection>
        <h2>{'Beskrivelse av prosjektdeltagerne'}</h2>
        <p>
          Prosjektet skal gjennomføres med innsats fra klyngens medlemmer. Aktiv deltagelse fra den enkelte
          bedrift behøver ikke nødvendigvis å være avklart på forhånd.
        </p>
        <Controller
          control={control}
          name={`participantsDescription`}
          rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label={participantsLabel()}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={(event) => {
                const { value } = event.target;
                field.onBlur();
                trigger('participantsDescription').then((isValid) => {
                  if (isValid && value) {
                    patchDevelopmentApplicationMutation.mutateAsync({
                      project: {
                        participants: { participantsDescription: value },
                      },
                    });
                  }
                });
              }}
              maxCount={InputMaxLength.large}
              maxLength={InputMaxLength.large}
              rows={3}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>

      <ThemeSection>
        <h2>Prosjektdeltagere</h2>
      </ThemeSection>

      <SimpleCardList
        cards={participants
          .filter((item: ClusterMember) => item.id != null)
          .map((item: ClusterMember) => {
            return {
              id: item.id,
              name: item?.name,
              iconName: solidIcons.faBuilding,
            };
          })}
        onDelete={(id: string) => {
          const index = participants.findIndex((participant: ClusterMember) => {
            return participant.id === id;
          });

          if (index !== -1) {
            putDevelopmentParticipantsMutation.mutateAsync(
              participants
                .filter((participant: ClusterMember) => participant.id !== id)
                .map((item: ClusterMember) => {
                  return {
                    id: item.id,
                    name: item?.name ? item.name.toString() : '',
                  };
                }),
            );
          }
        }}
      />
      <SimpleCardList
        onDelete={(id: string) => {
          const index = brRegParticipants.findIndex((participant) => {
            return participant.id === id;
          });

          if (index !== -1) {
            putDevelopmentBrRegParticipantsMutation.mutateAsync(
              brRegParticipants
                .filter((bregParticipant: BrRegMember) => bregParticipant.id !== id)
                .map((item: BrRegMember) => {
                  return {
                    id: item.id,
                    name: item?.name ? item.name : '',
                  };
                }),
            );
          }
        }}
        cards={brRegParticipants
          .filter((item: BrRegMember) => item.id != null)
          .map((item: BrRegMember) => {
            return {
              id: item.id,
              name: item?.name,
              iconName: solidIcons.faBuilding,
            };
          })}
      />

      <ThemeSection>
        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Link}
          iconName={FaIcons.PlusLight}
          onClick={() => {
            setShowAddParticipantsModal(true);
          }}
        >
          Legg til prosjektdeltagere
        </Button>

        <AddParticipantsModal
          participants={participants}
          brRegParticipants={brRegParticipants}
          show={showAddParticipantsModal}
          onClose={() => {
            setShowAddParticipantsModal(false);
          }}
        />
      </ThemeSection>
    </div>
  );
};
export default DevelopmentProjectParticipants;
