import {
  Cost,
  FinancingSource,
  OperationPlannedActivity,
  PlannedActivity,
  ProjectApplicationTypeEnum,
  SocialImpact,
} from '../../models';
import { getTotalCostsWithoutDuplicates, sumPropertiesOnType } from '../../hooks/CustomHooks';
import { grantsFromInnovationNorwayCode, otherPublicFundingCode } from '../../constants/constants';

export function test(numberOfMissingFields: number, totalNumberOfFields: number): number {
  if (totalNumberOfFields != null && totalNumberOfFields !== 0) {
    if (numberOfMissingFields === 0) {
      return numberOfMissingFields;
    } else if (totalNumberOfFields === numberOfMissingFields) {
      return -1;
    } else if (totalNumberOfFields > numberOfMissingFields) {
      return numberOfMissingFields;
    } else {
      return -1;
    }
  } else {
    return -1;
  }
}
export function getNumberOfMissingFields(items: boolean[], totalNumberOfFields?: number): number {
  let missingFields = items.filter((isValid) => !isValid).length;
  if (totalNumberOfFields != null) {
    missingFields = test(missingFields, totalNumberOfFields);
  }
  return missingFields;
}

export function societalEffectsPageIsValid(
  notApplicable?: boolean,
  notApplicableComment?: string,
  impacts?: SocialImpact[] | undefined,
): boolean[] {
  if (notApplicable === null) {
    return [false];
  }
  if (!notApplicable) {
    return [impacts != null ? impacts?.length > 0 : false];
  }
  return [(notApplicable && notApplicableComment !== '' && notApplicableComment != null) || !notApplicable];
}

export function financingSourcesIsValid(
  plannedActivities: OperationPlannedActivity[] | PlannedActivity[],
  financingSources: FinancingSource[],
): boolean[] {
  const { totalCostsAmount } = getTotalCostsWithoutDuplicates(plannedActivities);
  const financingSourcesTotal = financingSources.reduce(
    (a, b) => a + (b?.amount != null && !isNaN(b?.amount) ? b.amount : 0),
    0,
  );

  const otherFinancingTypes = financingSources
    .filter((item) => item.type === undefined && item.name === '')
    .map(() => {
      return false;
    });

  const grantsFromInnovationNorway = financingSources.find((financingSource) => {
    return financingSource.type === grantsFromInnovationNorwayCode;
  });
  const otherPublicFunding = financingSources.find((financingSource) => {
    return financingSource.type === otherPublicFundingCode;
  });

  let grantsFromInnovationNorwayAndOtherPublicFunding = 0;

  if (grantsFromInnovationNorway?.amount != null) {
    grantsFromInnovationNorwayAndOtherPublicFunding += grantsFromInnovationNorway.amount;
  }
  if (otherPublicFunding?.amount != null) {
    grantsFromInnovationNorwayAndOtherPublicFunding += otherPublicFunding.amount;
  }
  const grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts =
    grantsFromInnovationNorwayAndOtherPublicFunding > 0 &&
    grantsFromInnovationNorwayAndOtherPublicFunding <= totalCostsAmount / 2;

  if (totalCostsAmount === 0 && financingSourcesTotal === 0) {
    return [
      grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts, // Tilskudd must have value
      financingSourcesTotal > 0,
      false,
    ];
  } else {
    return [
      grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts, // Tilskudd must have value
      financingSourcesTotal > 0,
      totalCostsAmount === financingSourcesTotal, //Totale kostnader må være lik summen av finansieringen,
      ...otherFinancingTypes,
    ];
  }
}

export function developmentApplicationFinancingSourcesIsValid(
  costs: Cost[],
  financingSources: FinancingSource[],
  developmentApplicationType,
): boolean[] {
  const totalCostsAmount = sumPropertiesOnType(costs, ['applicantAmount', 'participantsAmount']);
  const financingSourcesTotal = financingSources.reduce(
    (a, b) => a + (b?.amount != null && !isNaN(b?.amount) ? b.amount : 0),
    0,
  );
  const otherFinancingTypes = financingSources
    .filter((item) => item.type == null && item.name === '')
    .map(() => {
      return false;
    });
  const grantsFromInnovationNorway = financingSources.find((financingSource) => {
    return financingSource.type === grantsFromInnovationNorwayCode;
  });
  const otherPublicFunding = financingSources.find((financingSource) => {
    return financingSource.type === otherPublicFundingCode;
  });

  let grantsFromInnovationNorwayAndOtherPublicFunding = 0;

  if (grantsFromInnovationNorway?.amount != null) {
    grantsFromInnovationNorwayAndOtherPublicFunding += grantsFromInnovationNorway.amount;
  }
  if (otherPublicFunding?.amount != null) {
    grantsFromInnovationNorwayAndOtherPublicFunding += otherPublicFunding.amount;
  }
  let supportRate = 0.5;
  if (developmentApplicationType === ProjectApplicationTypeEnum.Announcement) {
    supportRate = 0.7;
  }
  const grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts =
    grantsFromInnovationNorwayAndOtherPublicFunding > 0 &&
    grantsFromInnovationNorwayAndOtherPublicFunding <= totalCostsAmount * supportRate;
  const costsWithMissingName = costs
    .filter((item) => item.name === '')
    .map(() => {
      return false;
    });

  if (totalCostsAmount === 0 && financingSourcesTotal === 0) {
    return [
      grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts, // Tilskudd must have value
      financingSourcesTotal > 0,
      false,
    ];
  } else {
    return [
      grantsFromInnovationNorwayGraterThanZeroAndHalfValueOfTotalCosts, // Tilskudd must have value
      ...costsWithMissingName,
      totalCostsAmount > 0 && totalCostsAmount === financingSourcesTotal, //Totale kostnader må være lik summen av finansieringen,
      ...otherFinancingTypes,
    ];
  }
}
