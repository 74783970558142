import React, { useMemo } from 'react';
import { ClusterDto } from 'src/models';
import InformationCard from '../InformationCard/InformationCard';
import css from './ClusterChooser.module.scss';
import { useNavigate } from 'react-router';
import { Colors, Spinner } from '@in/component-library';
import useUser from '../../hooks/user/use-user';
import useClusters from '@hooks/cluster/use-clusters';
import Page from '../Page/Page';
import { ClusterStatuses } from '@src/constants/cluster-status';

const ClusterChooser: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { storeChosenClusterMutation, isInitialLoading } = useClusters();
  const { clusters } = useClusters();

  const clusterStatusIds: string[] = Object.values(ClusterStatuses)
    .filter((c) => c.id !== ClusterStatuses.NotSet.id)
    .map((clusterStatus) => clusterStatus.id);

  const activeClusters = useMemo(() => {
    return clusters
      .filter((c) => clusterStatusIds.includes(c.clusterStatus!.id))
      .sort((a, b) => {
        if (!a.name || !b.name) return 0;
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }, [clusterStatusIds, clusters]);

  const onSelectCluster = async (cluster: ClusterDto) => {
    await storeChosenClusterMutation.mutateAsync(cluster);

    if (location.pathname.endsWith('/cluster-chooser')) {
      navigate('/');
    }

    if (storeChosenClusterMutation.isLoading) {
      return <Spinner text="Lagrer valgt klynge..." />;
    }
  };

  if (clusters === undefined) {
    return <Spinner text="Henter klynger..." />;
  }

  return (
    <Page wrapWithBody={true}>
      <div className={css['cluster-chooser']}>
        <h2 className={css['cluster-chooser__title']}>Velg klynge</h2>
        <div className={css['cluster-chooser__container']}>
          {isInitialLoading ? (
            <Spinner text="Henter klynger..." />
          ) : (
            activeClusters.map((cluster) => {
              return (
                <div
                  key={cluster.id}
                  className={css['cluster-chooser__container--card']}
                  onClick={() => onSelectCluster(cluster)}
                  tabIndex={0}
                >
                  <InformationCard
                    title={cluster.name}
                    category={user?.chosenClusterId === cluster.id ? 'Valgt klynge' : ''}
                    description={''}
                    date={null}
                    messageType={user?.chosenClusterId === cluster.id ? Colors.Blue100 : Colors.White}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </Page>
  );
};

export default ClusterChooser;
