import { SessionClient } from '../../models';
import { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  USER_KEY,
  USER_SESSION_HAS_EXPIRED,
  USER_STATUS_HAS_CLUSTERS,
  USER_STATUS_HAS_LOGIN_ERROR_4XX,
  USER_STATUS_HAS_LOGIN_ERROR_5XX,
  USER_STATUS_LOADING,
  USER_STATUS_LOGGED_IN,
  USER_STATUS_REGISTERED,
} from '../../constants/hook-keys';

const useUser = () => {
  const [sessionClient] = useState(new SessionClient());
  const queryClient = useQueryClient();
  const { data: loadingUser } = useQuery([USER_STATUS_LOADING], () => true, {
    enabled: false,
    initialData: true,
  });

  const { data: isUserLoggedIn } = useQuery([USER_STATUS_LOGGED_IN], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: isUserRegistered } = useQuery([USER_STATUS_REGISTERED], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserClusters } = useQuery([USER_STATUS_HAS_CLUSTERS], () => true, {
    enabled: false,
    // Satt til true default, pga renders, hvis denne er false 1-2 første render ved refresh
    // useClusters() får da i oppgave om å motvevise at denne da er `false` hvis ingen klynger
    initialData: true,
  });

  const { data: hasUserSessionExpired } = useQuery([USER_SESSION_HAS_EXPIRED], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserLoginError4XX } = useQuery([USER_STATUS_HAS_LOGIN_ERROR_4XX], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserLoginError5XX } = useQuery([USER_STATUS_HAS_LOGIN_ERROR_5XX], () => false, {
    enabled: false,
    initialData: false,
  });

  const setHasUserClusters = (value: boolean) => queryClient.setQueryData([USER_STATUS_HAS_CLUSTERS], value);

  const { data: user, ...rest } = useQuery([USER_KEY], () => sessionClient.getCurrent(), {
    refetchInterval: 1000 * 60,
    retry: false,

    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData([USER_STATUS_LOGGED_IN], true);
        queryClient.setQueryData([USER_STATUS_REGISTERED], true);
        queryClient.setQueryData([USER_STATUS_LOADING], false);
        queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_4XX], false);
        queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_5XX], false);
        queryClient.setQueryData([USER_SESSION_HAS_EXPIRED], false);
      }
    },
    onError: (error) => {
      const status = (error as any).Status || (error as any).status;
      switch (status) {
        case 404:
          queryClient.setQueryData([USER_STATUS_LOGGED_IN], true);
          queryClient.setQueryData([USER_STATUS_REGISTERED], false);
          break;

        case 400:
        case 403:
          queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_4XX], true);
          break;
        case 500:
          queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_5XX], true);
          break;
        default:
          if (isUserLoggedIn) {
            // Bruker var logget inn, dvs at sesjon har gått ut.
            queryClient.setQueryData([USER_SESSION_HAS_EXPIRED], true);
          }

          queryClient.setQueryData([USER_STATUS_LOGGED_IN], false);
          queryClient.setQueryData([USER_STATUS_REGISTERED], false);
          queryClient.setQueryData([USER_STATUS_HAS_CLUSTERS], false);
          break;
      }

      queryClient.setQueryData([USER_STATUS_LOADING], false);
      queryClient.setQueryData([USER_KEY], undefined);
    },
  });

  const chosenClusterId = useMemo<string | undefined>(() => {
    if (!user) return undefined;

    return user?.chosenClusterId;
  }, [user]);

  const displayName = useMemo<string | undefined>(() => {
    if (!user) return undefined;

    return user.displayName;
  }, [user]);

  return {
    loadingUser,
    isUserLoggedIn,
    isUserRegistered,
    hasUserClusters,
    hasUserLoginError4XX,
    hasUserLoginError5XX,
    chosenClusterId,
    displayName,
    hasUserSessionExpired,
    setHasUserClusters,
    user: user,
    ...rest,
  };
};

export default useUser;
