import { useState } from 'react';
import { ApplicationsClient, SoppExpenseOverview } from '../../models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { USE_APPLICATIONS_EXPENSE_OVERVIEW } from '../../constants/hook-keys';

export default function useApplicationsExpenseOverview() {
  const [applicationsClient] = useState(new ApplicationsClient());
  const queryClient = useQueryClient();

  const { data: applicationsExpenseOverview } = useQuery<SoppExpenseOverview | null>(
    [USE_APPLICATIONS_EXPENSE_OVERVIEW],
    () => null,
    {
      enabled: false,
      initialData: null,
    },
  );

  const getApplicationsExpenseOverviewMutation = useMutation(
    (applicationIds: string[]) => {
      return applicationsClient.getApplicationsExpenseOverview({ applicationIds: applicationIds });
    },
    {
      onSuccess(data) {
        queryClient.setQueryData([USE_APPLICATIONS_EXPENSE_OVERVIEW], data);
      },
    },
  );

  return {
    applicationsExpenseOverview,
    getApplicationsExpenseOverviewMutation,
  };
}
