import React, { FC, lazy } from 'react';
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
import useMainApplication from '../../hooks/mainApplication/use-main-application';

const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export const GenderDistributionInClusterManagement: FC = () => {
  const { clusterManagement } = useMainApplication();
  return (
    <>
      <ThemeSection>
        <h3>Kjønnsfordeling i klyngeledelsen</h3>
        <DescriptionList>
          <DescriptionListItem
            name="Kvinner"
            value={clusterManagement?.femaleCount != null ? clusterManagement.femaleCount : '0'}
          />
          <DescriptionListItem
            name="Menn"
            value={clusterManagement?.maleCount != null ? clusterManagement.maleCount : '0'}
          />
        </DescriptionList>
      </ThemeSection>
    </>
  );
};
