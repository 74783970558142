import React from 'react';
import classNames from 'classnames';
import { Card, FaIcon, FaIcons } from '@in/component-library';

import './InformationCard.scss';

interface InformationCardProps {
  messageType?: string;

  title: React.ReactNode;
  description: React.ReactNode;

  category: React.ReactNode;
  date: Date | null;

  onClick?: () => void;

  hideIcon?: boolean;
  icon?: FaIcons;
}

const InformationCard: React.FC<InformationCardProps> = ({
  title,
  category,
  onClick,
  messageType,
  hideIcon = false,
  icon = FaIcons.ChevronRight,
}) => {
  const classes = classNames('information-card__wrapper', {
    'is-clickable': onClick !== undefined,
  });

  return (
    <div className={classes} onClick={onClick && onClick}>
      <Card backgroundColor={messageType} boxShadow={true}>
        <div className="information-card">
          <div className="information-card__content">
            <div className="information-card__metadata">
              <strong>
                <small>{category}</small>
              </strong>
            </div>
            <div className="information-card__title">
              <h2>{title}</h2>
            </div>
          </div>
          {hideIcon === false ? (
            <div className="information-card__icon">
              <FaIcon name={icon} size="2x" />
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default InformationCard;
