import { CompleteApplicationDto, OperationWithMetadataDto } from '../../models';
import {
  financingSourcesIsValid,
  getNumberOfMissingFields,
  societalEffectsPageIsValid,
} from './storeValidationFunctions';

export function validateKeyNumbers(
  state: CompleteApplicationDto | undefined,
  operationWithMetadataDto: OperationWithMetadataDto | undefined,
) {
  let clusterClusterMissingFields = -1;
  if (state != null) {
    /** Klyngen*/
    const clusterPageIsValid: boolean[] = [];

    const goodBusinessPracticeConsent = state.annualFunding?.goodBusinessPracticeConsent;

    clusterPageIsValid.push(
      operationWithMetadataDto?.activitiesNotStarted != null
        ? operationWithMetadataDto.activitiesNotStarted
        : false,
    );

    clusterPageIsValid.push(goodBusinessPracticeConsent != null ? goodBusinessPracticeConsent : false);
    clusterClusterMissingFields = getNumberOfMissingFields(clusterPageIsValid, 2);
  }
  return clusterClusterMissingFields;
}

export function validateResourceBase(state: CompleteApplicationDto | undefined) {
  let clusterResourcesMissingFields = -1;
  if (state != null) {
    const annualFunding = state?.annualFunding;
    const clusterResourceBasePageIsValid = [
      annualFunding != null &&
        annualFunding?.resourceBaseComments != null &&
        annualFunding.resourceBaseComments !== '',
      annualFunding != null &&
        annualFunding?.clusterOrganizationComments != null &&
        annualFunding.clusterOrganizationComments !== '',
    ];

    clusterResourcesMissingFields = getNumberOfMissingFields(clusterResourceBasePageIsValid, 2);
  }
  return clusterResourcesMissingFields;
}

export function activitiesResultThisYearPath(state: OperationWithMetadataDto | undefined) {
  if (state?.activity != null && state?.activity?.goalsThisYear != null) {
    let activitiesResultThisYearPageMissingFields = -1;
    let numberOfFieldsActivitiesResultThisYearPage = 2;

    const achievementCommentsMissing = state.activity.goalsThisYear.map((item) => {
      return item.achievementComments != null && item.achievementComments !== '';
    });
    const activitiesForAchievingMissing = state.activity.goalsThisYear.map((item) => {
      return item.activitiesForAchieving != null && item.activitiesForAchieving !== '';
    });
    if (state.activity.goalsThisYear.length > 0) {
      numberOfFieldsActivitiesResultThisYearPage =
        achievementCommentsMissing.length + activitiesForAchievingMissing.length + 1;
    }

    const activitiesResultThisYearPageIsValid: boolean[] = [
      state.activity.resultsDescription != null && state.activity.resultsDescription !== '',
      state.activity.goalsThisYear.length > 0,
      ...achievementCommentsMissing,
      ...activitiesForAchievingMissing,
    ];

    activitiesResultThisYearPageMissingFields = getNumberOfMissingFields(
      activitiesResultThisYearPageIsValid,
      numberOfFieldsActivitiesResultThisYearPage,
    );

    return activitiesResultThisYearPageMissingFields;
  }
  return -1;
}
export function activitiesGoalsAndFocusAreasNextYear(state: OperationWithMetadataDto | undefined) {
  if (
    state?.activity != null &&
    state?.activity?.focusAreasNextYear != null &&
    state?.activity?.goalsNextYear != null
  ) {
    let activitiesGoalsAndFocusAreasNextYearPageMissingFields = -1;

    const activitiesGoalsAndFocusAreasNextYearPageIsValid: boolean[] = [
      state.activity.goalsNextYear.length > 0,
      state.activity.focusAreasNextYear.length > 0,
      ...state.activity.goalsNextYear.map((item) => {
        return item.description != null && item.description !== '';
      }),
      ...state.activity.focusAreasNextYear.map((item) => {
        return item.description != null && item.description !== '';
      }),
    ];
    activitiesGoalsAndFocusAreasNextYearPageMissingFields = getNumberOfMissingFields(
      activitiesGoalsAndFocusAreasNextYearPageIsValid,
      2,
    );

    return activitiesGoalsAndFocusAreasNextYearPageMissingFields;
  }
  return -1;
}
export function planedActivitiesNextYear(state: OperationWithMetadataDto | undefined) {
  if (state?.activity != null && state?.activity?.plannedActivities != null) {
    let planedActivitiesNextYearMissingFields = -1;
    const planedActivitiesNextYearPageIsValid: boolean[] = [
      state.activity?.plannedActivitiesOverallDescription != null &&
        state.activity.plannedActivitiesOverallDescription !== '',
      state.activity.plannedActivities.length > 0,
    ];

    planedActivitiesNextYearMissingFields = getNumberOfMissingFields(planedActivitiesNextYearPageIsValid, 2);

    return planedActivitiesNextYearMissingFields;
  }
  return -1;
}

/**
 * Validate "Samfunns- og miljøeffekt"
 * @param state
 */
export function validateSocietalEffectsPage(state: OperationWithMetadataDto | undefined) {
  if (state == null) {
    return -1;
  }
  const societalEffectsPageMissingFields = getNumberOfMissingFields(
    societalEffectsPageIsValid(
      state?.impact?.notApplicable,
      state?.impact?.notApplicableComment,
      state?.impact?.impacts,
    ),
    1,
  );
  return societalEffectsPageMissingFields;
}

/**
 * Validate "Finansieringsplan and "Oppsummering finansiering"
 * @param state
 */
export function validateFinancingPage(state: OperationWithMetadataDto | undefined) {
  if (state?.financing?.sources != null && state?.attachments != null) {
    const financingPlanPageIsValid = financingSourcesIsValid(
      state.activity?.plannedActivities != null ? state.activity.plannedActivities : [],
      state.financing.sources,
    );
    const otherFinancingTypes = state.financing.sources.filter(
      (item) => item.type === undefined && item.name === '',
    );

    const financingPlanPageMissingFields = getNumberOfMissingFields(
      financingPlanPageIsValid,
      3 + otherFinancingTypes.length,
    );

    return financingPlanPageMissingFields;
  }
  return -1;
}
