import React, { FC, lazy, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FaIcon, FaIcons } from '@in/component-library/';
import { ContextMenuItem, ContextMenuPosition } from './ContextMenu';
const ContextMenu = lazy(() => import('./ContextMenu'));
import { useWindowSize } from '../../hooks/CustomHooks';
import css from './ContextMenu.module.scss';
import generateGuid from '../../utils/Guid';

export interface ContextMenuTriggerButtonProps {
  contextMenu?: ContextMenuItem[];
}

export const ContextMenuTriggerButton: FC<ContextMenuTriggerButtonProps> = ({
  contextMenu,
}: PropsWithChildren<ContextMenuTriggerButtonProps>) => {
  const id = generateGuid();
  const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuPosition>({
    x: 0,
    y: 0,
    widthOfElement: 0,
  });
  const size = useWindowSize();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (buttonRef?.current != null) {
      const boundingClientRect = buttonRef.current.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offsetY = boundingClientRect.top - bodyRect.top;
      const offsetX = boundingClientRect.left - bodyRect.left;

      setContextMenuPosition({
        x: offsetX,
        y: offsetY + boundingClientRect.height,
        widthOfElement: boundingClientRect.width,
      });
    }
  }, [size, buttonRef]);

  return (
    <>
      <button
        className={css['context-menu__trigger-button']}
        aria-label="Valg"
        aria-haspopup={'menu'}
        aria-controls={id}
        aria-expanded={contextMenuVisible}
        ref={buttonRef}
        onClick={(event) => {
          const boundingClientRect = event.currentTarget.getBoundingClientRect();

          const bodyRect = document.body.getBoundingClientRect();
          const offsetY = boundingClientRect.top - bodyRect.top;
          //
          const offsetX = boundingClientRect.left - bodyRect.left;
          setContextMenuPosition({
            x: offsetX,
            y: offsetY + boundingClientRect.height,
            widthOfElement: boundingClientRect.width,
          });
          setContextMenuVisible(!contextMenuVisible);
        }}
      >
        <FaIcon name={FaIcons.EllipsisH} />
      </button>
      {contextMenuVisible && contextMenu && (
        <ContextMenu
          id={id}
          position={contextMenuPosition}
          contextMenu={contextMenu}
          clickOutsideHandler={() => {
            setContextMenuVisible(false);
          }}
        />
      )}
    </>
  );
};
