import React, { lazy, useMemo, useRef, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Card,
  InputField,
  TextArea,
  Theme,
  Select,
  CheckboxOptionProps,
  MultiSelectListBox,
  ModalActionButtonContainer,
  ConfirmationBox,
} from '@in/component-library/';
import generateGuid, { emptyGuid } from '../../../utils/Guid';
import { Cost, FocusArea, Goal, OperationPlannedActivity } from '../../../models';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { getDateAsISOStringWithoutTimeZoneOffset } from '../../../utils/DateUtils';
import { formatDateInputDate } from '../../../modules/utils/method-modules/FormatValue';
import { ShadowHeight } from '@in/component-library';
import DateInput from '../../../components/NativeDateInput/DateInput';
import { ActivityCostTable } from './ActivityCostTable';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
const DateInputWrapper = lazy(() => import('src/components/DateInputWrapper/DateInputWrapper'));

export interface NewActivityProps {
  onSave: (activity: OperationPlannedActivity) => void;
  onDelete: (planedActivityId: string | null) => void;
  onCancel?: () => void;
  activity?: OperationPlannedActivity | null;
}
export type NewActivityFormInputs = {
  activityName: string;
  activityDescription: string;
  activityGoalDescription: string;
  activityCostComment: string;
  activityDatesFrom: string;
  activityDatesEnd: string;
  focusAreas: string;
  costs: Cost[];
  mainGoalId: string;
  startDate: string;
  endDate: string;
  focusAreaIds: string[];
};

export const NewActivity: React.FC<NewActivityProps> = ({
  onSave,
  onDelete,
  onCancel,
  activity = null,
}: NewActivityProps) => {
  const currentYear: number = new Date().getFullYear();

  const confirmDeleteRef = useRef<HTMLDialogElement>(null);

  const [minEndDate, setMinEndDate] = useState<string>(
    activity != null && activity.startDate != null
      ? formatDateInputDate(new Date(activity.startDate))
      : formatDateInputDate(new Date(currentYear + 1, 0, 1)),
  );

  const { applicationId } = useUrlApplicationId();
  const { goalsNextYear, focusAreasNextYear } = useOperationApplication(applicationId);

  const [focusAreaSelectedIdsErrorMessage, setFocusAreaSelectedIdsErrorMessage] = useState<string>('');

  const { getValues, handleSubmit, formState, control, setValue, reset } = useForm<NewActivityFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      activityName: activity?.name,
      activityCostComment: activity?.costComments ?? '',
      activityDescription: activity?.description ?? '',
      activityGoalDescription: activity?.goalDescription ?? '',
      mainGoalId: activity?.mainGoalId != null ? activity.mainGoalId : '',
      startDate:
        activity != null && activity.startDate != null
          ? formatDateInputDate(new Date(activity.startDate))
          : formatDateInputDate(new Date(currentYear + 1, 0, 1)),
      endDate:
        activity != null && activity.endDate != null ? formatDateInputDate(new Date(activity.endDate)) : '',
      costs:
        activity != null && activity?.costs != null
          ? activity.costs
          : [{ id: generateGuid(), participantsAmount: 0, applicantAmount: 0 }],
      focusAreaIds: activity?.focusAreaIds != null ? activity?.focusAreaIds : [],
    },
  });

  const result = useWatch({ control });

  const focusAreaOptions = useMemo(() => {
    return result?.focusAreaIds != null
      ? result.focusAreaIds?.map((id: string) => {
          const focusArea = focusAreasNextYear.find((focusArea) => {
            return focusArea?.id === id;
          });

          return {
            value: focusArea?.id != null ? focusArea?.id : '',
            text: focusArea?.name != null ? focusArea?.name : '',
            selected: true,
          };
        })
      : [];
  }, [focusAreasNextYear, result.focusAreaIds]);

  function formNotValidHandler(): void {
    if (getValues('focusAreaIds') == null || getValues('focusAreaIds').length === 0) {
      setFocusAreaSelectedIdsErrorMessage('Dere må velge minst ett fokusområde')
    } else {
      setFocusAreaSelectedIdsErrorMessage('');
    }
  }

  async function saveActivityClickHandler(activityFormInputs: NewActivityFormInputs): Promise<void> {
    if (Object.keys(formState.errors).length === 0 && activityFormInputs.focusAreaIds.length > 0) {
      const activityLocal: OperationPlannedActivity = {
        id: activity != null && activity.id != null ? activity.id : generateGuid(),
        name: activityFormInputs.activityName,
        description: activityFormInputs.activityDescription,
        goalDescription: activityFormInputs.activityGoalDescription,
        mainGoalId: activityFormInputs.mainGoalId,
        startDate: getDateAsISOStringWithoutTimeZoneOffset(new Date(activityFormInputs.startDate)),
        endDate: getDateAsISOStringWithoutTimeZoneOffset(new Date(activityFormInputs.endDate)),
        costComments: activityFormInputs.activityCostComment,
        costs: activityFormInputs.costs,
        focusAreaIds: activityFormInputs.focusAreaIds,
      };

      onSave(activityLocal);
    }

    if (activityFormInputs.focusAreaIds == null || activityFormInputs.focusAreaIds.length === 0) {
      setFocusAreaSelectedIdsErrorMessage('Dere må velge minst ett fokusområde');
    }
  }

  return (
    <form onSubmit={handleSubmit(saveActivityClickHandler, formNotValidHandler)} noValidate={true}>
      <Controller
        control={control}
        name={'activityName'}
        rules={{
          required: { value: true, message: 'Feltet er påkrevd' },
          maxLength: { value: 200, message: 'Navnet kan maks være 200 tegn' },
        }}
        render={({ field, fieldState }) => (
          <InputField
            {...field}
            value={field.value}
            autoComplete="off"
            errorMsg={fieldState?.error?.message?.toString()}
            label={`Navn på aktiviteten`}
            maxCount={200}
            maxLength={200}
          />
        )}
      />

      <Controller
        control={control}
        name={'activityDescription'}
        rules={{
          required: { value: true, message: 'Feltet er påkrevd' },
          maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' },
        }}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            errorMsg={fieldState?.error?.message?.toString()}
            label="Beskriv aktiviteten"
            maxCount={InputMaxLength.medium}
            maxLength={InputMaxLength.medium}
            rows={3}
            disableResize={true}
          />
        )}
      />

      <Controller
        control={control}
        name={'activityGoalDescription'}
        rules={{
          required: { value: true, message: 'Feltet er påkrevd' },
          maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' },
        }}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            errorMsg={fieldState?.error?.message?.toString()}
            label="Hva er resultatmålet til aktiviteten?"
            maxCount={InputMaxLength.medium}
            maxLength={InputMaxLength.medium}
            rows={3}
            disableResize={true}
          />
        )}
      />

      <h3>Klyngens hovedmål</h3>

      <Controller
        control={control}
        name={`mainGoalId` as const}
        rules={{
          validate: (value) => {
            return value != null && value !== emptyGuid() && value !== '' ? true : 'Feltet er påkrevd';
          },
        }}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            label="Knytt aktiviteten til ett av klyngens hovedmål som dere definerte tidligere i søknaden"
            placeholder="Velg ett hovedmål"
            errorMsg={fieldState.error?.message}
            options={goalsNextYear.map((goal: Goal) => {
              return {
                selected: false,
                text: goal.name != null ? goal.name : '',
                value: goal.id != null ? goal.id : '',
              };
            })}
          />
        )}
      />

      <h3>Klyngens fokusområder</h3>

      {focusAreasNextYear.length ? (
        <MultiSelectListBox
          useFilterInput={false}
          truncateSelectedBoxes={false}
          showSelectedTagsContainerWhileExpanded={false}
          label="Knytt aktiviteten til ett eller flere av klyngens fokusområder som dere definerte tidligere i søknaden"
          name="activityFocusAreas"
          errorMessage={focusAreaSelectedIdsErrorMessage}
          placeHolder="Velg fokusområder"
          placeHolderFilterInput="Filtrer fokusområder"
          options={focusAreasNextYear.map((focusArea: FocusArea) => {
            return {
              selected: true,
              text: focusArea.name != null ? focusArea.name : '',
              value: focusArea.id != null ? focusArea.id : '',
            };
          })}
          value={focusAreaOptions}
          onChange={(selected) => {
            setValue(
              'focusAreaIds',
              selected.map((item: CheckboxOptionProps) => {
                return item.value;
              }),
            );
          }}
          isSaving={false}
        />
      ) : (
        <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
          <h2>Mangler fokusområder</h2>
          Dere må legge til fokusområder som dere ønsker å jobbe med
        </Card>
      )}

      <h3>Framdrift</h3>
      <p>Når starter aktiviteten og hvor lang tid tar det å gjennomføre den?</p>
      <DateInputWrapper>
        <Controller
          control={control}
          name={'startDate'}
          rules={{
            required: { value: true, message: 'Feltet er påkrevd' },
          }}
          render={({ field, fieldState }) => (
            <DateInput
              {...field}
              onBlur={(event) => {
                field.onBlur();
                setMinEndDate(event.target.value);
              }}
              label={'Aktiviteten starter:'}
              errorMsg={fieldState.error?.message}
              min={formatDateInputDate(new Date(currentYear + 1, 0, 1))}
              showClearButton={false}
              showIcon
            />
          )}
        />

        <Controller
          control={control}
          name={'endDate'}
          rules={{
            validate: (value) => {
              const startDate = new Date(formatDateInputDate(getValues('startDate'))).getTime();
              const inputDate = new Date(value).getTime();
              return inputDate >= startDate ? true : 'Dato må være etter startdato';
            },
          }}
          render={({ field, fieldState }) => (
            <DateInput
              {...field}
              id="endDate"
              label={'Aktiviteten er ferdig:'}
              errorMsg={fieldState.error?.message?.toString()}
              min={minEndDate}
              showClearButton={false}
              showIcon
            />
          )}
        />
      </DateInputWrapper>

      <h3>Kostnadsoverslag</h3>
      <p>Her legger dere til kostnadsestimat for aktiviteten. Kostnadene skal fordeles på kostnadstyper.</p>

      <ActivityCostTable control={control} getValues={getValues} />

      <Controller
        control={control}
        name={'activityCostComment'}
        rules={{ maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' } }}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            errorMsg={fieldState?.error?.message?.toString()}
            label="Kommentar til aktivitetskostnadene"
            maxCount={InputMaxLength.medium}
            maxLength={InputMaxLength.medium}
            rows={3}
            disableResize={true}
          />
        )}
      />

      <ModalActionButtonContainer>
        <div>
          <Button theme={Theme.Neutral} type={'submit'}>
            {activity != null ? 'Lagre' : 'Legg til'}
          </Button>
          <Button
            theme={Theme.None}
            type={'button'}
            onClick={() => {
              reset();
              onCancel?.();
            }}
            variant={ButtonVariant.Link}
          >
            Avbryt
          </Button>
        </div>

        <div>
          <Button
            theme={Theme.Danger}
            type={'button'}
            onClick={() => {
              confirmDeleteRef.current?.showModal();
            }}
            variant={ButtonVariant.Outlined}
          >
            Slett
          </Button>
        </div>

        <ConfirmationBox
          title="Sikker på at dere vil slette?"
          confirmButtonTheme={Theme.Danger}
          onConfirm={() => {
            onDelete?.(activity != null && activity?.id != null ? activity.id : null);
            confirmDeleteRef.current?.close();
            reset();
          }}
          onCancel={() => confirmDeleteRef.current?.close()}
          confirmText="Ja"
          cancelText="Avbryt"
          ref={confirmDeleteRef}
        />
      </ModalActionButtonContainer>
    </form>
  );
};
