import { useMutation, useQuery } from '@tanstack/react-query';
import { GET_ARCHIVED_APPLICATIONS } from '../../constants/hook-keys';
import { useState } from 'react';
import { ApplicationsClient, SubmittedFormMetadata } from '../../models';
import useUser from '../user/use-user';
import { sortArrayOnProperty } from '../../utils/ArrayUtils';
import { ArchiveGroup } from '../../pages/Application/ArchiveTabContent';
import { queryClient } from '../../reactQuery/reactQueryCallbackFunctions';

export default function useArchivedApplications() {
  const [applicationsClient] = useState(new ApplicationsClient());
  const { user, chosenClusterId } = useUser();
  const { data: archivedApplications, ...rest } = useQuery(
    [GET_ARCHIVED_APPLICATIONS],
    () => applicationsClient.getArchivedApplications(),
    {
      retry: false,
      enabled: !!user && !!chosenClusterId,
    },
  );

  const getArchivedApplicationAsPdfMutation = useMutation(
    (id: string) => {
      return applicationsClient.getArchivedApplicationAsPdf(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([GET_ARCHIVED_APPLICATIONS]);
      },
    },
  );
  const getArchivedApplicationAsDocxMutation = useMutation(
    (id: string) => {
      return applicationsClient.getArchivedApplicationAsDocx(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([GET_ARCHIVED_APPLICATIONS]);
      },
    },
  );

  return {
    archivedApplications: archivedApplications ?? [],
    archivedApplicationsGroupedByYear: getArchiveGroup(archivedApplications),
    getArchivedApplicationAsPdfMutation,
    getArchivedApplicationAsDocxMutation,
    ...rest,
  };
}
function getArchiveGroup(list: SubmittedFormMetadata[] | undefined): ArchiveGroup[] {
  if (list == null) {
    return [];
  }
  const years = list.reduce<Record<string, SubmittedFormMetadata[]>>((groups, pdfObject) => {
    if (pdfObject?.modifiedOn != null) {
      const pdfDate = new Date(pdfObject.modifiedOn);
      const year = pdfDate.getFullYear();
      if (!groups[year]) {
        groups[year] = [];
      }
      groups[year].push(pdfObject);
      return groups;
    } else {
      return groups;
    }
  }, {});
  return sortArrayOnProperty(
    Object.entries(years).map(([key, value]) => {
      return {
        year: key,
        documents: value,
      };
    }),
    'year',
    'string',
    'ascending',
  );
}
