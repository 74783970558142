import React, { FC, lazy, useEffect, useMemo, useState } from 'react';
import { Button, ButtonVariant, FaIcons, Spinner, TextArea, Theme } from '@in/component-library';
import { Controller, useForm } from 'react-hook-form';
import { InputMaxLength } from '../../../enums/InputMaxLength';
import { NewActivity } from './NewActivity';
import { RHFRequiredNotEmptyExtraLargeMaxLength } from '../../../utils/FormValidations';
import { ErrorMessage } from '@in/component-library/';
import { sumPropertiesOnType } from '../../../hooks/CustomHooks';
import { useAppLocation } from '../../../hooks/use-app-location';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
import { OperationPlannedActivity } from '@src/models';
import DialogContainer from '@src/components/DialogContainer/DialogContainer';
const ActivityListItem = lazy(() => import('src/components/Activity/ActivityListItem'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

interface FormInput {
  plannedActivitiesOverallDescription: string;
}
export interface GoalsAndFocusAreasNextYearProps {
  title?: string;
  ingress?: string;
}
const PlanedActivitiesNextYear: FC<GoalsAndFocusAreasNextYearProps> = ({ title, ingress }) => {
  const { state } = useAppLocation();
  const [showActivityModal, setShowActivityModal] = useState<boolean>(false);
  const [plannedActivitiesErrorMessage, setPlannedActivitiesErrorMessage] = useState<string>('');

  const { applicationId } = useUrlApplicationId();

  const {
    plannedActivities,
    plannedActivitiesOverallDescription,
    patchOperationApplicationMutation,
    putOperationActivityMutation,
    deleteOperationActivityMutation,
    isLoading,
  } = useOperationApplication(applicationId);

  const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>();

  const selectedActivity = useMemo(() => {
    return plannedActivities.find((item) => item.id === selectedActivityId);
  }, [plannedActivities, selectedActivityId]);

  const { control, getValues, reset, trigger } = useForm<FormInput>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      plannedActivitiesOverallDescription: '',
    },
  });

  useEffect(() => {
    if (plannedActivitiesOverallDescription == null) {
      return;
    }
    reset({ ...getValues(), plannedActivitiesOverallDescription: plannedActivitiesOverallDescription });
  }, [plannedActivitiesOverallDescription, reset, getValues]);

  useEffect(() => {
    if (state != null && state.validate) {
      requestAnimationFrame(async () => {
        await trigger();
        setPlannedActivitiesErrorMessage(
          plannedActivities.length > 0 ? '' : 'Dere må legge inn minst en aktivitet',
        );
      });
    }
  }, [state, trigger, plannedActivities]);

  function addActivityClickHandler(): void {
    setSelectedActivityId(undefined);
    setShowActivityModal(true);
  }

  function activityModalCloseHandler(): void {
    setSelectedActivityId(undefined);
    setShowActivityModal(false);
  }

  function editActivityClickHandler(activity: OperationPlannedActivity): void {
    setSelectedActivityId(activity!.id);
    setShowActivityModal(true);
  }

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <h2>Fokus for det kommende året</h2>
        <Controller
          control={control}
          name={'plannedActivitiesOverallDescription'}
          rules={{ ...RHFRequiredNotEmptyExtraLargeMaxLength }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Hvor ligger klyngens hovedfokus? Må dere gjøre justeringer fra inneværende år?"
              maxCount={InputMaxLength.extraLarge}
              maxLength={InputMaxLength.extraLarge}
              rows={3}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={() => {
                field.onBlur();
                trigger('plannedActivitiesOverallDescription').then((isValid) => {
                  if (
                    isValid &&
                    getValues('plannedActivitiesOverallDescription') !== plannedActivitiesOverallDescription
                  ) {
                    patchOperationApplicationMutation.mutateAsync({
                      activity: {
                        plannedActivitiesOverallDescription: getValues('plannedActivitiesOverallDescription'),
                      },
                    });
                  }
                });
              }}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>
      <ThemeSection>
        <h3>Aktiviteter</h3>
        <p>
          Her skal dere legge til aktivitetene klyngen vil gjennomføre neste år. De utgjør fremdriftsplanen
          til klyngen og vil bli overført til Flerbedriftsportalen etter at søknad er sendt inn.
        </p>
      </ThemeSection>

      {plannedActivities.map((activity) => {
        return (
          <ActivityListItem
            key={activity.id}
            title={activity?.name ? activity.name : 'no-name'}
            totalCost={
              activity?.costs != null
                ? sumPropertiesOnType(activity.costs, ['applicantAmount', 'participantsAmount'])
                : 0
            }
            onClick={() => {
              editActivityClickHandler(activity);
            }}
          />
        );
      })}
      {isLoading && <Spinner text="Laster aktiviteter" />}
      <ThemeSection>
        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Link}
          onClick={() => addActivityClickHandler()}
          iconName={FaIcons.PlusLight}
        >
          Legg til aktivitet
        </Button>
        {plannedActivitiesErrorMessage !== '' && (
          <ErrorMessage errorMessage={plannedActivitiesErrorMessage} />
        )}
      </ThemeSection>
      <DialogContainer
        title={'Legg til aktivitet'}
        onClose={() => activityModalCloseHandler()}
        show={showActivityModal}
      >
        <NewActivity
          activity={selectedActivity}
          onCancel={() => activityModalCloseHandler()}
          onDelete={(planedActivityId: string | null) => {
            setShowActivityModal(false);
            if (planedActivityId != null) {
              deleteOperationActivityMutation.mutateAsync(planedActivityId);
            }
          }}
          onSave={(activity) => {
            setShowActivityModal(false);
            if (activity?.id != null) {
              putOperationActivityMutation.mutateAsync(activity);
              setSelectedActivityId(undefined);
            }
          }}
          key={selectedActivity?.id}
        />
      </DialogContainer>
    </div>
  );
};

export default PlanedActivitiesNextYear;
