import React, { FC, lazy, useMemo } from 'react';
import { ClusterStatusEnum } from '../../models';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export const KeyInformation: FC = () => {
  const { clusterKeyInformation } = useMainApplication();

  const address = useMemo(() => {
    const address: string = clusterKeyInformation?.address != null ? clusterKeyInformation.address : '';
    const zipCode: string = clusterKeyInformation?.postalCode != null ? clusterKeyInformation.postalCode : '';
    const city: string = clusterKeyInformation?.city != null ? clusterKeyInformation.city : '';

    return `${address} ${zipCode} ${city}`;
  }, [clusterKeyInformation?.address, clusterKeyInformation?.postalCode, clusterKeyInformation?.city]);

  return (
    <>
      <ThemeSection>
        <h2>Nøkkelopplysninger</h2>
        <p>Klyngens nøkkelopplysninger blir hentet fra flerbedriftsportalen.</p>
        <DescriptionListItem
          name="Navn"
          value={clusterKeyInformation?.name != null ? clusterKeyInformation.name : '-'}
        />
        <DescriptionList>
          <DescriptionListItem
            name="Organisasjonsnummer"
            value={
              clusterKeyInformation?.clusterOwnerOrgNumber != null
                ? clusterKeyInformation.clusterOwnerOrgNumber
                : '-'
            }
          />
          <DescriptionListItem
            name="Klyngenivå"
            value={
              clusterKeyInformation?.clusterStatus != null
                ? ClusterStatusEnum[clusterKeyInformation.clusterStatus]
                : '-'
            }
          />
          <DescriptionListItem name="Forretningsadresse" value={address} />
          <DescriptionListItem
            name="Næringskode"
            value={
              clusterKeyInformation?.industryCode ? clusterKeyInformation.industryCode : 'Næringskode mangler'
            }
          />
        </DescriptionList>
      </ThemeSection>
    </>
  );
};
