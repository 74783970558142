import React from 'react';
import { FocusArea } from '../../models';
import { Accordion } from '@in/component-library';

import { Control } from 'react-hook-form';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';
import FocusAreaAccordionInputFields from './FocusAreaAccordionInputFields';
import { GoalsAndFocusAreasFormInputs } from '../../Application/themePageSections/ClustersGoalsAndFocusAreas';

export interface FocusAreaAccordionProps {
  showSaveButton?: boolean;
  onRemove: (index: number, focusArea: FocusArea) => void;
  onSave: (index: number, isDirty: boolean) => void;
  saveProcessActive: boolean;
  index: number;
  label?: string;
  control: Control<GoalsAndFocusAreasFormInputs, any>;
  fields: FieldArrayWithId<GoalsAndFocusAreasFormInputs, 'focusAreas', 'focusAreaInternalId'>;
}

const FocusAreaAccordion = React.forwardRef<HTMLTextAreaElement, FocusAreaAccordionProps>(
  (props: FocusAreaAccordionProps, ref) => {
    return (
      <Accordion
        title={props.fields.name}
        id={`focusArea_next_year_${props.fields.id}`}
        open={props.fields.description === '' || props?.fields?.description == null}
      >
        <FocusAreaAccordionInputFields {...props} ref={ref} />
      </Accordion>
    );
  },
);
FocusAreaAccordion.displayName = 'FocusAreaAccordion';
export default FocusAreaAccordion;
