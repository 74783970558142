import React, { FC, lazy, useEffect } from 'react';
import { TextArea } from '@in/component-library';
import { Controller, useForm } from 'react-hook-form';
import { InputMaxLength } from '../../enums/InputMaxLength';

import { RHFRequiredNotEmptyLargeMaxLength } from '../../utils/FormValidations';
import { useAppLocation } from '../../hooks/use-app-location';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('../../components/ThemeSection/ThemeSection'));

interface FormInputs {
  resourceBaseDescription: string;
}

export const AboutMembershipBase: FC = () => {
  const { state } = useAppLocation();
  const { patchMainApplicationAnnualFunding, annualFunding } = useMainApplication();

  const { control, getValues, trigger, reset } = useForm<FormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      resourceBaseDescription: '',
    },
  });

  useEffect(() => {
    if (annualFunding?.resourceBaseComments == null || annualFunding?.resourceBaseComments === '') {
      return;
    }
    reset({ resourceBaseDescription: annualFunding?.resourceBaseComments });
  }, [reset, getValues, annualFunding?.resourceBaseComments]);

  useEffect(() => {
    if (state != null && state.validate) {
      requestAnimationFrame(() => {
        trigger();
      });
    }
  }, [state, trigger]);

  function resourceBaseDescriptionBlurHandler(): void {
    if (getValues('resourceBaseDescription') !== annualFunding?.resourceBaseComments) {
      patchMainApplicationAnnualFunding.mutateAsync({
        resourceBaseComments: getValues('resourceBaseDescription'),
      });
    }
  }
  return (
    <>
      <ThemeSection marginBottom={true}>
        <h2>Om ressursbasen</h2>
        <Controller
          control={control}
          name={'resourceBaseDescription'}
          rules={{
            ...RHFRequiredNotEmptyLargeMaxLength,
          }}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              label="Beskriv aktørsammensetningen med utgangspunkt i femfaktormodellen. Forklar hvorfor dette er de riktige og nødvendige aktørene."
              maxCount={InputMaxLength.large}
              maxLength={InputMaxLength.large}
              rows={3}
              errorMsg={fieldState?.error?.message?.toString()}
              onBlur={() => {
                field.onBlur();
                trigger('resourceBaseDescription').then((isValid) => {
                  if (isValid) {
                    resourceBaseDescriptionBlurHandler();
                  }
                });
              }}
              disableResize={true}
            />
          )}
        />
      </ThemeSection>
    </>
  );
};
