import React from 'react';
import { CustomRouteObject } from '../types';
import ReadyToSubmitReceiptPage from '../../pages/Application/ReadyToSubmitReceiptPage';

export type BaseInfoRouteMenu = {
  operationApplicationReadyToSubmitPage: CustomRouteObject;
  developmentApplicationReadyToSubmitPage: CustomRouteObject;
};

const operationApplicationReadyToSubmitReceipt = `/operation/ready-for-submit/receipt`;
const developmentApplicationReadyToSubmitReceipt = `/development/ready-for-submit/receipt`;
export interface ReadyToSubmitReceiptReceiptRoutePaths {
  operationApplicationReadyToSubmitReceipt: string;
  developmentApplicationReadyToSubmitReceipt: string;
}
export const readyToSubmitReceiptRoutePaths: ReadyToSubmitReceiptReceiptRoutePaths = {
  operationApplicationReadyToSubmitReceipt,
  developmentApplicationReadyToSubmitReceipt,
};

const operationApplicationReadyToSubmitPage: CustomRouteObject = {
  name: 'Driftssøknad er klar for innsending',
  element: <ReadyToSubmitReceiptPage />,
  path: readyToSubmitReceiptRoutePaths.operationApplicationReadyToSubmitReceipt,
  children: [],
};
const developmentApplicationReadyToSubmitPage: CustomRouteObject = {
  name: 'Utviklingssøknad er klar for innsending',
  element: <ReadyToSubmitReceiptPage />,
  path: readyToSubmitReceiptRoutePaths.developmentApplicationReadyToSubmitReceipt,
  children: [],
};

export const readyToSubmitReceiptRouteConfigList: Array<CustomRouteObject> = [
  operationApplicationReadyToSubmitPage,
  developmentApplicationReadyToSubmitPage,
];
export const readyToSubmitReceiptRouteConfigObject: BaseInfoRouteMenu = {
  operationApplicationReadyToSubmitPage,
  developmentApplicationReadyToSubmitPage,
};
