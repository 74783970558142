import React from 'react';

import { Button } from '@in/component-library';

import { useLocation } from 'react-router-dom';

const SessionExpired = () => {
  const location = useLocation();

  const login = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `/api/session/login?redirectUri=${location.pathname}`;
  };

  return (
    <div>
      <div className="landingpage">
        <h1 className="landingpage--heading">Din sesjon er utløpt!</h1>
        <div className="landingpage--text">
          <p>Din sesjon er utløpt, for å få tilgang til søknadene igjen, må du logge inn på nytt.</p>
        </div>

        <Button type="button" onClick={login}>
          Logg inn
        </Button>

        <div className={'landingpage__admin-login'}>
          <p>
            <a href={`/api/session/login?domainHint=commonaad&redirectUri=${location.pathname}`}>
              Ansatt i Innovasjon Norge? Klikk her.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
