//TODO: otherFinancingSourceCode må legges til som type på alle annen finansiering
export const otherFinancingSourceCode: string = 'FS00'; // type 1
export const cashContributionsFromCompaniesCode: string = 'FS01'; // type 1
export const grantsFromInnovationNorwayCode: string = 'FS02';
export const otherPublicFundingCode: string = 'FS04';
export const totalRequestedAmountCostCode: string = 'ET22';

export const financingSourcesIds = {
  FS01: 'aef290d3-d7da-442d-97f5-79cd5f8232d9',
  FS02: '79f47a96-7510-4e7a-b193-ec281c94b818',
  FS03: '3f37bb89-10af-410a-a427-0839d1a1cbd0',
  FS04: '0610b9dd-c1a6-4506-af10-0b880ac62fec',
};
