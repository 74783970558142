import React, { FC, lazy } from 'react';
import { Cost, FinancingSource, ProjectApplicationTypeEnum } from '../../../models';
import { formatAsCurrencyString } from '../../../modules/utils/method-modules/FormatValue';
import { useSumFinancingSources, useTotalCostsWithoutDuplicates } from '../../../hooks/CustomHooks';
import { getCostName } from '../../../utils/Financing';
import { useLocation } from 'react-router-dom';
import { operationApplicationRoutePaths } from '../../../routeConfigs/application/OperationApplicationRouteConfig';
import { Notification } from '@in/component-library/';
import NavigationLinkWithIcon from '../../../components/NavgationLinkWithIcon/NavigationLinkWithIcon';
import useChoices from '../../../hooks/choices/use-choices';
import { Spinner } from '@in/component-library';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import useOperationApplication from '../../../hooks/OperationApplication/use-operation-application';
const DescriptionList = lazy(() => import('../../../components/DescriptionList/DescriptionList'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
export interface FinancingNeedsThemePageProps {
  title?: string;
  ingress?: string;
}
const DescriptionListItem = lazy(() => import('../../../components/DescriptionList/DescriptionListItem'));
const FinancingNeedsThemePage: FC<FinancingNeedsThemePageProps> = ({ title, ingress }) => {
  const { search } = useLocation();
  const { expenseTypes, isFetchingExpenseTypes } = useChoices(
    ProjectApplicationTypeEnum.OperationApplication,
  );
  const { applicationId } = useUrlApplicationId();
  const { plannedActivities, financingSources } = useOperationApplication(applicationId);

  const { financingSourcesTotal } = useSumFinancingSources(financingSources);

  const { allCostsNoDuplicates, totalCostsAmount } = useTotalCostsWithoutDuplicates(plannedActivities);

  function getCostCombinedAsFormattedString(
    costAmount1: number | undefined,
    costAmount2: number | undefined,
  ): string {
    const combinedCost: number =
      (costAmount1 != null ? costAmount1 : 0) + (costAmount2 != null ? costAmount2 : 0);

    return formatAsCurrencyString(combinedCost, ' ');
  }

  const DescriptionListItemsForCosts = () => {
    return (
      <>
        {allCostsNoDuplicates.map((cost: Cost) => {
          return (
            <DescriptionListItem
              key={cost.id}
              name={getCostName(cost.type, expenseTypes)}
              value={getCostCombinedAsFormattedString(cost.applicantAmount, cost.participantsAmount)}
            />
          );
        })}
      </>
    );
  };

  const DescriptionListItemsForFinancing = () => {
    return (
      <>
        {financingSources.map((financingSource: FinancingSource) => {
          return (
            <DescriptionListItem
              key={financingSource.id}
              name={financingSource?.name}
              value={financingSource.amount ? formatAsCurrencyString(financingSource.amount, ' ') : 0}
            />
          );
        })}
      </>
    );
  };
  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <h2>Kostnader og finansiering</h2>
        <p>Her finner dere summen av alle kostnadstyper og finansiering på tvers av alle aktiviteter</p>

        <Notification type="info">
          Trenger dere å gjøre endringer i registrerte aktiviteter, kan det gjøres her:
          <br />
          <NavigationLinkWithIcon to={operationApplicationRoutePaths.planedActivitiesNextYear + search}>
            Gå til Planlagte aktiviteter
          </NavigationLinkWithIcon>
        </Notification>

        <DescriptionList title="Kostnader">
          {isFetchingExpenseTypes ? (
            <Spinner text="laster kostnadstyper..." />
          ) : (
            <DescriptionListItemsForCosts />
          )}

          <DescriptionListItem
            name="Total kostnader"
            value={formatAsCurrencyString(totalCostsAmount, ' ')}
            borderBottom={true}
          />
        </DescriptionList>
        <DescriptionList title="Finansiering">
          <DescriptionListItemsForFinancing />

          <DescriptionListItem
            name="Sum finansiering"
            value={formatAsCurrencyString(financingSourcesTotal, ' ')}
            nameInBold={true}
            doubleBorderBottom={true}
          />
        </DescriptionList>
      </ThemeSection>
    </div>
  );
};

export default FinancingNeedsThemePage;
