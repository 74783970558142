import React from 'react';
import { FaIcon, Link as CLLink } from '@in/component-library';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { Link, LinkProps } from 'react-router-dom';
import css from './NavigationLinkWithIcon.module.scss';
import cn from 'classnames';

export interface NavigationLinkWithIconProps extends LinkProps {
  isExternalLink?: boolean;
}

const NavigationLinkWithIcon = React.forwardRef<any, NavigationLinkWithIconProps>(
  ({ children, isExternalLink = false, ...rest }: NavigationLinkWithIconProps, ref) => {
    let pathName = '';
    if (typeof rest.to !== 'string' && Object.hasOwn(rest.to, 'pathname')) {
      pathName = rest.to?.pathname ? rest.to.pathname : '';
    }
    return (
      <div className={css['navigation-link__wrapper']}>
        {isExternalLink && (
          <CLLink
            className={cn(css['navigation-link'], 'link-black')}
            href={pathName}
            rel="noreferrer"
            target={rest?.target}
          >
            {children}
          </CLLink>
        )}
        {!isExternalLink && (
          <Link {...rest} className={cn(css['navigation-link'], 'link-black')} ref={ref && null}>
            {children}
          </Link>
        )}

        <FaIcon icon={solidIcons.faArrowCircleRight} size="sm" />
      </div>
    );
  },
);
NavigationLinkWithIcon.displayName = 'NavigationLinkWithIcon';
export default NavigationLinkWithIcon;
