import { InputMaxLength } from '../enums/InputMaxLength';

export function RHFisEmpty(value: string, errorMessage = 'Feltet kan ikke være blankt') {
  if (!value.trim().length) {
    return errorMessage;
  }
  return true;
}

export const RHFRequiredAndNotEmpty = {
  required: { value: true, message: 'Feltet er påkrevd' },
  validate: {
    isEmpty: (v) => RHFisEmpty(v, 'Feltet kan ikke være blankt'),
  },
};

export const RHFRequiredNotEmptyMediumMaxLength = {
  required: { value: true, message: 'Feltet er påkrevd' },
  validate: {
    isEmpty: (v) => RHFisEmpty(v, 'Feltet kan ikke være blankt'),
  },
  maxLength: { value: InputMaxLength.medium, message: 'Teksten er for lang' },
};

export const RHFRequiredNotEmptyLargeMaxLength = {
  required: { value: true, message: 'Feltet er påkrevd' },
  validate: {
    isEmpty: (v) => RHFisEmpty(v, 'Feltet kan ikke være blankt'),
  },
  maxLength: { value: InputMaxLength.large, message: 'Teksten er for lang' },
};

export const RHFRequiredNotEmptyLargerMaxLength = {
  required: { value: true, message: 'Feltet er påkrevd' },
  validate: {
    isEmpty: (v) => RHFisEmpty(v, 'Feltet kan ikke være blankt'),
  },
  maxLength: { value: InputMaxLength.larger, message: 'Teksten er for lang' },
};

export const RHFRequiredNotEmptyExtraLargeMaxLength = {
  required: { value: true, message: 'Feltet er påkrevd' },
  validate: {
    isEmpty: (v) => RHFisEmpty(v, 'Feltet kan ikke være blankt'),
  },
  maxLength: { value: InputMaxLength.extraLarge, message: 'Teksten er for lang' },
};
