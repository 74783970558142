import { DevelopmentApplicationOverviewDto } from '../../models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchObjectArray } from '../../utils/ArrayUtils';

interface ConfirmState {
  selectedApplicationsForSubmit: DevelopmentApplicationOverviewDto[];
  selectedApplicationDraftIds: string[];
}
export const initialConfirmState: ConfirmState = {
  selectedApplicationsForSubmit: [],
  selectedApplicationDraftIds: [],
};

const confirmSlice = createSlice({
  initialState: initialConfirmState,
  name: 'confirm',

  reducers: {
    addApplication(state: ConfirmState, action: PayloadAction<DevelopmentApplicationOverviewDto>) {
      const index = searchObjectArray('id', action.payload.id, state?.selectedApplicationsForSubmit);
      if (index === -1) {
        state.selectedApplicationsForSubmit?.push(action.payload);
      }
    },
    setSelectedApplicationsForSubmit(
      state: ConfirmState,
      action: PayloadAction<DevelopmentApplicationOverviewDto[]>,
    ) {
      state.selectedApplicationsForSubmit = action.payload;
    },

    addApplicationIdToSelected(state: ConfirmState, action: PayloadAction<string>) {
      const index = state?.selectedApplicationDraftIds.indexOf(action.payload);
      if (index === -1) {
        state.selectedApplicationDraftIds?.push(action.payload);
      }
    },
    setSelectedApplicationDraftIds(state: ConfirmState, action: PayloadAction<string[]>) {
      state.selectedApplicationDraftIds = action.payload;
    },
    removeApplicationIdFromSelected(state: ConfirmState, action: PayloadAction<string>) {
      const index = state?.selectedApplicationDraftIds.indexOf(action.payload);

      if (index !== -1) {
        state?.selectedApplicationDraftIds?.splice(index, 1);
      }
    },
    removeApplication(state: ConfirmState, action: PayloadAction<string>) {
      const index = searchObjectArray('id', action.payload, state?.selectedApplicationsForSubmit);

      if (index !== -1) {
        state?.selectedApplicationsForSubmit?.splice(index, 1);
      }
    },
    clearSelectedApplicationIds(state: ConfirmState) {
      state.selectedApplicationDraftIds = [];
    },
    clearSelectedApplicationsForSubmit(state: ConfirmState) {
      state.selectedApplicationsForSubmit = [];
    },
  },
});

export const {
  addApplication,
  setSelectedApplicationsForSubmit,
  addApplicationIdToSelected,
  setSelectedApplicationDraftIds,
  removeApplication,
  removeApplicationIdFromSelected,
  clearSelectedApplicationIds,
  clearSelectedApplicationsForSubmit,
} = confirmSlice.actions;

export default confirmSlice.reducer;
