import React, { FC, lazy } from 'react';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export const ClustersVision: FC = () => {
  const { clusterKeyInformation } = useMainApplication();

  return (
    <>
      <ThemeSection marginBottom={true}>
        <h2>Legg til mål og fokusområder</h2>

        <p>Klyngens visjon, misjon, mål og fokusområder blir hentet fra flerbedriftsportalen.</p>

        <h3>Visjon</h3>

        {clusterKeyInformation?.vision != null && <p>{clusterKeyInformation.vision}</p>}

        <h3>Misjon</h3>

        {clusterKeyInformation?.purpose != null && <p>{clusterKeyInformation.purpose}</p>}
      </ThemeSection>
    </>
  );
};
