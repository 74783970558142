import React, { lazy, useState } from 'react';
import { Button, ButtonVariant, Theme } from '@in/component-library';
import useCreationInformation from '../../hooks/creationInformation/use-creation-information';
import DialogContainer from '../DialogContainer/DialogContainer';
const NewDevelopmentApplicationModalContent = lazy(() => import('./NewDevelopmentApplicationModalContent'));

const NewDevelopmentApplicationModal = () => {
  const [showCreateApplicationModal, setShowCreateApplicationModal] = useState<boolean>(false);
  const { applicationCreationInformation } = useCreationInformation();

  return (
    <>
      <Button
        theme={Theme.Neutral}
        variant={ButtonVariant.Outlined}
        onClick={() => {
          setShowCreateApplicationModal(true);
        }}
      >
        Nytt prosjekt
      </Button>
      <DialogContainer
        title={'Velg type utviklingsprosjekt'}
        onClose={() => setShowCreateApplicationModal(false)}
        show={showCreateApplicationModal}
      >
        <NewDevelopmentApplicationModalContent
          objectives={applicationCreationInformation?.development?.objectives}
          onClose={() => {
            setShowCreateApplicationModal(false);
          }}
        />
      </DialogContainer>
    </>
  );
};
export default NewDevelopmentApplicationModal;
