import React from 'react';
import { IconButton, FaIcons } from '@in/component-library';
import { SubmittedFormMetadata } from '@src/models';
import { formatDateToNOString } from 'src/modules/utils/method-modules/FormatValue';
import css from './ApplicationLandingPage.module.scss';
import { saveFileOnUserDevice } from '@src/utils/AttachmentHelper';
import useArchivedApplications from '../../hooks/archivedApplications/use-archived-applications';
import { toast } from 'react-toastify';
import CardContainer from '@src/ApplicationShared/CardContainer/CardContainer';

export interface ArchiveGroup {
  year: string;
  documents: SubmittedFormMetadata[];
}

const ArchiveTabContent = React.forwardRef<HTMLDivElement, React.HtmlHTMLAttributes<HTMLDivElement>>(
  ({ ...prop }, ref) => {
    const {
      archivedApplications,
      getArchivedApplicationAsPdfMutation,
      getArchivedApplicationAsDocxMutation,
    } = useArchivedApplications();

    return (
      <div {...prop} ref={ref}>
        <div className={css['application-landing-page__list-group']}>
          <div className={css['application-landing-page__list-group-header']}>
            <h2 className={css['application-landing-page__list-header']}>Innsendte søknader</h2>
          </div>

          <CardContainer>
            {archivedApplications
              .sort((a, b) => {
                const dateA = a?.modifiedOn ? new Date(a.modifiedOn).getTime() : -1;
                const dateB = b?.modifiedOn ? new Date(b.modifiedOn).getTime() : -1;
                return dateB - dateA;
              })
              .map((archivedApplication) => {
                const submittedDate =
                  archivedApplication?.modifiedOn != null
                    ? formatDateToNOString(
                        archivedApplication?.modifiedOn,
                        false,
                        '{date}.{month}.{year}: ',
                        true,
                      )
                    : '';
                return (
                  <div key={archivedApplication.id} className={css['application-landing-page__archive-item']}>
                    {submittedDate}
                    {archivedApplication.title}
                    <IconButton
                      title={`Last ned ${archivedApplication.title} som pdf`}
                      iconName={FaIcons.FilePdfLight}
                      iconSize={'xl'}
                      onClick={async () => {
                        let applicationId = '';
                        if (archivedApplication.id) {
                          applicationId = archivedApplication.id;
                        }

                        try {
                          if (applicationId !== '') {
                            const response = await getArchivedApplicationAsPdfMutation.mutateAsync(
                              applicationId,
                            );

                            if (response?.data != null) {
                              saveFileOnUserDevice(response.data, response.fileName);
                            } else {
                              toast.error('Klarte ikke laste ned dokument.');
                            }
                          }
                        } catch (error) {
                          toast.error(
                            'Fikk ikke kontakt med Websøknad, prøv igjen litt senere, dersom problemet vedvarer, kontakt din klyngerådgiver',
                          );
                        }
                      }}
                    />

                    <IconButton
                      title={`Last ned ${archivedApplication.title} som word dokument`}
                      iconName={FaIcons.FileWordLight}
                      iconSize={'xl'}
                      className={css['application-landing-page__archive-list-link']}
                      aria-label={`Last ned ${archivedApplication.title} som word dokument`}
                      theme="none"
                      size="small"
                      onClick={async () => {
                        let applicationId = '';
                        if (archivedApplication.id) {
                          applicationId = archivedApplication.id;
                        }

                        try {
                          if (applicationId !== '') {
                            const response = await getArchivedApplicationAsDocxMutation.mutateAsync(
                              applicationId,
                            );

                            if (response?.data != null) {
                              saveFileOnUserDevice(response.data, response.fileName);
                            } else {
                              toast.error('Klarte ikke laste ned dokument.');
                            }
                          }
                        } catch (error) {
                          toast.error(
                            'Fikk ikke kontakt med Websøknad, prøv igjen litt senere, dersom problemet vedvarer, kontakt din klyngerådgiver',
                          );
                        }
                      }}
                    />
                  </div>
                );
              })}
          </CardContainer>
        </div>
      </div>
    );
  },
);
ArchiveTabContent.displayName = 'ArchiveTabContent';
export default ArchiveTabContent;
