import { LabelValueDto } from '../models';

export function getCostName(costType: string | undefined, expenseTypes: Array<LabelValueDto>): string {
  if (costType != null) {
    const name: string | undefined = expenseTypes.find((item: LabelValueDto) => {
      return item?.value?.toString() === costType?.toString();
    })?.label;
    return name != null ? name : costType;
  }
  return '';
}
