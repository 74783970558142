import React, { FC, lazy, Suspense } from 'react';
import css from './ApplicationLandingPage.module.scss';
import { Section, LayoutGrid, Spinner, Notification } from '@in/component-library';
import { useDocTitle } from '@hooks/CustomHooks';
import ArchiveTabContent from './ArchiveTabContent';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
import OperationContent from '@src/pages/Application/OperationContent';
import DevelopmentProjectContent from '@src/pages/Application/DevelopmentProjectContent';
import LegalOwnerNotification from '@src/components/LegalOwnerNotification/LegalOwnerNotification';

const ApplicationSummaryBox = lazy(
  () => import('src/components/ApplicationSummaryBox/ApplicationSummaryBox'),
);
const Page = lazy(() => import('src/components/Page/Page'));
export enum LandingPageTabs {
  ACTIVE_APPLICATIONS,
  APPLICATIONS_READY_FOR_SUBMIT,
  ARCHIVED_APPLICATIONS,
}
export const ApplicationLandingPage: FC = () => {
  useDocTitle('Søknader');

  const { operationsApplication, createApplicationSetup, developmentApplications } = useMainApplication();
  return (
    <Page paddingTop={true}>
      <Section collapse={true}>
        <div className={css['application-landing-page__header']}>
          <h1>Søknader</h1>
        </div>
      </Section>
      <Section>
        <LegalOwnerNotification />
      </Section>
      <Section>
        <Notification type="error">
          <p>Vi gjør tekniske endringer i forbindelse med nytt saksbehandlingssystem.</p>
          <p>Vi har derfor deaktivert innsending av søknader inntil dette er på plass.</p>
          <p>Det er kun innsending som er deaktivert, så søknaden kan fortsatt fylles ut.</p>
          </Notification>
      </Section>
      { createApplicationSetup?.submitInformation?.year === 2026 && (
        <Section>
          <Notification type="info">
            <h3>Søknad om klyngedrift 2026</h3>
            <p>Dere fyller nå ut en tilpasset finansieringssøknad for 2026. Den skal ikke inneholde noen utviklingsprosjekter.</p>
            <p>
              Alminnelig finansieringssøknad for 2025 blir tilgjengelig etter at denne søknaden er sendt inn,
              <br /> og dere vil kunne søke utviklingsprosjekter som vanlig da. 
            </p>
          </Notification>
        </Section>
      )}
      <Section>
        { (createApplicationSetup != undefined ) && ( 
          <h3>{createApplicationSetup?.title}</h3>
        )
        }
        <div className={css['application-landing-page']}>
          <LayoutGrid>
            <div className={css['application-landing-page__application-list']}>
              <Suspense fallback={<Spinner text="Laster data..." />}>
                <div className={css['application-landing-page__application-list-container']}>
                  <OperationContent operationsApplication={operationsApplication} />

                  <DevelopmentProjectContent developmentApplications={developmentApplications} />

                  <ArchiveTabContent />
                </div>
              </Suspense>
            </div>
            <ApplicationSummaryBox />
          </LayoutGrid>
        </div>
      </Section>
    </Page>
  );
};
