import React, { FC, lazy, PropsWithChildren, useState } from 'react';
import { Button, ButtonVariant, ErrorMessage, FaIcons, Theme } from '@in/component-library';
import { PlannedActivity } from 'src/models';
import useDevelopmentApplication from '../../../hooks/developmentApplication/use-development-application';
import { useUrlApplicationId } from '../../../hooks/use-url-application-id';
import { useAppLocation } from '../../../hooks/use-app-location';
import { toast } from 'react-toastify';
import DialogContainer from '@src/components/DialogContainer/DialogContainer';
const ActivityListItem = lazy(() => import('src/components/Activity/ActivityListItem'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ActivityModal = lazy(() => import('./ActivityModal'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export interface DevelopmentActivitiesInProjectProps {
  title?: string;
  ingress?: string;
}

const DevelopmentActivities: FC<DevelopmentActivitiesInProjectProps> = ({
  title,
  ingress,
}: PropsWithChildren<DevelopmentActivitiesInProjectProps>) => {
  const location = useAppLocation();

  const [showNewActivityModal, setShowNewActivityModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<PlannedActivity | undefined>();

  const { applicationId } = useUrlApplicationId();
  const { activities, putDevelopmentActivityMutation, deleteDevelopmentActivityMutation } =
    useDevelopmentApplication(applicationId);

  const closeHandler = () => {
    setCurrentActivity(undefined);
    setShowNewActivityModal(false);
  };

  return (
    <>
      <div>
        <ThemeSection>
          <ApplicationHeader ingress={ingress} title={title} />
        </ThemeSection>

        <ThemeSection>
          <h2>Prosjektaktiviteter</h2>

          <p>Her skal dere legge til og beskrive aktivitetene dere tenker å gjennomføre i prosjektet.</p>
          <p>
            Dersom den samme aktiviteten skal gjentas i prosjektet kan disse grupperes og beskrives samtidig.
            Aktivitetene vil listes opp og utgjøre en framdriftsplan.
          </p>
        </ThemeSection>

        <ThemeSection marginBottom>
          {activities.map((activity: PlannedActivity) => {
            return (
              <ActivityListItem
                key={activity.id}
                title={activity?.name || 'no-name'}
                onClick={() => {
                  setCurrentActivity(activity);
                  setShowNewActivityModal(true);
                }}
              />
            );
          })}
        </ThemeSection>

        <ThemeSection>
          <Button
            type={'button'}
            theme={Theme.Neutral}
            variant={ButtonVariant.Link}
            iconName={FaIcons.Plus}
            onClick={() => {
              setShowNewActivityModal(true);
            }}
          >
            Legg til en ny prosjektaktivitet
          </Button>

          {location?.state?.validate && activities.length === 0 && (
            <ErrorMessage errorMessage={'Du må legge til minst 1 aktivitet'} />
          )}
        </ThemeSection>
      </div>

      <DialogContainer
        title={'Om prosjektaktiviteten'}
        onClose={() => closeHandler()}
        show={showNewActivityModal}
      >
        <ActivityModal
          defaultValues={currentActivity}
          onSubmit={async (plannedActivity) => {
            if (plannedActivity.id != null) {
              try {
                await putDevelopmentActivityMutation.mutateAsync({
                  planedActivityId: plannedActivity.id,
                  planedActivity: plannedActivity,
                });
              } catch (e) {
                toast.error('Klarte ikke lagre aktivitet');
              }
            }

            closeHandler();
          }}
          onDelete={async (id) => {
            try {
              await deleteDevelopmentActivityMutation.mutateAsync(id);
              closeHandler();
            } catch (e) {
              toast.error('Klarte ikke slette aktivitet');
            }
          }}
          onClose={() => {
            closeHandler();
          }}
          key={currentActivity?.id}
        />
      </DialogContainer>
    </>
  );
};
export default DevelopmentActivities;
