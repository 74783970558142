import { useLocation } from 'react-router-dom';

export const useUrlApplicationId = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const applicationId: string | null = params.get('id');

  return { applicationId };
};
