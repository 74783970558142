import React, { FC, lazy } from 'react';
import { Checkbox } from '@in/component-library';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export const GoodBusinesspractice: FC = () => {
  const { patchMainApplicationAnnualFunding, annualFunding } = useMainApplication();

  return (
    <>
      <ThemeSection marginBottom={true}>
        <h2>God forretningsskikk</h2>
        <p>
          Innovasjon Norge skal bidra til å løfte de prosjektene som har størst nytte for samfunnet. Derfor er
          god forretningsskikk i bedriften og bærekraft i prosjektet viktig når vi vurderer søknader.
        </p>
        <a
          href="https://www.innovasjonnorge.no/no/verktoy/barekraft-og-etikk/ansvarlig-naeringsliv/"
          target="_blank"
          rel="noreferrer"
        >
          Les mer om dette her.
        </a>
      </ThemeSection>
      <ThemeSection>
        <Checkbox
          name="goodBusinessPracticeConsents"
          options={[
            {
              value: 'yes',
              text: 'Jeg bekrefter at klyngen har en god forretningsskikk i tråd med prinsippene for ansvarlig næringsliv?',
              selected:
                annualFunding?.goodBusinessPracticeConsent != null &&
                annualFunding.goodBusinessPracticeConsent,
              disabled: patchMainApplicationAnnualFunding.isLoading,
            },
          ]}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const { checked } = e.currentTarget;
            patchMainApplicationAnnualFunding.mutateAsync({
              goodBusinessPracticeConsent: checked,
            });
          }}
        />
      </ThemeSection>
    </>
  );
};
