import React, { FC, lazy } from 'react';
import { GoodBusinesspractice } from '../../themePageSections/GoodBusinesspractice';
import { ProjectsStarted } from '../../themePageSections/ProjectsStarted';
import { KeyInformation } from '../../themePageSections/KeyInformation';
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
export interface ClusterThemePageProps {
  title?: string;
  ingress?: string;
}
const ClusterThemePage: FC<ClusterThemePageProps> = ({ title, ingress }) => {
  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <KeyInformation />

      <GoodBusinesspractice />

      <ProjectsStarted />
    </div>
  );
};
export default ClusterThemePage;
