import React, { FC, lazy, useEffect, useState } from 'react';
import { Checkbox } from '@in/component-library';
import { useAppLocation } from '../../hooks/use-app-location';
import { useUrlApplicationId } from '../../hooks/use-url-application-id';
import useOperationApplication from '../../hooks/OperationApplication/use-operation-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));

export const ProjectsStarted: FC = () => {
  const location = useAppLocation();
  const { applicationId } = useUrlApplicationId();
  const { activitiesNotStarted = false, patchOperationApplicationMutation } =
    useOperationApplication(applicationId);

  const [activitiesNotStartedErrorMessage, setActivitiesNotStartedErrorMessage] = useState<string>('');

  useEffect(() => {
    if (location?.state != null && location?.state.validate) {
      requestAnimationFrame(() => {
        setActivitiesNotStartedErrorMessage(activitiesNotStarted ? '' : 'Prosjektene kan ikke være påbegynt');
      });
    }
  }, [location, activitiesNotStarted]);

  return (
    <>
      <ThemeSection marginBottom={true}>
        <h2>Finansieringen skal dekke fremtidige utgifter</h2>
        <p>
          Pengene dere får fra Innovasjon Norge skal brukes til fremtidige kostnader, ikke til å dekke
          utgifter dere allerede har hatt. Derfor er det viktig at dere søker om finansiering FØR dere starter
          aktivitetene som beskrives i søknaden.
        </p>
      </ThemeSection>
      <ThemeSection marginBottom={true}>
        <Checkbox
          name="activitiesNotStarted"
          errorMsg={activitiesNotStartedErrorMessage}
          options={[
            {
              value: 'yes',
              text: 'Jeg har lest og godtar dette vilkåret',
              selected: activitiesNotStarted,
            },
          ]}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const { checked } = e.currentTarget;
            patchOperationApplicationMutation.mutateAsync({
              activitiesNotStarted: checked,
            });
          }}
        />
      </ThemeSection>
    </>
  );
};
