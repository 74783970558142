import React, { FC, lazy, PropsWithChildren, useState } from 'react';
import { Button, ButtonVariant, FaIcons, Theme } from '@in/component-library';
import { IconPosition } from '@in/component-library/';
import { CheckboxOptionProps } from '@in/component-library/dist/components/checkbox/Checkbox';
import DialogContainer from '../DialogContainer/DialogContainer';
const SelectAndAddModalContent = lazy(() => import('./SelectAndAddModalContent'));

export interface SelectAndAddProps {
  buttonText: string;
  title: string;
  options: CheckboxOptionProps[];
  value: CheckboxOptionProps[];
  onSave: (data: string[]) => void;
  children?: React.ReactNode;
}

const SelectAndAdd: FC<SelectAndAddProps> = ({
  buttonText,
  title,
  options,
  value,
  onSave,
  ...rest
}: PropsWithChildren<SelectAndAddProps>) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button
        theme={Theme.Neutral}
        variant={ButtonVariant.Link}
        iconPosition={IconPosition.Left}
        iconName={FaIcons.PlusLight}
        type="button"
        onClick={() => {
          setShow(!show);
        }}
      >
        {buttonText}
      </Button>

      <DialogContainer title={title} onClose={() => setShow(false)} show={show}>
        <SelectAndAddModalContent
          {...rest}
          options={options}
          onSave={(data) => {
            setShow(false);
            onSave(data);
          }}
          value={value}
        />
      </DialogContainer>
    </>
  );
};

export default SelectAndAdd;
