import { ApplicationIdList, ApplicationsClient } from '../../models';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

export default function usePdf() {
  const [applicationClient] = useState(new ApplicationsClient());

  const getApplicationsAsPdf = useMutation((applicationIdList: ApplicationIdList) => {
    return applicationClient.getApplicationsAsPdf(applicationIdList);
  });

  const getDevelopmentApplicationAsPdf = useMutation((id: string) => {
    return applicationClient.getDevelopmentApplicationAsPdf(id);
  });
  const getOperationApplicationAsPdf = useMutation((id: string) => {
    return applicationClient.getOperationApplicationAsPdf(id);
  });
  return {
    getApplicationsAsPdf,
    getDevelopmentApplicationAsPdf,
    getOperationApplicationAsPdf,
  };
}
