import React, { FC, lazy } from 'react';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
const DescriptionList = lazy(() => import('../../components/DescriptionList/DescriptionList'));
const DescriptionListItem = lazy(() => import('../../components/DescriptionList/DescriptionListItem'));

export const MembersListKeyNumbers: FC = () => {
  const { clusterKeyInformation } = useMainApplication();

  return (
    <ThemeSection>
      <h3>Medlemmer</h3>
      <DescriptionList>
        <DescriptionListItem
          name="Totalt antall medlemmer:"
          value={clusterKeyInformation?.membersCount != null ? clusterKeyInformation.membersCount : '0'}
        />
      </DescriptionList>
    </ThemeSection>
  );
};
