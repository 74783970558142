import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, FileItem, FileUpload } from '@in/component-library';
import css from './ConfirmCostsAndAttachments.module.scss';
import { formatAsCurrencyString } from '../../modules/utils/method-modules/FormatValue';
import { getFilesFromAttachmentsByInputFieldName } from '../../utils/AttachmentHelper';
import { SoppCost } from '../../models';
import generateGuid from '../../utils/Guid';
import { sumPropertiesOnType } from '../../hooks/CustomHooks';
import useAttachments from '../../hooks/attachment/use-attachements';
import useApplicationsExpenseOverview from '../../hooks/applicationsExpenseOverview/use-applications-expense-overview';
import useMainApplication from '../../hooks/mainApplication/use-main-application';
import { CREATE_APPLICATION_SETUP } from '../../constants/hook-keys';

const ConfirmCostsAndAttachments: FC = () => {
  const [totalSum, setTotalSUm] = useState<number>(0);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { applicationsExpenseOverview } = useApplicationsExpenseOverview();

  const operatingBudgetFileUploadFieldName = `operatingBudgetFileUpload`;
  const annualAccountsFileUploadFieldName = `annualAccountsFileUpload`;
  const { attachments, mainApplicationId } = useMainApplication();
  const { uploadFile, deleteFile } = useAttachments([CREATE_APPLICATION_SETUP], mainApplicationId);

  useEffect(() => {
    if (applicationsExpenseOverview?.expenseCategories != null) {
      const test = applicationsExpenseOverview.expenseCategories.reduce<SoppCost[]>(
        (item, soppExpenseCategory) => {
          const costs = soppExpenseCategory?.costs != null ? soppExpenseCategory?.costs : [];
          return [...item, ...costs];
        },
        [],
      );

      const total = sumPropertiesOnType(test, ['applicantAmount', 'participantsAmount']);

      setTotalSUm(total);
    }
  }, [applicationsExpenseOverview?.expenseCategories]);

  const operatingBudgetFiles = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, operatingBudgetFileUploadFieldName);
  }, [attachments, operatingBudgetFileUploadFieldName]);

  const annualAccountsFiles = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, annualAccountsFileUploadFieldName);
  }, [attachments, annualAccountsFileUploadFieldName]);

  return (
    <>
      <h2>Oppsummering per støttekategori</h2>
      <p className={css['cost-accordion__ingress']}>{applicationsExpenseOverview?.description}</p>
      <div className={css['cost-accordion']}>
        {applicationsExpenseOverview?.expenseCategories != null &&
          applicationsExpenseOverview.expenseCategories.map((item) => {
            return (
              <Accordion
                id={item.id != null ? item.id : generateGuid()}
                key={item.id}
                title={item.expenseCategory}
              >
                <p>{item.description}</p>
                {item.costs != null && item.costs.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th>Kostnadstype</th>
                        <th>Deltagerbedrift</th>
                        <th>Søkerbedrift</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.costs.map((costType) => {
                        return (
                          <tr key={costType.id}>
                            <td>{costType.name}</td>
                            <td>
                              {costType.participantsAmount != null &&
                                formatAsCurrencyString(costType.participantsAmount, ' ', '', 0, 'kr ')}
                            </td>
                            <td>
                              {costType.applicantAmount != null &&
                                formatAsCurrencyString(costType.applicantAmount, ' ', '', 0, 'kr ')}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </Accordion>
            );
          })}

        <div className={css['cost-accordion__total-amount']}>
          <span>Totalt kostnader</span>
          <span>{formatAsCurrencyString(totalSum, ' ', '', 0, 'kr ')}</span>
        </div>
      </div>

      <h2>Driftsbudsjett (obligatorisk)</h2>
      <FileUpload
        inputRef={fileUploadRef}
        files={operatingBudgetFiles}
        uploadCallback={uploadFile}
        deletionCallback={deleteFile}
        label="Legg ved et driftsbudsjett for klyngens samlede aktivitet for kommende år."
        name={operatingBudgetFileUploadFieldName}
        fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        requirementsText="Tillatte filtyper: PDF, Excel, Word"
      />

      <h2>Sist oppdaterte regnskap til søker (obligatorisk)</h2>
      <FileUpload
        id="annualAccountsFiles"
        files={annualAccountsFiles}
        uploadCallback={uploadFile}
        deletionCallback={deleteFile}
        label="Legg ved et delårsregnskap eller et foreløpig regnskap for kontraktspart."
        name={annualAccountsFileUploadFieldName}
        fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        requirementsText="Tillatte filtyper: PDF, Excel, Word"
      />
    </>
  );
};
export default ConfirmCostsAndAttachments;
