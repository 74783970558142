import React, { FC, lazy, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { LabelValueDto, SocialImpact } from '@src/models';
import { ConfirmationBox, FileItem, FileUpload, Spinner, TextArea, Theme } from '@in/component-library';
import { getFilesFromAttachmentsByInputFieldName } from '@src/utils/AttachmentHelper';
import { InputMaxLength } from '@src/enums/InputMaxLength';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { RHFRequiredNotEmptyMediumMaxLength } from '@src/utils/FormValidations';
import { ErrorMessage } from '@in/component-library/';
import { useAppLocation } from '@hooks/use-app-location';
import useChoices from '../../hooks/choices/use-choices';
import { useUrlApplicationId } from '@hooks/use-url-application-id';
import useOperationApplication from '../../hooks/OperationApplication/use-operation-application';
import useAttachments from '../../hooks/attachment/use-attachements';
import { OPERATION_APPLICATION } from '@src/constants/hook-keys';
const SocialAndEnvironmentalEffectAccordion = lazy(
  () => import('src/components/SocialAndEnvironmentalEffectAccordion/SocialAndEnvironmentalEffectAccordion'),
);
const SelectAndAdd = lazy(() => import('src/components/SelectAndAdd/SelectAndAdd'));
const ToggleGroup = lazy(() => import('src/components/ToggleGroup/ToggleGroup'));
const ThemeSection = lazy(() => import('src/components/ThemeSection/ThemeSection'));
const ApplicationHeader = lazy(() => import('src/ApplicationShared/ApplicationHeader/ApplicationHeader'));

export interface SocietalEffectsProps {
  title?: string;
  ingress?: string;
}

export interface SocietalEffectsFormInputs {
  notApplicableComment: string;
  socialImpacts: SocialImpact[];
  notApplicable: boolean;
}
const SocietalEffects: FC<SocietalEffectsProps> = ({
  title,
  ingress,
}: PropsWithChildren<SocietalEffectsProps>) => {
  const operationFileUploadFieldName = 'operationSocietalEffectsAttachment';
  const { state } = useAppLocation();
  const { applicationId } = useUrlApplicationId();

  const {
    impacts,
    operationApplication,
    notApplicableComment,
    attachments,
    patchOperationApplicationMutation,
    putOperationSocialImpactsMutation,
    deleteOperationSocietalEnvironmentalImpact,
  } = useOperationApplication(applicationId);

  const ref = useRef<HTMLDialogElement>(null);

  const { societalEffects } = useChoices();

  const { uploadFile, deleteFile } = useAttachments([OPERATION_APPLICATION, applicationId!], applicationId);

  const attachmentLocal = useMemo<FileItem[]>(() => {
    return getFilesFromAttachmentsByInputFieldName(attachments, operationFileUploadFieldName);
  }, [attachments, operationFileUploadFieldName]);

  const [notApplicableWarning, setNotApplicableWarning] = useState<{
    message: string;
    notApplicable: boolean;
  }>({ message: '', notApplicable: false });

  const { control, reset, getValues, trigger, setValue } = useForm<SocietalEffectsFormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      socialImpacts: impacts,
      notApplicable: operationApplication?.impact?.notApplicable ?? false,
      notApplicableComment: operationApplication?.impact?.notApplicableComment ?? '',
    },
  });
  const { fields, remove } = useFieldArray<
    SocietalEffectsFormInputs,
    'socialImpacts',
    'socialImpactsInternalId'
  >({
    control,
    name: 'socialImpacts',
    keyName: 'socialImpactsInternalId',
  });

  const { notApplicable = false } = useWatch({ control });

  const [hasNoticeableEffectNotRelevant, setHasNoticeableEffectNotRelevant] = useState<boolean>(false);

  useEffect(() => {
    if (notApplicable == null) {
      return;
    }
    setHasNoticeableEffectNotRelevant(notApplicable ?? false);
  }, [notApplicable]);

  useEffect(() => {
    if (impacts?.length === 0) {
      return;
    }

    reset({
      ...getValues(),
      socialImpacts: [...impacts],
    });
  }, [impacts, reset, getValues]);

  useEffect(() => {
    if (notApplicableComment != null) {
      reset({
        ...getValues(),
        notApplicableComment: notApplicableComment,
      });
    }
  }, [notApplicableComment, reset, getValues]);

  useEffect(() => {
    const validatePage = async () => {
      if (notApplicable) {
        await trigger();
      }
    };

    if (state != null && state.validate) {
      validatePage();
    }
  }, [state, notApplicable, trigger]);

  const ImpactErrorMessage = () => {
    if (state != null && state.validate) {
      if (impacts.length === 0) {
        return <ErrorMessage errorMessage={'Du må legge til minst én effekt'} />;
      }
    }
    return <></>;
  };

  return (
    <div>
      <ThemeSection>
        <ApplicationHeader ingress={ingress} title={title} />
      </ThemeSection>
      <ThemeSection marginBottom={false}>
        <h2>Klyngearbeidets effekt</h2>
        <p>Er positiv samfunns- eller miljøeffekt en vesentlig del av arbeidet i klyngen?</p>

        <ToggleGroup
          disabled={false}
          toggleButtons={[
            { pressed: !hasNoticeableEffectNotRelevant, text: 'Ja', value: 'yes' },
            { pressed: hasNoticeableEffectNotRelevant, text: 'Nei', value: 'no' },
          ]}
          onChange={async (value) => {
            let notApplicableFromValue = true;
            if (value === 'yes') {
              notApplicableFromValue = false;
            } else if (value === 'no') {
              notApplicableFromValue = true;
            }

            if (!notApplicableFromValue && getValues('notApplicableComment')) {
              //Beskriv hvorfor dette ikke er aktuelt for dere?
              ref?.current?.showModal();
              setNotApplicableWarning({
                message:
                  'Din beskrivelse av hvorfor dette ikke er aktuelt for dere vil bli slettet dersom du velger ja',
                notApplicable: notApplicableFromValue,
              });
            } else if (notApplicableFromValue && impacts.length > 0) {
              ref?.current?.showModal();
              setNotApplicableWarning({
                message:
                  'Alle samfunns- og miljøeffekter i dette prosjektet blir slettet dersom du velger ja.',
                notApplicable: notApplicableFromValue,
              });
            } else {
              await patchOperationApplicationMutation.mutateAsync({
                impact: {
                  notApplicable: notApplicableFromValue,
                },
              });
              setValue('notApplicable', notApplicableFromValue);
            }

            // setHasNoticeableEffectNotRelevant(notApplicable);
            //
            // if (impacts.length > 0) {
            //   setShowDeleteWarning(notApplicable);
            // } else {
            //   patchOperationApplicationMutation.mutateAsync({ impact: { notApplicable: notApplicable } });
            //
            //   if (!notApplicable) {
            //     patchOperationApplicationMutation.mutateAsync({
            //       impact: {
            //         notApplicableComment: '',
            //       },
            //     });
            //   }
            // }
          }}
        />

        <p>
          Dersom dere mener klyngens aktiviteter har en merkbar effekt skal dere prøve å
          kvantifisere/tallfeste effekten. Dere kan velge om dere vil bruke tekstfeltet eller om dere vil
          laste opp et et dokument.
        </p>
      </ThemeSection>
      <ThemeSection>
        {putOperationSocialImpactsMutation.isLoading && <Spinner />}
        {hasNoticeableEffectNotRelevant && (
          <Controller
            control={control}
            name={`notApplicableComment`}
            rules={{ ...RHFRequiredNotEmptyMediumMaxLength }}
            render={({ field, fieldState }) => (
              <>
                <TextArea
                  {...field}
                  label="Beskriv hvorfor dette ikke er aktuelt for dere?"
                  maxCount={InputMaxLength.medium}
                  maxLength={InputMaxLength.medium}
                  rows={3}
                  errorMsg={fieldState?.error?.message?.toString()}
                  onBlur={(): void => {
                    field.onBlur();
                    trigger('notApplicableComment').then((isValid) => {
                      if (isValid && getValues('notApplicableComment') !== notApplicableComment) {
                        patchOperationApplicationMutation.mutateAsync({
                          impact: {
                            notApplicableComment: getValues('notApplicableComment'),
                          },
                        });
                      }
                    });
                  }}
                />
              </>
            )}
          />
        )}
      </ThemeSection>
      <ThemeSection marginBottom={false}>
        {!hasNoticeableEffectNotRelevant && (
          <>
            <SelectAndAdd
              buttonText="Legg til effekt"
              title={'Legg til aktuelle samfunns- og miljøeffekter'}
              options={societalEffects.map((impact: LabelValueDto) => {
                return {
                  selected: false,
                  text: impact.label != null ? impact.label : '',
                  value: impact.value != null ? impact.value : '',
                };
              })}
              value={fields.map((socialImpact: SocialImpact) => {
                return {
                  selected: true,
                  text: socialImpact.name != null ? socialImpact.name : '',
                  value: socialImpact.id != null ? socialImpact.id : '',
                };
              })}
              onSave={async (selectedIds) => {
                const applicationImpacts: SocialImpact[] = [
                  ...societalEffects
                    .filter((impactChoiceItem: LabelValueDto) =>
                      selectedIds.some((id) => impactChoiceItem.value === id),
                    )
                    .map((impactChoiceItem: LabelValueDto) => {
                      const existingImpact = fields.find(
                        (item: SocialImpact) => item.id === impactChoiceItem.value,
                      );

                      return {
                        id: impactChoiceItem.value,
                        name: impactChoiceItem.label,
                        description: existingImpact != null ? existingImpact.description : '',
                      };
                    }),
                ];

                if (notApplicable === null) {
                  await patchOperationApplicationMutation.mutateAsync({ impact: { notApplicable: false } });
                }

                await putOperationSocialImpactsMutation.mutateAsync(applicationImpacts);
              }}
            />
            <ImpactErrorMessage />
          </>
        )}
      </ThemeSection>

      {!hasNoticeableEffectNotRelevant && (
        <ThemeSection noPadding={true} marginBottom={true}>
          {fields.map((field, index) => {
            return (
              <SocialAndEnvironmentalEffectAccordion
                showSaveButton={false}
                key={field.id}
                {...{ control, index, field }}
                saveProcessActive={
                  patchOperationApplicationMutation.isLoading ||
                  putOperationSocialImpactsMutation.isLoading ||
                  deleteOperationSocietalEnvironmentalImpact.isLoading
                }
                onRemove={async (index, socialImpact) => {
                  if (index != null && socialImpact?.id != null) {
                    remove(index);
                    await deleteOperationSocietalEnvironmentalImpact.mutateAsync(socialImpact.id);
                  }
                }}
                onSave={(index: number) => {
                  if (getValues(`socialImpacts`)[index] != null) {
                    patchOperationApplicationMutation.mutateAsync({
                      impact: { impacts: [getValues(`socialImpacts`)[index]] },
                    });
                  }
                }}
              />
            );
          })}
        </ThemeSection>
      )}
      <ThemeSection>
        <h2>Vedlegg</h2>
        <FileUpload
          files={attachmentLocal}
          uploadCallback={uploadFile}
          deletionCallback={deleteFile}
          label="Legg ved et vedlegg relatert til klyngearbeidets effekt"
          name={operationFileUploadFieldName}
          fileTypes="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          requirementsText="Tillatte filtyper: PDF, Excel, Word"
        />
      </ThemeSection>
      <ConfirmationBox
        title="Er du sikker?"
        confirmButtonTheme={Theme.Danger}
        onConfirm={async () => {
          // patchOperationApplicationMutation.mutateAsync({ impact: { notApplicable: true } });
          // putOperationSocialImpactsMutation.mutateAsync([]);
          if (notApplicableWarning.notApplicable) {
            await patchOperationApplicationMutation.mutateAsync({
              impact: {
                notApplicable: notApplicableWarning.notApplicable,
                notApplicableComment: getValues('notApplicableComment'),
              },
            });
            await putOperationSocialImpactsMutation.mutateAsync([]);
            setValue('socialImpacts', []);
          } else {
            await patchOperationApplicationMutation.mutateAsync({
              impact: {
                notApplicable: notApplicableWarning.notApplicable,
                notApplicableComment: '',
              },
            });

            setValue('notApplicableComment', '');
          }
          setValue('notApplicable', notApplicableWarning.notApplicable);
          setNotApplicableWarning({ message: '', notApplicable: notApplicableWarning.notApplicable });
          ref.current?.close();
        }}
        onCancel={() => {
          setNotApplicableWarning({ message: '', notApplicable: notApplicableWarning.notApplicable });
          ref.current?.close();

          //setHasNoticeableEffectNotRelevant(false);

          //patchOperationApplicationMutation.mutateAsync({ impact: { notApplicable: false } });

          //setShowDeleteWarning(false);
        }}
        confirmText="Ja"
        cancelText="Nei"
        ref={ref}
        text={notApplicableWarning.message}
      />
    </div>
  );
};

export default SocietalEffects;
