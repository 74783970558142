import React from 'react';
import { CustomRouteObject } from '../types';
import { baseSiteRoutePaths } from '../site/SiteRouteConfig';

import DevelopmentApplicationSummary from '../../Application/developmentApplication/Summary/DevelopmentApplicationSummary';
import DevelopmentProjectApplication from '../../Application/ApplicationWrapper/DevelopmentProject';
import DevelopmentSocietalEffects from '../../ApplicationShared/SocialAndEnvironmentalEffect/DevelopmentSocietalEffects';
import DevelopmentImpact from '../../ApplicationShared/SocialAndEnvironmentalEffect/DevelopmentImpact';
import DevelopmentFinancing from '../../Application/developmentApplication/Financing/DevelopmentFinancing';
import DevelopmentBudget from '../../Application/developmentApplication/Financing/DevelopmentBudget';
import DevelopmentActivities from '../../Application/developmentApplication/Activities/DevelopmentActivities';
import DevelopmentProject from '../../Application/developmentApplication/Project/DevelopmentProject';
import DevelopmentProjectAbout from '../../Application/developmentApplication/Project/DevelopmentProjectAbout';
import DevelopmentProjectParticipants from '../../Application/developmentApplication/Project/DevelopmentProjectParticipants';

const baseApplicationPath: string = `${baseSiteRoutePaths.applicationsOverviewPath}/development-application/`;

const projectStartPath = `${baseApplicationPath}project/`;
const projectAbout = `${projectStartPath}about-project`;
const projectParticipants = `${projectStartPath}project-participants`;
const activitiesParentPath = `${baseApplicationPath}activities/`;
const activitiesProjectsPath = `${activitiesParentPath}projects/`;
const impactParentPath = `${baseApplicationPath}impact/`;
const societalEffectsPath = `${impactParentPath}societal-effects`;
const financingParentPath = `${baseApplicationPath}financing/`;
const budgetPath = `${financingParentPath}budget/`;
const financingPlan = `${financingParentPath}financing-plan`;
const estimatedFinancingNeeds = `${financingParentPath}estimated-financing-needs`;
const summaryParentPath = `${projectStartPath}sumary/`;
const summaryOverviewPath = `${summaryParentPath}overview`;
const applicationReadyToSubmitReceipt = `${projectStartPath}receipt`;

export interface DevelopmentApplicationRoutePaths {
  baseApplicationPath: string;
  projectStartPath: string;
  projectAbout: string;
  projectParticipants: string;
  activitiesParentPath: string;
  activitiesProjectsPath: string;
  impactParentPath: string;
  societalEffectsPath: string;
  financingParentPath: string;
  financingPlan: string;
  budgetPath: string;
  estimatedFinancingNeeds: string;
  summaryParentPath: string;
  summaryOverviewPath: string;
  applicationReadyToSubmitReceipt: string;
}
export const developmentApplicationRoutePaths: DevelopmentApplicationRoutePaths = {
  baseApplicationPath,
  projectStartPath,
  projectAbout,
  projectParticipants,
  activitiesParentPath,
  activitiesProjectsPath,
  impactParentPath,
  societalEffectsPath,
  financingParentPath,
  financingPlan,
  budgetPath,
  estimatedFinancingNeeds,
  summaryParentPath,
  summaryOverviewPath,
  applicationReadyToSubmitReceipt,
};

const project: CustomRouteObject = {
  name: 'Prosjektet',
  element: <DevelopmentProject />,
  path: developmentApplicationRoutePaths.projectStartPath,
  children: [
    {
      name: 'Om dette prosjektet',
      element: (
        <DevelopmentProjectAbout
          title="Om dette prosjektet"
          ingress="Beskriv prosjektet dere søker finansiering til."
        />
      ),
      path: developmentApplicationRoutePaths.projectAbout,
      children: [],
    },
    {
      name: 'Prosjektdeltagere',
      element: (
        <DevelopmentProjectParticipants
          title="Prosjektdeltagere"
          ingress="Hvem skal delta i prosjektet? Prosjektdeltagerne som registreres her følger med til prosjektoversikten i Flerbedriftsportalen."
        />
      ),
      path: developmentApplicationRoutePaths.projectParticipants,
      children: [],
    },
  ],
};

const activities: CustomRouteObject = {
  name: 'Framdrift og prosjektaktiviteter',
  element: (
    <DevelopmentActivities
      title="Framdrift og prosjektaktiviteter"
      ingress="Hva planlegger dere å gjøre i prosjektet?"
    />
  ),
  path: developmentApplicationRoutePaths.activitiesParentPath,
  children: [
    {
      name: 'Prosjektaktiviteter',
      element: <DevelopmentActivities title="Prosjektaktiviteter" />,
      path: developmentApplicationRoutePaths.activitiesProjectsPath,
      children: [],
    },
  ],
};

const impacts: CustomRouteObject = {
  name: 'Samfunns- og miljøeffekt',
  element: <DevelopmentImpact />,
  path: developmentApplicationRoutePaths.impactParentPath,
  children: [
    {
      name: 'Prosjektets effekt',
      element: <DevelopmentSocietalEffects title="Samfunns- og miljøeffekt" />,
      attachmentField: 'societalEffectsAttachment',
      path: developmentApplicationRoutePaths.societalEffectsPath,
      children: [],
    },
  ],
};

const financing: CustomRouteObject = {
  name: 'Finansiering',
  element: <DevelopmentFinancing />,
  path: developmentApplicationRoutePaths.financingParentPath,
  children: [
    {
      name: 'Budsjett',
      element: <DevelopmentBudget />,
      path: developmentApplicationRoutePaths.budgetPath,
      children: [],
    },
  ],
};

const summary: CustomRouteObject = {
  name: 'Oppsummering',
  path: developmentApplicationRoutePaths.summaryParentPath,
  element: <DevelopmentApplicationSummary title="Oppsummering" />,
  children: [],
};
const developmentApplication: CustomRouteObject = {
  name: 'Søknad',
  element: <DevelopmentProjectApplication />,
  path: developmentApplicationRoutePaths.baseApplicationPath,
  children: [project, activities, impacts, financing, summary],
};
export const developmentApplicationRouteObjects: CustomRouteObject[] = [developmentApplication];
