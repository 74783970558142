import React from 'react';
import { PageLoader } from '@in/component-library';
import css from './CustomPageLoader.module.scss';
import cn from 'classnames';
const CustomPageLoader: React.FC<{ viewportHeight?: boolean }> = ({ viewportHeight = false }) => {
  return (
    <div
      className={cn(css['custom-page-loader'], {
        [css['custom-page-loader--viewport-height']]: viewportHeight,
      })}
    >
      <PageLoader aria-label="Laster data..." />
    </div>
  );
};

export default CustomPageLoader;
