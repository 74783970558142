import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useUser from '../hooks/user/use-user';
import { baseSiteRoutePaths, siteRouteConfigList } from './site/SiteRouteConfig';
import ClusterChooser from '../components/ClusterChooser/ClusterChooser';
import { readyToSubmitReceiptRouteConfigList } from './site/ReadyToSubmitReceiptRouteConfig';
import { ApplicationLandingPage } from '../pages/Application/ApplicationLandingPage';
import { confirmRouteConfigList } from './site/ConfirmRouteConfig';
import { baseInfoRouteConfigList } from './site/BaseInfoRouteConfig';
import {
  operationApplicationRouteObjects,
  operationApplicationRoutePaths,
} from './application/OperationApplicationRouteConfig';
import {
  developmentApplicationRouteObjects,
  developmentApplicationRoutePaths,
} from './application/DevelopmentApplicationRouteConfig';
import SessionExpired from '../pages/session-exipred/SessionExpired';
import LandingPage from '../components/LandingPage/LandingPage';
import NotFound from '../pages/NotFound/NotFound';

type Props = {
  user: ReturnType<typeof useUser>;
};

const RouterConfig: React.FC<Props> = ({ user: userProp }) => {
  const { isUserLoggedIn, isUserRegistered, hasUserSessionExpired, user } = userProp;
  const { pathname } = useLocation();
  // Bruker har blitt utlogget
  if (hasUserSessionExpired) {
    return (
      <Routes>
        <Route path="*" element={<SessionExpired />} />
      </Routes>
    );
  }

  // Bruker er ikke logget inn
  if (!isUserLoggedIn || !isUserRegistered) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Bruker er logget inn, men ikke valgt noe klynge
  if (user && !user?.chosenClusterId) {
    return (
      <Routes>
        <Route path="/" element={<ClusterChooser />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Bruker er logget inn, og alt er good
  return (
    <Routes>
      <Route path="/" element={<ApplicationLandingPage />} />
      <Route path={baseSiteRoutePaths.clusterChooser} element={<ClusterChooser />} />

      {siteRouteConfigList.map((item) => {
        return <Route key={item.path} path={item.path} element={item.element} />;
      })}

      <Route path={baseSiteRoutePaths.applicationsOverviewPath} element={<ApplicationLandingPage />} />
      {readyToSubmitReceiptRouteConfigList.map((item) => {
        return <Route key={item.path} path={item.path} element={item.element} />;
      })}
      {confirmRouteConfigList.map((item) => {
        return <Route key={item.path} path={item.path} element={item.element} />;
      })}
      {baseInfoRouteConfigList.map((item) => {
        return <Route key={item.path} path={item.path} element={item.element} />;
      })}

      {
        /**
         * Creating a list of ProtectedRoute for all form pages
         */
        pathname.startsWith(operationApplicationRoutePaths.baseOperationApplicationRoute) &&
          operationApplicationRouteObjects.length > 0 &&
          operationApplicationRouteObjects.map((item) => {
            return (
              <Route key={item.path} path={item.path} element={item.element}>
                {item.children.length > 0 &&
                  item.children.map((child) => {
                    return (
                      <Route key={child.path} path={child.path} element={child.element}>
                        {child.children.length > 0 &&
                          child.children.map((child2) => {
                            return <Route key={child2.path} path={child2.path} element={child2.element} />;
                          })}
                      </Route>
                    );
                  })}
              </Route>
            );
          })
      }
      {
        /**
         * Creating a list of ProtectedRoute for all form pages
         */
        pathname.startsWith(developmentApplicationRoutePaths.baseApplicationPath) &&
          developmentApplicationRouteObjects.length > 0 &&
          developmentApplicationRouteObjects.map((item) => {
            return (
              <Route key={item.path} path={item.path} element={item.element}>
                {item.children.length > 0 &&
                  item.children.map((child) => {
                    return (
                      <Route key={child.path} path={child.path} element={child.element}>
                        {child.children.length > 0 &&
                          child.children.map((child2) => {
                            return <Route key={child2.path} path={child2.path} element={child2.element} />;
                          })}
                      </Route>
                    );
                  })}
              </Route>
            );
          })
      }

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterConfig;
