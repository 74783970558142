import css from '../Confirm/Confirm.module.scss';
import {
  baseInfoRouteConfigList,
  baseInfoRouteConfigObject,
} from '../../routeConfigs/site/BaseInfoRouteConfig';
import cn from 'classnames';
import {
  Button,
  ButtonVariant,
  Card,
  FaIcon,
  FaIcons,
  Section,
  ShadowHeight,
  Theme,
} from '@in/component-library';
import { KeyInformation } from '../../Application/themePageSections/KeyInformation';
import ClusterResourceBase from '../../Application/operationApplication/Cluster/ClusterResourceBase';
import { GoodBusinesspractice } from '../../Application/themePageSections/GoodBusinesspractice';
import { baseSiteRoutePaths } from '../../routeConfigs/site/SiteRouteConfig';
import { LandingPageTabs } from '../Application/ApplicationLandingPage';
import React, { useEffect, useState } from 'react';
import PageTitleAndIngress from '../../components/Page/PageTitleAndIngress';
import { useAppDispatch } from '../../store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDocTitle } from '../../hooks/CustomHooks';
import ThemeSection from '../../components/ThemeSection/ThemeSection';
import useMainApplication from '../../hooks/mainApplication/use-main-application';

const ClusterBaseInfoContent = ({ docTitlePrefix }: { docTitlePrefix: string }) => {
  const { updateDocTitle } = useDocTitle('');
  const applicationDispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeIndexInternal, setActiveIndexInternal] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);
  const [showFinalStepError, setShowFinalStepError] = useState<boolean>(false);

  const { annualFunding } = useMainApplication();
  useEffect(() => {
    if (
      annualFunding?.resourceBaseComments != null &&
      annualFunding?.resourceBaseComments !== '' &&
      annualFunding?.clusterOrganizationComments != null &&
      annualFunding?.clusterOrganizationComments !== ''
    ) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [annualFunding?.clusterOrganizationComments, annualFunding?.resourceBaseComments]);

  useEffect(() => {
    if (pathname != null) {
      switch (pathname) {
        case baseInfoRouteConfigObject.baseInfoKeyInformationPage.path:
          updateDocTitle(`${docTitlePrefix} - Nøkkelopplysninger`);
          setActiveIndexInternal(0);
          break;
        case baseInfoRouteConfigObject.baseInfoClusterInfoPage.path:
          setActiveIndexInternal(1);
          updateDocTitle(`${docTitlePrefix} - Ressursbasen`);
          break;
        case baseInfoRouteConfigObject.baseInfoConfirmGoodBusinessPracticePage.path:
          setActiveIndexInternal(2);
          updateDocTitle(`${docTitlePrefix} - God forretningsskikk`);
          break;
      }
    }
  }, [pathname, applicationDispatch, updateDocTitle, docTitlePrefix]);
  return (
    <Section>
      <PageTitleAndIngress titleCenterAlign={true}>
        <h1>Godkjenn basisinformasjon</h1>
      </PageTitleAndIngress>

      <div className={css['confirm__steps']}>
        {baseInfoRouteConfigList.map((item, index) => {
          return (
            <button
              key={`confirm_navigation_${item.path}`}
              onClick={() => {
                if (index < activeIndexInternal) {
                  navigate(item.path);
                }
              }}
              className={cn(css['confirm__step'], {
                [css['confirm__step--active']]: index === activeIndexInternal,
                [css['confirm__step--check']]: index < activeIndexInternal,
              })}
            >
              <div className={css['confirm__step-number']}>
                {index < activeIndexInternal && <FaIcon name={FaIcons.Check} />}
                {index >= activeIndexInternal && index + 1}
              </div>
              <div className={css['confirm__step-name']}>{item.name}</div>
            </button>
          );
        })}
      </div>
      <div
        className={cn(css['confirm__content'], {
          [css['confirm__content--stand-alone']]: true,
        })}
      >
        {activeIndexInternal === 0 && <KeyInformation />}
        {activeIndexInternal === 1 && <ClusterResourceBase />}
        {activeIndexInternal === 2 && <GoodBusinesspractice />}

        {activeIndexInternal === 1 && showError && (
          <ThemeSection marginBottom={true}>
            <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
              <h2>Du mangler noen felter</h2>
              <ul>
                {(annualFunding?.resourceBaseComments == null ||
                  annualFunding?.resourceBaseComments === '') && <li>Beskrivelse av ressursbasen mangler</li>}

                {(annualFunding?.clusterOrganizationComments == null ||
                  annualFunding?.clusterOrganizationComments === '') && (
                  <li>Beskrivelse av organisering av klyngen mangler</li>
                )}
              </ul>
            </Card>
          </ThemeSection>
        )}

        {activeIndexInternal === 2 && showFinalStepError && !annualFunding?.goodBusinessPracticeConsent && (
          <ThemeSection marginBottom={true}>
            <Card boxShadow={ShadowHeight.Elevated} backgroundColor="white">
              <h2>Du mangler et felt</h2>
              <ul>
                <li>
                  Du må bekrefte at klyngen har en god forretningsskikk i tråd med prinsippene for ansvarlig
                  næringsliv?
                </li>
              </ul>
            </Card>
          </ThemeSection>
        )}
        <ThemeSection>
          <div className="button-container">
            {activeIndexInternal <= 2 && activeIndexInternal > 0 && (
              <Button
                theme={Theme.Neutral}
                variant={ButtonVariant.Outlined}
                onClick={() => {
                  navigate(baseInfoRouteConfigList[activeIndexInternal - 1].path);
                }}
              >
                Forrige
              </Button>
            )}

            {activeIndexInternal < 2 && (
              <Button
                theme={Theme.Neutral}
                variant={ButtonVariant.Solid}
                onClick={() => {
                  if (
                    activeIndexInternal !== 0 &&
                    (annualFunding?.resourceBaseComments == null ||
                      annualFunding?.resourceBaseComments === '' ||
                      annualFunding?.clusterOrganizationComments == null ||
                      annualFunding?.clusterOrganizationComments === '')
                  ) {
                    navigate(baseInfoRouteConfigList[activeIndexInternal].path, {
                      state: { validate: true },
                    });
                    setShowError(true);
                  } else {
                    navigate(baseInfoRouteConfigList[activeIndexInternal + 1].path);
                    setShowError(false);
                  }
                }}
              >
                Neste
              </Button>
            )}

            {activeIndexInternal === 2 && (
              <Button
                theme={Theme.Neutral}
                variant={ButtonVariant.Solid}
                onClick={() => {
                  if (
                    annualFunding?.resourceBaseComments == null ||
                    annualFunding?.resourceBaseComments === '' ||
                    annualFunding?.clusterOrganizationComments == null ||
                    annualFunding?.clusterOrganizationComments === '' ||
                    !annualFunding?.goodBusinessPracticeConsent
                    //TODO: dersom patch pågårPATCH_GOOD_BUSINESS_PRACTICE_CONSENT
                  ) {
                    setShowFinalStepError(true);
                  } else {
                    navigate(baseSiteRoutePaths.applicationsOverviewPath, {
                      state: { activeLandingPageTab: LandingPageTabs.APPLICATIONS_READY_FOR_SUBMIT },
                    });
                  }
                }}
              >
                Godkjenn
              </Button>
            )}
          </div>
        </ThemeSection>
      </div>
    </Section>
  );
};
export default ClusterBaseInfoContent;
