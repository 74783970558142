import React from 'react';
import { CustomRouteObject } from '../types';

import Confirm from '../../pages/Confirm/Confirm';
import SubmitReceiptPage from '../../pages/Application/SubmitReceiptPage';

export type ConfirmRouteMenu = {
  costs: CustomRouteObject;
  success: CustomRouteObject;
};

const baseConfirmRoute: string = '/confirm/';

const confirmCostsAndAttachments = `${baseConfirmRoute}costs-and-attachments/`;
const confirmSubmit = `${baseConfirmRoute}submit/`;
const submitSuccess = `${confirmSubmit}success/`;

export interface ArenaFormRoutePaths {
  baseConfirmRoute: string;
  confirmCostsAndAttachmentRoute: string;
  submitSuccessRoute: string;
}
export const confirmRoutePaths: ArenaFormRoutePaths = {
  baseConfirmRoute: baseConfirmRoute,
  confirmCostsAndAttachmentRoute: confirmCostsAndAttachments,
  submitSuccessRoute: submitSuccess,
};

const costs: CustomRouteObject = {
  name: 'Vedlegg',
  element: <Confirm />,
  path: confirmRoutePaths.confirmCostsAndAttachmentRoute,
  children: [],
};

const success: CustomRouteObject = {
  name: 'Dine søknader er sendt inn!',
  element: <SubmitReceiptPage />,
  path: confirmRoutePaths.submitSuccessRoute,
  children: [],
};

export const confirmRouteConfigList: Array<CustomRouteObject> = [costs, success];
export const confirmRouteConfigObject: ConfirmRouteMenu = {
  costs,
  success,
};
