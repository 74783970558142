import React, { FC, lazy, PropsWithChildren, useEffect, useState } from 'react';
import { FaIcons, Section } from '@in/component-library';

import css from './ConfirmSuccess.module.scss';
import { FaIcon } from '@in/component-library/';
import Confetti from 'react-dom-confetti';
const Page = lazy(() => import('src/components/Page/Page'));

export interface SubmitSuccessProps {
  ingress?: string;
  confetti: boolean;
  children: React.ReactNode;
}

export const SubmitSuccess: FC<SubmitSuccessProps> = ({
  ingress,
  children,
  confetti,
}: PropsWithChildren<SubmitSuccessProps>) => {
  const config = {
    angle: 90,
    spread: 160,
    startVelocity: 28,
    elementCount: 195,
    dragFriction: 0.09,
    duration: 3750,
    stagger: 1,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };
  const [showConfetti, setShowConfetti] = useState(false);
  useEffect(() => {
    setShowConfetti(confetti);
  }, [confetti]);
  return (
    <Page>
      <Section className={css['submit-success']}>
        <div className={css['submit-success-check-container']}>
          <FaIcon name={FaIcons.Check} />
          <Confetti active={showConfetti} config={config} />
        </div>
        {ingress != null && <p className={css['submit-success-ingress']}>{ingress}</p>}
        {children}
      </Section>
    </Page>
  );
};
